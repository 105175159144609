import React from 'react'
import styled from "styled-components";

export const OnBoardingList = [
  {
    id: "create-publication",
    description: "¿Sos constructor? Adjunta planos , especificaciones y cotiza tus requerimientos de materiales",
  },
  {
    id: "private-projects",
    description: "¿Sos Proveedor? Postúlate en obras privadas y consigue tu próximo trabajo",
  },
  {
    id: "public-projects",
    description: "Accedé a todas las obras públicas en un solo lugar",
  },
   {
    id: "cotizacion-materiales",
    description: "¿Vendes insumos para la construcción? Descarga solicitudes de cotización de entidades públicas y privadas",
  },
  {
    id: "comunidad-edify",
    description: "Completa tu perfil, subí tus trabajos, recibí comentarios y calificaciones para destacarte en nuestra comunidad",
  },
  {
    id: "seguros",
    description: "Cotiza seguros con las mejores compañías (Proximamente)",
  },
  {
    id: "alquiler-maquinarias",
    description: "Conseguí las mejores ofertas en maquinaras para la construcción (Proximamente)",
  },
  
]

const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  border: 1px solid #234ba2;
  box-sizing: border-box;
  border-radius: 11px;
  color: #234ba2;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 5px;
`

const SkipButton = styled.p`
  overflow: hidden;
	position: absolute;
  top: 0.5em;
  right: 0.5em;
	border: none;
	padding: 0;
	width: 1em; height: 1em;
	border-radius: 50%;
	background: transparent;
	color: #234ba2;
	font: inherit;
	text-indent: 100%;
	cursor: pointer;
	
	&:focus {
		outline: solid 0 transparent;
		box-shadow: 0 0 0 2px #8ed0f9
	}
	
	&:hover {
		background: rgba(29, 161, 142, .1)
	}
	
	&:before, &:after {
		position: absolute;
		top: 15%; left: calc(50% - .0625em);
		width: .125em; height: 70%;
		border-radius: .125em;
		transform: rotate(45deg);
		background: currentcolor;
		content: ''
	}
	
	&:after { transform: rotate(-45deg); }
`

export const OnBoardingModal = ({children, onClickNext, top, isLast, onSkip, isFirst, onClickPrevious}) => (
  <ModalContainer top={top}>
    <SkipButton onClick={onSkip}/>
    <h3>¿Qué puedo hacer en edify?</h3>
    <div>{children}</div>
    <ModalButtons>
      {
        !isFirst ? (<ModalButton onClick={onClickPrevious}>Atrás</ModalButton>) : null
      }
      <ModalButton style={{}} onClick={onClickNext}>{isLast ? "Cerrar" : "Siguiente"}</ModalButton>
    </ModalButtons>
  </ModalContainer>
)

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: ${(state) => state.top ?? 60}px;
  background-color: var(--color-white);
  border-radius: 20px;
  padding: 10px 20px;
  width: 200px;
  z-index: 1001;
  border: solid 1px #ebebeb;

  &::before{
    content: '';
    position: absolute;
    top: -12px;
    right: 20px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 20px solid var(--color-white);
    border-left: 10px solid transparent;
  }
`

export const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.3);
`

export const RelativeContainer = styled.div`
  position: relative
`