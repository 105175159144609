import React, { useEffect, useState, useRef } from "react";
import styles from "./MercadoDeTrabajo.module.scss";
import { connect } from "react-redux";
import { openTutorialModal, getAllWorkBagUsers, openWorkOfferModal } from "../../store/actions";
import WorkersMaps from "../UI/Maps/WorkersMap/WorkersMaps"
import { ReactComponent as NoProfileIcon } from "../../assets/svg/noProfileIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/plusIcon.svg";
import FilterWorkBag from "../Dashboard/FilterBy/FilterWorkBag/FilterWorkBag";
import Botonera from "../Botonera/Botonera";
import { motion } from "framer-motion";
import OfertaDeTrabajo from "./OfertaDeTrabajo";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./MercadoDeTrabajo.css"
import ListedWorkers from "./ListedWorkers/ListedWorkers";
import { downloadFile } from "../../utils/fileUtils.js";
import useGetTodayDate from "../../hooks/useGetTodayDate";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import ListedWorkersForMobile from "./ListedWorkers/ListedWorkersForMobile.js";


const MercadoDeTrabajo = ({
    openTutorialModal,
    getAllWorkBagUsers,
    workBagUsers,
    isUserPremium,
    openWorkOfferModal,
    workOfferModal,
}) => {
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filterMenu, setFilterMenu] = useState("");
    const [isFiltered, setIsFiltered] = useState(false);
    const [zoom, setZoom] = useState(4);
    const [center, setCenter] = useState([-34.61315, -58.37723]);
    const [loading, setLoading] = useState(true);
    const [coordinatesArray, setCoordinatesArray] = useState([]);
    const [selectedOptionJurisdictions, setSelectedOptionJurisdictions] = useState([]);
    const [selectedOptionSkills, setSelectedOptionSkills] = useState([]);
    const [showJobOfferNotification, setShowJobOfferNotification] = useState(true);
    const [bounds, setBounds] = useState(null);
    const [activeView, setActiveView] = useState("map");
    const [showSmallMap, setShowSmallMap] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const premiumCheck = usePremiumCheck();
    const mapContainerRef = useRef(null);
    const initialZoom = 4;
    const initialCenter = [-34.61315, -58.37723];

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 768);
        setMobileStylesFromParent(windowWidth < 768);
        setIsPhone(windowWidth < 768);
    };

    useEffect(() => {
        getAllWorkBagUsers();
        handleResize();
    }, []);

    useEffect(() => {
        if (workBagUsers.length > 0) {
            setDataLoaded(true);
        }
    }, [workBagUsers]);

    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const countUniqueJurisdictionsAndSkills = (workBagUsers) => {
        const uniqueJurisdictions = new Set();
        const jurisdictionCounts = {};
        const uniqueSkills = new Set();

        workBagUsers.forEach(user => {
            user.jobBoardZones.forEach(zone => {
                const jurisdictionName = zone.jurisdiccion.name;
                uniqueJurisdictions.add(jurisdictionName);

                if (jurisdictionCounts[jurisdictionName]) {
                    jurisdictionCounts[jurisdictionName]++;
                } else {
                    jurisdictionCounts[jurisdictionName] = 1;
                }
            });

            user.skill.forEach(skill => {
                uniqueSkills.add(skill?.skill?.name);
            });
        });

        let mostFrequentJurisdiction = null;
        let maxCount = 0;

        for (const [name, count] of Object.entries(jurisdictionCounts)) {
            if (count > maxCount) {
                maxCount = count;
                mostFrequentJurisdiction = name;
            }
        }

        return {
            uniqueJurisdictionsCount: uniqueJurisdictions.size,
            mostFrequentJurisdiction,
            uniqueSkillsCount: uniqueSkills.size
        };
    };

    useEffect(() => {
        if (!dataLoaded) return;

        const { uniqueJurisdictionsCount, mostFrequentJurisdiction, uniqueSkillsCount } = countUniqueJurisdictionsAndSkills(workBagUsers);

        const showStaggeredToasts = () => {
            toast.info(
                <div className={`${styles.smallToast} ${styles.firstToast}`}>
                    <h2>¿Sabías que...?</h2>
                    <p className={styles.smallToast_item}>Actualmente contamos con más de {workBagUsers.length} trabajadores registrados.</p>
                </div>,
                {
                    autoClose: 30000,
                    className: 'Toastify__toast--firstToast'
                }
            );

            setTimeout(() => {
                toast.info(
                    <div className={`${styles.smallToast} ${styles.secondToast}`}>
                        <h2>Además...</h2>
                        <p>Tenemos presencia en {uniqueJurisdictionsCount} jurisdicciones. Siendo {mostFrequentJurisdiction} la más frecuente.</p>
                    </div>,
                    {
                        autoClose: 30000,
                        className: 'Toastify__toast--secondToast'
                    }
                );
            }, 10000);

            setTimeout(() => {
                toast.info(
                    <div className={`${styles.smallToast} ${styles.thirdToast}`}>
                        <h2>¡Por último!</h2>
                        <p>Si lo que buscas es variedad existen {uniqueSkillsCount} rubros diferentes registrados en la base.
                            Pero si no encontrás lo que buscas podés crear una oferta de trabajo y nosotros te ayudamos.</p>
                    </div>,
                    {
                        autoClose: 30000,
                        className: 'Toastify__toast--thirdToast'
                    }
                );
            }, 20000);
        };

        showStaggeredToasts();
    }, [dataLoaded]);

    const handleCloseInfo = () => {
        setZoom(3);
        setCenter([-38.416097, -63.616672]);
        setIsFiltered(false);
        setSelectedUser(null);
        setSelectedOptionJurisdictions([]);
        setSelectedOptionSkills([]);
        setShowSmallMap(false);
    }

    const showNoMatchesToast = () => {
        toast.error(
            <div>
                <h2 className={styles.selectedUser_title}>¡UPS! No hay coincidencias</h2>
                <div className={styles.selectedUser_emptyList}>
                    <NoProfileIcon width={40} height={40} />
                    <p className={styles.selectedUser_emptyList_text}>No hay trabajadores que cumplan con los criterios de búsqueda</p>
                </div>
                <button
                    className={styles.mapContainer_jobOffer_button}
                    onClick={() => {
                        handleCreateJobOffer();
                        toast.dismiss();
                    }}>
                    <PlusIcon width={20} height={20} />
                    Crear oferta de trabajo
                </button>
            </div>,
            {
                autoClose: false,
                closeButton: (
                    <button onClick={() => { toast.dismiss(); handleCloseInfo() }} className={styles.selectedUser_title_closeButton}>X</button>
                )
            }
        );
    };

    useEffect(() => {
        if (isFiltered && workBagUsers.length === 0) {
            showNoMatchesToast();
        } else {
            toast.dismiss();
        }
    }, [isFiltered, workBagUsers.length]);

    const handleCreateJobOffer = () => {
        setShowJobOfferNotification(false);
        openWorkOfferModal();
    }

    const todayDate = useGetTodayDate();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const formattedTodayDate = parseDate(todayDate);

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleDownload = (format) => {
        const headers = [
            'Nombre Completo',
            'Teléfono',
            'Email',
            'Lugar',
            'Rubro',
            'Libreta de Desempleo',
            'Disponibilidad'
        ];

        const data = workBagUsers.map(worker => ({
            'Nombre Completo': `"${(worker.name || '')} ${(worker.last_name || '')}"`,
            'Teléfono': `"${(worker.phone || '')}"`,
            'Email': `"${(worker.email || '')}"`,
            'Lugar': `"${(worker?.jobBoardZones ?? []).map(zone => zone.city.replace(", Argentina", "").trim()).join(", ")}"`,
            'Rubro': `"${(worker?.skill ?? []).map(skill => skill?.skill?.name.replace(/,/g, ' ').trim()).join(", ")}"`,
            'Libreta de Desempleo': worker?.unemployment_fund ? "Sí" : "No",
            'Disponibilidad': new Date(worker?.employment_end_date) < formattedTodayDate
                ? "Inmediata"
                : `A partir del ${formatEmploymentEndDate(worker?.employment_end_date)}`
        }));

        downloadFile([headers, ...data.map(worker => Object.values(worker))], 'workers_data', format);
    };

    return (
        <main className={styles.container}>
            {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
            <section className={styles.componentContainer}>
                <section className={styles.filterContainer}>
                    <FilterWorkBag
                        workBagUsers={workBagUsers}
                        setIsFiltered={setIsFiltered}
                        setZoom={setZoom}
                        setCenter={setCenter}
                        setCoordinatesArray={setCoordinatesArray}
                        setFilterMenu={setFilterMenu}
                        setLoading={setLoading}
                        loading={loading}
                        coordinatesArray={coordinatesArray}
                        zoom={zoom}
                        center={center}
                        isPhone={isPhone}
                        setSelectedUser={setSelectedUser}
                        selectedOptionJurisdictions={selectedOptionJurisdictions}
                        setSelectedOptionJurisdictions={setSelectedOptionJurisdictions}
                        selectedOptionSkills={selectedOptionSkills}
                        setActiveView={setActiveView}
                        activeView={activeView}
                        setSelectedOptionSkills={setSelectedOptionSkills}
                        setShowSmallMap={setShowSmallMap}
                        onDownload={handleDownload}
                        />
                </section>
                {activeView === "map" && !isPhone ? (
                    <div className={styles.listContainer}>
                        <section className={styles.mapContainer} ref={mapContainerRef}>
                            {showJobOfferNotification && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 1 }}
                                    drag
                                    dragConstraints={mapContainerRef}
                                    dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                                    className={styles.mapContainer_jobOffer}
                                >
                                    <div className={styles.mapContainer_jobOffer_titleContainer}>
                                        <h1 className={styles.mapContainer_jobOffer_title}>¿No encontraste lo que buscabas?</h1>
                                        <button onClick={() => setShowJobOfferNotification(false)} className={styles.mapContainer_jobOffer_closeButton}>X</button>
                                    </div>
                                    <p className={styles.mapContainer_jobOffer_text}>¡No te preocupes! Podés crear tu propia oferta de trabajo,
                                        el equipo de edify te ayudará a conseguir un candidato para tu propuesta.</p>
                                    <button className={styles.mapContainer_jobOffer_button} onClick={premiumCheck(handleCreateJobOffer)}>
                                        <PlusIcon width={20} height={20} />
                                        Crear oferta de trabajo</button>
                                </motion.div>
                            )}
                            <WorkersMaps
                                ciudadesArray={workBagUsers}
                                setSelectedUser={setSelectedUser}
                                setIsFiltered={setIsFiltered}
                                isFiltered={isFiltered}
                                setZoom={setZoom}
                                setCenter={setCenter}
                                setCoordinatesArray={setCoordinatesArray}
                                setLoading={setLoading}
                                loading={loading}
                                coordinatesArray={coordinatesArray}
                                bounds={bounds}
                                setBounds={setBounds}
                                setShowJobOfferNotification={setShowJobOfferNotification}
                                setShowSmallMap={setShowSmallMap}
                                showSmallMap={showSmallMap}
                                zoom={zoom}
                                center={center}
                                initialCenter={initialCenter}
                                initialZoom={initialZoom}
                            />
                        </section>
                    </div>
                ) : (
                    isPhone ? (
                        <ListedWorkersForMobile
                            workers={workBagUsers}
                            setSelectedUser={setSelectedUser}
                            setIsFiltered={setIsFiltered}
                            loading={loading}
                        />
                    ) : (
                        <ListedWorkers
                            workers={workBagUsers}
                            setSelectedUser={setSelectedUser}
                            setIsFiltered={setIsFiltered}
                            loading={loading}
                        />
                    )
                )}
                <ToastContainer
                    position="bottom-right" />
                {workOfferModal && (
                    <OfertaDeTrabajo
                        setShowJobOfferNotification={setShowJobOfferNotification}
                    />
                )}
            </section>
        </main>
    );
}

const mapStateToProps = (state) => {
    return {
        workBagUsers: state.register.workBagUsers,
        isUserPremium: state.general.isUserPremium,
        workOfferModal: state.general.workOfferModal,
    };
}

export default connect(mapStateToProps, { openTutorialModal, getAllWorkBagUsers, openWorkOfferModal })(MercadoDeTrabajo);
