import React, { useState } from "react";
import styles from "./HeaderWithButton.module.scss";
import ImageModal from '../../Dashboard/AllProjectsItem/ImageModal'

const HeaderWithButton = ({ image, name, showImage=true }) => {
const [openImage, setOpenImage] = useState(false)
  function handleOnClick() {
    setOpenImage(!openImage)
  }
  return (
    showImage && <header>
      {image ? <img src={image} onClick={handleOnClick} alt={name} className={styles.withButton_headingimg} /> : null}
      {openImage && <ImageModal image={image} onClose={handleOnClick} />}      
    </header>
  );
};

export default HeaderWithButton;


