import React, { useState, useEffect } from "react";
import styles from "./SubProyectoItem.module.scss";
import { ReactComponent as Edit } from "../../../../../assets/svg/editarSkill.svg"
import { ReactComponent as Delete } from "../../../../../assets/svg/description-sub.svg"
import { connect } from "react-redux";
import { setEditSendSubProject, updateSubCotizacion } from "../../../../../store/actions/index";
import { updateSubProject, deleteSubProjects, deleteSubProjectFromReducer } from "../../../../../store/actions/profileActions"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { checkDisabled } from "../../../../../utils/utilsFunctions";
import { ReactComponent as Trash } from "../../../../../assets/svg/description-sub.svg";
import { useNavigate } from "react-router-dom";


const SubProyectoItem = ({
  updateSubProject,
  deleteSubProjects,
  deleteSubProjectFromReducer,
  subProjectId,
  subProjectEdit,
  projectIdForSub,
  subProjectsToEdit,
  projectToEditId,
  isEditing,
  ...props }) => {
  const [open, openHandler] = useState(false);
  const [hoverStyles, setHoverStyles] = useState(false);
  const [file, setFile] = useState('')
  const [isDateInput, setIsDateInput] = useState(false);
  const [showMetricInput, setShowMetricInput] = useState(false);
  const [showTechnicalInput, setShowTechnicalInput] = useState(false);
  const [showBluePrintsInput, setShowBluePrintsInput] = useState(false);
  const [showBudgetInput, setShowBudgetInput] = useState(false);
  const [subProject, setSubProject] = useState(props.subproject);
  const [hasBluePrints, setHasBluePrints] = useState(false);
   const [selectFileOrlink, setSelectFileOrLink] = useState(false);
   const [isFileOrLink, setIsFileOrLink] = useState(false);
 const navigate = useNavigate();

  useEffect(() => {
    if (props && props.subproject && props.subproject.budget_payroll || props.subproject.quotation_payroll) {
      try {
        const url = URL.createObjectURL(props.subproject.budget_payroll || props.subproject.quotation_payroll)
        setFile(url)
      } catch (error) {
        setFile(props.subproject.budget_payroll || props.subproject.quotation_payroll)
      }

    }
  }, [props])

 

  props.subproject.endDate = props.subproject?.endDate?.split("T")[0];
  props.subproject.startDate = props.subproject?.startDate?.split("T")[0];


  const editHandler = (values) => {

    const data = {
      id: parseInt(props.subproject.id) || parseInt(subProjectId),
      name: props.subproject.name,
      description: values.description,
      budget: values.file,
      link: values.link,
      endDate: values.endDate,
      startDate: values.startDate,
      metricComputation: values.metric_computation,
      technicalSpecifications: values.technical_specifications,
      archivoPlanos: values.archivoPlanos,
      navigate
    }
    updateSubProject(data, parseInt(projectToEditId));
    setSubProject(data);
    openHandler(!open);
    setShowMetricInput(false);
    setShowTechnicalInput(false);
    setShowBudgetInput(false);
  };

  useEffect(() => {
    setSubProject(isEditing ? props.subproject : subProject);
  }, [subProject, props.subproject]);

  //Defino si tiene cargado algun archivo o link como planos
   useEffect(() => {
    if ((subProject?.planos === "null" || subProject?.planos === null) && (subProject?.link === "undefined" || subProject?.link === "")) { 
      setHasBluePrints(false);
    } else {
      setHasBluePrints(true);
    }
  }, [subProject]);

  //Defino si tiene cargado, o un archivo o un link para ver que dato mostrar
  useEffect(() => {
    if (subProject?.planos === "null" || subProject?.planos === "undefined") {
      setIsFileOrLink(true)
    } else {
      setIsFileOrLink(false)
    }
  }, [subProject]);


  const deleteSubProjectHandler = () => {
    if (isEditing) {
      deleteSubProjects(parseInt(props.subproject.id || subProjectId), projectToEditId);
    } else {
      deleteSubProjectFromReducer(props.subproject.subProyectoListId);
    };
  };

  //VALIDACIONES
  const subProyectosSchema = Yup.object().shape({
    descriptionEdit: Yup.string().required("El campo es requerido"),

    linkEdit: Yup.string().required("El campo es requerido"),

    cierreEdit: Yup.date().required("El campo es requerido"),
  });



  return (
    <div className={`${styles.proyectItem}`}>
      <div
        className={`${styles.proyectActions} ${props.subproject.finished ? styles.finished : ""}`}

      >
        <p className={`${styles.description} ${hoverStyles && styles.isHovered} ${open ? styles.open : ""}`}>
          {props.subproject.name} {props.subproject.status === "OPEN" ? "| ABIERTO" : ""}
        </p>
        <div className={`${styles.iconContainer}`}>
          <div className={styles.editContainer}
            onMouseEnter={() => setHoverStyles(true)}
            onMouseLeave={() => setHoverStyles(false)}
            onClick={() => openHandler(!open)}>
            <p className={styles.iconContainer_text}>{open ? "cancelar edición" : "editar"}</p>
            <div className={`${styles.iconEdit} ${open ? styles.iconEdit_open : ""}`}>
              <Edit />
            </div>
          </div>
          <div className={styles.borderIconDelete} onClick={(e) => {
            e.stopPropagation();
            deleteSubProjectHandler()
          }}>
            <p className={styles.iconContainer_text}>eliminar</p>
            <div className={`${styles.iconDelete} ${open ? styles.iconDelete_open : ""}`}  >
              <Delete />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.proyectInput}>
        {open ? (
          <Formik
            initialValues={{
              description: props.subproject.description === "undefined" ? "" : subProject.description !== props.subproject.description ? subProject.description : props.subproject.description,
              link: props.subproject.link === "undefined" ? "" : subProject.link !== props.subproject.link ? subProject.link : props.subproject.link,
              startDate: props.subproject.startDate === undefined ? "" : subProject.startDate !== props.subproject.startDate ? subProject.startDate : props.subproject.startDate,
              endDate: props.subproject.endDate === undefined ? "" : subProject.endDate !== props.subproject.endDate ? subProject.endDate : props.subproject.endDate,
              file: props.subproject.plantillaDePresupuesto ? "" : props.subproject.plantillaDePresupuesto || subProject.plantillaDePresupuesto,
              metric_computation: props.subproject.metric_computation || subProject.metric_computation,
              technical_specifications: props.subproject.technical_specifications || subProject.technical_specifications,
              archivoPlanos: props.subproject.planos || subProject.planos, 
            }}
            validationSchema={subProyectosSchema}
          >

            {({ handleSubmit, values, initialValues, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className={`${styles.formContainer} ${open ? styles.open : ""}`}>
                <div className={styles.fieldWrapper_documentacionHeader}>
                  <p>INFORMACIÖN ADICIONAL PARA COTIZAR:</p>
                </div>
                <div className={styles.fieldWrapper_documentacionHeader_body}>
                  <div className={styles.fieldWrapper_linkHeader}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Planos:</p>
                    {hasBluePrints ? (
                      isFileOrLink ? (
                        <Field id='link' name='link' placeholder='Por ej: Google Drive, Dropbox, etc.' className={styles.fieldWrapper_documentationField} />
                      ) : (
                        <div className={styles.fieldWrapper_documentationFileField}>
                        {showBluePrintsInput ? (
                        <Field 
                          id='planosFile' 
                          name='planosFile' 
                          type='file' 
                          accept=".pdf, .xlsx, .xls"
                          className={styles.fileInput}
                          onChange={(event) => {
                            event.currentTarget.files[0] &&
                              setFieldValue("archivoPlanos", event.currentTarget.files[0]);
                          }}
                          />
                        ) : (
                          <>
                           <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowBluePrintsInput(true)}>
                            Actualizar archivo
                          </button>
                          <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.planos ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                          </>
                        )}
                        </div>
                      )
                    ) : (    
                    <div>
                          <div className={styles.radioGroup}>
                            <label className={styles.radioGroup_label}>
                              <input
                                type="radio"
                                name="uploadOption"
                                value="link"
                                checked={selectFileOrlink === 'link'}
                                onChange={() => setSelectFileOrLink('link')}
                                className={styles.radioGroup_label_input}
                              />
                              Cargar link de acceso
                            </label>
                            <label className={styles.radioGroup_label} title="PDF o excel">
                              <input
                                type="radio"
                                name="uploadOption"
                                value="archivoPlanos"
                                checked={selectFileOrlink === 'planosFile'}
                                onChange={() => setSelectFileOrLink('planosFile')}
                                className={styles.radioGroup_label_input}
                              />
                              Cargar archivo
                            </label>
                          </div>
                          {selectFileOrlink === 'link' && (
                            <div className={styles.fieldWrapperLink}>
                              <div className={styles.relative}>
                                <Field
                                  className={styles.fieldWrapper_addLinkInEdit}
                                  id="link"
                                  name="link"
                                  placeholder="Por ej: Google Drive, Dropbox, etc."
                                />
                              </div>
                            </div>
                          )}
                          {selectFileOrlink === 'planosFile' && (
                            <div className={styles.fieldWrapperLink}>
                              <div className={styles.relative}>
                                <Field
                                  className={styles.fileName}
                                  id="planos"
                                  name="planos"
                                  type="file"
                                  accept=".pdf, .xlsx, .xls"
                                  onChange={(event) => {
                                    event.currentTarget.files[0] &&
                                      setFieldValue("archivoPlanos", event.currentTarget.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                    )}
                  </div>
                  <div className={styles.fieldWrapper_linkHeader}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Cómputo métrico:</p>
                    <div className={styles.fieldWrapper_documentationFileField}>
                      {showMetricInput ? (
                        <Field
                          type='file'
                          id='computation'
                          name='computation'
                          className={styles.fileInput}
                          accept=".pdf, .xlsx, .xls"
                          onChange={(event) => {
                            event.currentTarget.files[0] &&
                              setFieldValue("metric_computation", event.currentTarget.files[0]);
                          }}
                        />
                      ) : (
                        <>
                          <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowMetricInput(true)}>
                            Actualizar archivo
                          </button>
                          <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.metric_computation ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.fieldWrapper_linkHeader}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Especificaciónes técnicas:</p>
                    <div className={styles.fieldWrapper_documentationFileField}>
                      {showTechnicalInput ? (
                        <Field
                          type='file'
                          id='techinicalSpecs'
                          name='techinicalSpecs'
                          className={styles.fileInput}
                          accept=".pdf, .xlsx, .xls"
                          onChange={(event) => {
                            event.currentTarget.files[0] &&
                              setFieldValue("techinical_specification", event.currentTarget.files[0]);
                          }}
                        />
                      ) : (
                        <>
                          <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowTechnicalInput(true)}>
                            Actualizar archivo
                          </button>
                          <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.technical_specifications ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.fieldWrapper_linkHeader}>
                    <p className={styles.fieldWrapper_linkHeader_subTitle}>Planilla de cotización:</p>
                    <div className={styles.fieldWrapper_documentationFileField}>
                      {showBudgetInput ? (
                        <Field
                          type='file'
                          id='budgetFile'
                          name='bugetFile'
                          accept=".pdf, .xlsx, .xls"
                          className={styles.fileInput}
                          onChange={(event) => {
                            event.currentTarget.files[0] &&
                              setFieldValue("file", event.currentTarget.files[0]);
                          }}
                        />
                      ) : (
                        <>
                          <button className={styles.fieldWrapper_documentationFileField_button} onClick={() => setShowBudgetInput(true)}>
                            Actualizar archivo
                          </button>
                          <p className={styles.fieldWrapper_documentationFileField_txt}>{props.subproject.budget_payroll ? "(1)Archivo cargado" : "No se ha cargado un archivo"}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.fieldWrapper_linkHeader}>
                  <p className={styles.fieldWrapper_linkHeader_subTitle}>Descripción particular:</p>
                  <div className={styles.fieldWrapper}>
                  <Field id='description' name='description' placeholder='Carga una breve descripción' as='textarea' className={styles.fieldWrapper_textArea} />
                </div>
                </div>              
                <div className={styles.fieldWrapper_linkHeader}>
                  <div className={styles.fieldWrapper_linkHeader_subTitle}>
                    Fechas de recepción de presupuestos:
                  </div>
                  <div className={styles.fieldWrapper_dateHeader_field}>
                    <div className={styles.fieldWrapper_dateHeader_subTitle}>
                      Desde:

                      <div className={styles.fieldWrapper_dateHeader_value}>
                        {props.subproject.startDate === undefined ? (
                          <Field
                            type={isDateInput ? "date" : "text"}
                            id='startDate' name='startDate'
                            placeholder="Seleccione una fecha"
                            className={styles.fieldWrapper_dateHeader_valueInput}
                            value={values.startDate}
                            onClick={() => setIsDateInput(true)} />)
                          : (<Field type="date" id='startDate' name='startDate' className={styles.fieldWrapper_dateHeader_valueInput} />)}
                      </div>
                    </div>
                    <div className={styles.fieldWrapper_dateHeader_subTitle}>
                      Hasta:

                      <div className={styles.fieldWrapper_dateHeader_value}>
                        {props.subproject.endDate === undefined ? (
                          <Field
                            type={isDateInput ? "date" : "text"}
                            id='endDate' name='endDate'
                            placeholder="Seleccione una fecha"
                            className={styles.fieldWrapper_dateHeader_valueInput}
                            value={values.endDate} onClick={() => setIsDateInput(true)} />)
                          : (<Field type="date" id='endDate' name='endDate' className={styles.fieldWrapper_dateHeader_valueInput} />)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.fieldWrapper_buttonContainer}>
                  <button type='button' className={styles.buttonCambios_cancelar} onClick={() => openHandler(!open)}>
                    <Trash />
                    Cancelar
                  </button>
                  <button type='button' className={styles.buttonCambios} disabled={checkDisabled(initialValues, values)} onClick={() => { editHandler(values); }}>
                    Guardar Cambios
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subProjectEdit: state.profile.subProjectId,
    subProjectId: state.profile.subProjectId?.id,
    projectIdForSub: state.profile.subProjectId?.proyecto || state.profile.projectIdForSub,
    projectToEditId: state.profile.projectToEdit.id,
    subProjectsToEdit: state.profile.projectToEdit.subproyecto,
  };
};

export default connect(mapStateToProps, {
  deleteSubProjects,
  setEditSendSubProject,
  updateSubProject,
  updateSubCotizacion,
  deleteSubProjectFromReducer,
})(SubProyectoItem);
