import React, { useState, useEffect } from "react";
import styles from "./FilterPrivateProjects.module.scss";
import { filterQuotations, getSubquotationType, filterPrivateProjects, getSkills } from "../../../../store/actions";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/infoIcon.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/arrow-down.svg";
import { connect } from "react-redux";


const FilterPrivateProjects = ({
    filterQuotations,
    setIsFiltered,
    setIsAdjudicated,
    listaDeRubros,
    getSubquotationType,
    getSubprojectsType,
    filterPrivateProjects,
    page,
    resultados,
    setIsLoading,
    totalDeProyectosMostrados,
    getSkills,
    ...props }) => {

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Todos");
    const [showWorkTypeOptions, setShowWorkTypeOptions] = useState(false);
    const [placeInput, setOpenPlaceInput] = useState(false);
    const [showDateOptions, setShowDateOptions] = useState(false);
    const [showExactDateInput, setShowExactDateInput] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [selectedWorkType, setSelectedWorkType] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState("Pesos");
    const [minAmount, setMinAmount] = useState(1);
    const [maxAmount, setMaxAmount] = useState(5000000000);
    const [asociatedWords, setAsociatedWords] = useState("");
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [selectedSort, setSelectedSort] = useState("Recientes");
    const [dateFilterHeader, setDateFilterHeader] = useState("Fecha de apertura");

    useEffect(() => {
        getSkills();
    }, []);

    //Funcion que maneja el click en los filtros
    const handleFilterClick = (filterName) => {
        if (selectedFilter === filterName) {
            setSelectedFilter(null);
        } else {
            setSelectedFilter(filterName);
        }
    };

    //Maneja el el estado del título del filtro por fecha y los valores del rango de monto
    useEffect(() => {
        if (selectedCurrency === "Dólares") {
            setMinAmount(1);
            setMaxAmount(500000000);
        } else {
            setMinAmount(1);
            setMaxAmount(5000000000);
        }
        if (selectedOption === "Privadas") {
            setDateFilterHeader("Fecha de cierre");
        } else {
            setDateFilterHeader("Fecha de apertura");
        }
    }, [selectedOption, selectedCurrency]);

    const handleOptionSelect = (option) => {
        setSelectedWorkType(option);
        setSelectedFilter(null);
    };

    useEffect(() => {
        getSubquotationType();
    }, []);

    //Formateo la fecha a mostrar
    const formatDate = (date) => {
        const dateArray = date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];
        return `${day}/${month}/${year}`;
    };

    //Generamos rangos para busqueda por fecha
    const generateDateRange = (dateRange) => {
        const today = new Date();
        const thirtyDaysFromNow = new Date(today);
        thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
        const sixtyDaysFromNow = new Date(today);
        sixtyDaysFromNow.setDate(sixtyDaysFromNow.getDate() + 60);
        const ninetyDaysFromNow = new Date(today);
        ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() + 90);

        switch (dateRange) {
            case "Próximos 30 días":
                return [today, thirtyDaysFromNow];
            case "30 a 60 días":
                return [thirtyDaysFromNow, sixtyDaysFromNow];
            case "60 a 90 días":
                return [sixtyDaysFromNow, ninetyDaysFromNow];
            default:
                return null;
        }
    };


    //Funcion para filtrar los proyectos 
    const handleFilter = (hasFilter) => {

        //Maneja el cambio de pagina
        const pageToSend = hasFilter ? 1 : page;

        //Formatea la fecha para enviar
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        //Traemos la fecha de hoy
        const todayDate = new Date();
        const today = formatDate(todayDate);

        //Generamos el rango de fechas
        const dateRange = generateDateRange(selectedDateRange);
        const dateFrom = selectedDate !== null ? today : dateRange ? formatDate(new Date(dateRange[0])) : null;
        const dateTo = selectedDate !== null ? selectedDate : dateRange ? formatDate(new Date(dateRange[1])) : null;


        const filter = {
            sort: selectedSort === "Recientes" ? "DESC" : "ASC",
            //asociatedWords: asociatedWords,
            zone: asociatedWords,
            //currency: selectedCurrency === "Dólares" ? "USD" : "$",
            workType: selectedWorkType === null ? null : selectedWorkType,
            dateRange: { dateFrom, dateTo },
        }

        filterPrivateProjects(filter, pageToSend, 10);

    }

    const handleReset = () => {
        if (
            selectedWorkType === null &&
            selectedDateRange === null &&
            selectedDate === null &&
            asociatedWords === "" &&
            selectedSort === "Recientes"
        )
            return;
        setSelectedWorkType(null);
        setSelectedDateRange(null);
        setSelectedDate(null);
        setAsociatedWords("");
        setSelectedSort("Recientes");

        const filter = {
            sort: null,
            zone: null,
            workType: null,
            dateRange: null,
        }
        filterPrivateProjects(filter, 1, 10, true);
    }


    return (
        <div className={styles.filterContainer}>
            <div className={styles.mainFilter}>
                <div
                    className={styles.mainFilter_projectType}
                    onClick={() => setShowWorkTypeOptions(!showWorkTypeOptions)}
                >
                    <div className={styles.mainFilter_projectType_infoContainer} >
                        <div className={styles.mainFilter_projectType_itemContent}>
                            <p className={styles.mainFilter_projectType_title}>
                                Rubros a cotizar
                            </p>
                            <p className={styles.mainFilter_projectType_info}>
                                {selectedWorkType !== null ? selectedWorkType : 'Todos'}
                            </p>
                        </div>
                        <div className={styles.mainFilter_projectType_infoIcon}>
                            <InfoIcon width={20} height={20} />
                        </div>
                    </div>
                    {showWorkTypeOptions && (
                        <div className={styles.mainFilter_projectType_options}>
                            {Array.isArray(listaDeRubros) &&
                                listaDeRubros.map((listaDeRubros) => (
                                    <div className={styles.mainFilter_projectType_options_item} onClick={() => handleOptionSelect(listaDeRubros.name)} key={listaDeRubros.id}>{listaDeRubros.name}</div>
                                ))}
                        </div>
                    )}
                </div>
                <div className={styles.mainFilter_organism} onClick={() => setShowDateOptions(!showDateOptions)}>
                    <div className={`${styles.mainFilter_organism_infoContainer} ${selectedFilter === 'organism' ? styles.selectedFilter : ''}`}>
                        <div className={styles.mainFilter_organism_itemContent}>
                            <p className={styles.mainFilter_organism_title}>
                                Fecha de apertura de la cotización
                            </p>
                            <p className={styles.mainFilter_organism_info}>{selectedDateRange || selectedDate ? selectedDateRange || formatDate(selectedDate) : "Elegir"}</p>
                        </div>
                        <div className={styles.mainFilter_organism_infoIcon}>
                            <InfoIcon width={20} height={20} />
                        </div>
                    </div>
                    {showDateOptions && (
                        <div className={styles.advancedFilter_filterDate_options_menu}>
                            <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("Próximos 30 días"); setShowDateOptions(false) }}>Proximos 30 días</div>
                            <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("30 a 60 días"); setShowDateOptions(false) }}>30 a 60 días</div>
                            <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("60 a 90 días"); setShowDateOptions(false) }}>60 a 90 días</div>
                            <div
                                className={styles.advancedFilter_filterDate_options_item}
                                onClick={() => { setShowExactDateInput(true); setSelectedDateRange(null) }}
                            >
                                Fecha exacta
                            </div>
                            {showExactDateInput && (
                                <input
                                    type="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    className={styles.advancedFilter_filterDate_options_item} />
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.mainFilter_jurisdiction} onClick={() => setOpenPlaceInput(!placeInput)}>
                    <div className={styles.mainFilter_jurisdiction_infoContainer}>
                        <div className={styles.mainFilter_jurisdiction_itemContent}>
                            <p className={styles.mainFilter_jurisdiction_title}>
                                Lugar
                            </p>
                            <p className={styles.mainFilter_jurisdiction_info}>
                                {asociatedWords !== "" ? asociatedWords : 'Todos'}
                            </p>
                        </div>
                        <div className={styles.mainFilter_jurisdiction_infoIcon}>
                            <InfoIcon width={20} height={20} />
                        </div>
                    </div>
                    {placeInput && (
                        <div className={styles.mainFilter_filterAsociatedWords_options}>
                            <input
                                type="text"
                                value={asociatedWords}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => setAsociatedWords(e.target.value)}
                                className={styles.mainFilter_filterAsociatedWords_options_input} />
                        </div>
                    )}
                </div>
                <div className={`${styles.mainFilter_button} ${selectedOption === "Privadas" ? styles.searchButtonPrivate : ""}`} onClick={() => handleFilter(true)}>
                    <p className={`${styles.mainFilter_button_title} ${selectedOption === "Privadas" ? styles.title : ""}`}>Buscar</p>
                </div>
            </div>
            <div className={styles.filterContainer_searchInfo}>
                <div className={styles.filterContainer_searchInfo_sortContainer}>
                    <div className={styles.filterContainer_searchInfo_sort_txt}>Ordenar por:</div>
                    <div className={styles.filterContainer_searchInfo_sort}>
                        <div className={styles.filterContainer_searchInfo_sort_selected}
                            onMouseEnter={() => setShowSortOptions(true)}
                            onMouseLeave={() => setShowSortOptions(false)}>
                            <p className={styles.filterContainer_searchInfo_sort_selected_txt}>{selectedSort === "Antiguos" ? selectedSort : "Recientes"}</p>
                            <div className={styles.filterContainer_searchInfo_sort_selected_iconContainer}>
                                {showSortOptions ? <ArrowDown width={20} height={25} /> : <ArrowUp width={20} height={25} />}
                            </div>
                            {showSortOptions && (
                                <div className={styles.filterContainer_searchInfo_sort_options}>
                                    <p className={styles.filterContainer_searchInfo_sort_options_item}
                                        onClick={() => { setSelectedSort("Recientes"); setShowSortOptions(false) }}>Recientes</p>
                                    <p className={styles.filterContainer_searchInfo_sort_options_item}
                                        onClick={() => { setSelectedSort("Antiguos"); setShowSortOptions(false) }}>Antiguos</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.filterContainer_searchInfo_txtContainer}>
                    <p className={styles.filterContainer_searchInfo_txt}>Se encontraron {resultados} resultados</p>
                    {resultados > 10 && (
                        <p className={styles.filterContainer_searchInfo_txt}>Mostrando {totalDeProyectosMostrados} de {resultados}</p>
                    )}
                </div>
                <div className={styles.filterContainer_searchInfo_txtContainer_clearFilter}>
                    <p className={styles.filterContainer_searchInfo_txtContainer_clearFilter_txt} onClick={() => handleReset()}>Limpiar filtros</p>
                </div>
            </div>
            <div className={styles.filterContainer_sortInfo}>
                <ul className={styles.filterContainer_sortInfo_list}>
                    <li className={styles.filterContainer_sortInfo_list_item}>Imagen <ArrowDown height={15} width={15} /></li>
                    <li className={styles.filterContainer_sortInfo_list_item}> Nombre <ArrowDown height={15} width={15} /></li>
                    <li className={styles.filterContainer_sortInfo_list_item}>Rubro <ArrowDown height={15} width={15} /></li>
                    <li className={styles.filterContainer_sortInfo_list_item}>Fecha de apertura <ArrowDown height={15} width={15} /></li>
                    <li className={styles.filterContainer_sortInfo_list_item}>Fecha de cierre <ArrowDown height={15} width={15} /></li>
                    <li className={styles.filterContainer_sortInfo_list_item}> Lugar <ArrowDown height={15} width={50} /></li>
                    <li className={styles.filterContainer_sortInfo_list_item}>Acciones <ArrowDown height={15} width={50} /></li>
                    <li className={styles.filterContainer_sortInfo_list_item}>Creador<ArrowDown height={15} width={50} /></li>
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {

    return {
        resultados: state.dashboard.itemCount,
        totalDeProyectosMostrados: state.dashboard.allProjects?.length,
        listaDeRubros: state.register.skills
    };
};

export default connect(mapStateToProps, {
    filterQuotations,
    getSubquotationType,
    getSkills,
    filterPrivateProjects,
})(FilterPrivateProjects);
