import React, { useState } from "react";
import { ReactComponent as XIcon } from "../../../assets/svg/x.svg";
import styles from "./ProjectDetails.module.scss";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../../assets/images/fondoObraPrivada.jpg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg"
import { ReactComponent as TrashIcon } from "../../../assets/svg/trashIcon.svg";
import { deleteProyecto } from "../../../store/actions";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { ReactComponent as NoImage } from "../../../assets/svg/noImageIcon.svg";
import { connect } from "react-redux"
import Maps from "../Maps/Maps";

const ProjectDetails = ({ project, deleteProyecto }) => {
    const [showProject, setShowProject] = useState(false);
    const [showItemInfo, setShowItemInfo] = useState(1);

   const navigate = useNavigate();

    const handleShowProject = () => {
        setShowProject(!showProject);
    }

    const coverImage = project?.proyecto_images[0]?.image || DefaultImage;

    //Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate();
        return `${day}/${month}/${year}`;
    };

    const handleRedirectToProject = () => {
        navigate(`/proyectosprivados/${project.id}`);
    };

    const handleDeleteProject = () => {
        deleteProyecto(parseInt(project.id), navigate)
        setTimeout(() => {
            setShowProject(false)
        }, 1000)
    }

    return (
        project?.type === "PRIVATE" && (
            <>
                <div className={styles.container}>
                    <div className={styles.container_image}>
                        <img className={styles.container_image_img} src={coverImage} alt="proyecto" />
                    </div>
                    <div className={styles.container_info}>
                        <div className={styles.container_info_text} onClick={() => handleShowProject()}>
                            <p>{project?.name}</p>
                            <div className={styles.container_info_button}>
                                <ArrowRightIcon height={12} width={12} />
                            </div>
                        </div>
                    </div>
                </div>
                {showProject && (
                    <div className={styles.container_portfolio}>
                        <div className={styles.container_portfolio_container}>
                            <div className={styles.container_portfolio_headerContainer}>
                                <h2 className={styles.container_portfolio_info_title} onClick={() => handleRedirectToProject()}>{project?.name}</h2>
                                <div className={styles.container_portfolio_headerContainer_buttons}>
                                    <div className={styles.container_portfolio_delete} onClick={() => handleDeleteProject()}>
                                        <TrashIcon width={25} height={25} />
                                    </div>
                                    <div className={styles.container_portfolio_editButton} onClick={() => handleRedirectToProject()}>
                                        Editar
                                        <ArrowRightIcon height={10} width={10} />
                                    </div>
                                    <div className={styles.container_portfolio_close} onClick={() => handleShowProject()}>
                                        <XIcon width={18} height={18} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.container_portfolio_container_body}>
                                <div className={styles.container_portfolio_container_content}>
                                    <div className={styles.container_portfolio_info}>
                                        <div className={styles.container_portfolio_info_header}>
                                            <h3 className={styles.container_portfolio_info_subTitle}>INFORMACIÓN GENERAL:</h3>
                                        </div>
                                        <div className={styles.container_portfolio_info_headers}>
                                            <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 1 && styles.isActive}`} onClick={() => setShowItemInfo(1)}>Imágenes</h4>
                                            <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 2 && styles.isActive}`} onClick={() => setShowItemInfo(2)}>Descripción</h4>
                                            <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 3 && styles.isActive}`} onClick={() => setShowItemInfo(3)}>Zona</h4>
                                            <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 4 && styles.isActive}`} onClick={() => setShowItemInfo(4)}>Fechas</h4>
                                        </div>
                                        {showItemInfo === 1 && (
                                            <div className={styles.container_portfolio_images}>
                                                <div className={styles.container_portfolio_images_imagesContent}>
                                                    {project?.proyecto_images.length === 0 ? (
                                                        <div className={styles.container_portfolio_images_noImages}>
                                                            <NoImage width={100} height={100} />
                                                            No hay imágenes en el proyecto
                                                        </div>) : (
                                                        <ResponsiveMasonry
                                                            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                                        >
                                                            <Masonry gutter="10px">
                                                                {project?.proyecto_images.map((image, index) => (
                                                                    <img className={styles.container_portfolio_images_item_img} key={index} src={image.image} alt="portfolio" />
                                                                ))}
                                                            </Masonry>
                                                        </ResponsiveMasonry>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {showItemInfo === 2 && (
                                            < div className={styles.container_portfolio_info_text}>
                                                <p className={styles.container_portfolio_info_subTitle_txt}>"{project?.description}"</p>
                                            </div>

                                        )}
                                        {showItemInfo === 3 && (
                                            <div className={styles.container_portfolio_info_text}>
                                                <div className={styles.container_portfolio_info_map}>
                                                    <Maps ciudad={project?.zone} />
                                                </div>
                                            </div>

                                        )}
                                        {showItemInfo === 4 && (
                                            <div className={styles.container_portfolio_info_text}>
                                                <p className={styles.container_portfolio_info_subTitle_txt}>Inicio: {formatDate(project?.startDate)}</p>
                                                <p className={styles.container_portfolio_info_subTitle_txt}>Fin: {formatDate(project?.endDate)}</p>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    );
};

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, { deleteProyecto })(ProjectDetails);