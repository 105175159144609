import React, { useEffect, useState } from "react";
import styles from "./TempBanner.module.scss";
import ImageCarousel from "./ImageCarousel"; 


const TempBanner = ({newMaterialBudget, newProject}) => {
  const [isSticky, setIsSticky] = useState(false);

  //Seteamos en que punto del scroll se va a activar el sticky
  const offset = 380; 

  //Agregamos el evento scroll al window
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > offset) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={`${styles.container} ${isSticky ? styles.containerCarouselSticky : ""}`} >
        <ImageCarousel newMaterialBudget={newMaterialBudget} newProject={newProject}/>
    </div>
  );
};

export default TempBanner;
