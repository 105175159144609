import React from "react";
import { ReactComponent as EmptyProyectIcon } from "../../../assets/svg/misPostulacionesEmpty.svg";
import styles from "./EmptyStateMyPostulations.module.scss";

const EmptyStateMyPostulations = ({ title = "" }) => {
  return (
    <div className={styles.EmptyState}>
      <EmptyProyectIcon />
      <h3>{title}</h3>
    </div>
  );
};

export default EmptyStateMyPostulations;