import React, { useEffect, useState } from "react";
import styles from "./Reputacion.module.scss";
import { connect } from "react-redux";
import { ReactComponent as StarFull } from "../../../assets/svg/starFullSvg.svg";
import { ReactComponent as HalfStar } from "../../../assets/svg/halfStarSvg.svg";
import { ReactComponent as StarEmpty } from "../../../assets/svg/emptyStarSvg.svg";
import {
  getCalificationsByUser,
  getVisits,
} from "../../../store/actions/profileActions";
import { openCalificationModal } from "../../../store/actions";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";

const Reputacion = ({
  userData,
  getCalificationsByUser,
  userId,
  userCalifications,
  openCalificationModal,
  profileVisualizerData,
  profileVisits,
  getVisits,
  myPerson,
}) => {
  const id = userId ? userId : userData.id;

  useEffect(() => {
    getCalificationsByUser(id);
    getVisits();
  }, []);


  const userStars = myPerson ? userData?.stars?.toFixed(1 - (userData?.stars % 1 === 0)) : profileVisualizerData?.stars?.toFixed(1 - (profileVisualizerData?.stars % 1 === 0));

  let stars = [];
  const starsCount = userStars !== null ? Math.floor(userStars) : 0; 
  
  for (let i = 0; i < 5; i++) {
      if (i < starsCount) {
          stars.push(<StarFull key={i} width={15} height={15} />);
      } else if (i === starsCount && userStars % 1 !== 0) {
          stars.push(<HalfStar key={i} width={15} height={15} />);
      } else {
          stars.push(<StarEmpty key={i} width={15} height={15} />);
      }
  }

  return (
    <div className={styles.ReputacionContainer}>
      <div className={styles.ReputacionContainer_item}>
        <div className={styles.ReputacionContainer_header}>
          <h4>Promedio de calificaciones</h4>
        </div>
       <div className={styles.ReputacionContainer_starsCount}>
          <span className={styles.ReputacionContainer_numero}>{!userStars ? "0" : userStars}</span>
          <div className={styles.ReputacionContainer_stars}>
            {stars}
          </div>
        </div>
      </div>
      <div className={styles.ReputacionContainer_item2}>
        <div className={styles.ReputacionContainer_header}>
          <h4>Calificaciones recibidas</h4>
        </div>
        <div className={styles.ReputacionContainer_califications}>
          {userCalifications?.length}
        </div>
      </div>
      {!myPerson && (
      <div className={styles.ReputacionContainer_item3}>
        <div className={styles.ReputacionContainer_header}>
          <h4>Calificar usuario</h4>       
          </div>
        <div className={styles.ReputacionContainer_buttonContainer} onClick={() => openCalificationModal(profileVisualizerData)}>
          <div className={styles.ReputacionContainer_button}>
            Comenzar
          </div>
          <ArrowRightIcon width={10} height={10} />
          </div>
      </div>
      )}
      {myPerson && (
        <div className={styles.ReputacionContainer_item3}>
          <div className={styles.ReputacionContainer_header}>
            <h4>Visitas al perfil</h4>
          </div>
          <div className={styles.ReputacionContainer_visits}>
            {profileVisits.viewsCount}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    stars: state.profile.stars,
    profileVisits: state.profile.profileVisits,
    profileVisualizerData: state.general.profileVisualizer,
    userCalifications: state.profile.userCalifications,
  };
};

export default connect(mapStateToProps, {
  getCalificationsByUser,
  getVisits,
  openCalificationModal,
})(Reputacion);
