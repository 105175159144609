import React, { useState, useEffect } from "react";
import styles from "./FeedTextModal.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import { closeFeedTextModal, openFeedTextModal, openMainModal } from "../../../store/actions/profileActions";
import { createPost } from "../../../store/actions/dashboardActions";
import { ReactComponent as Publicar } from "../../../assets/svg/publicarProyecto.svg";
import { ReactComponent as FotoIcon } from "../../../assets/svg/fotoAdd.svg";
import { ReactComponent as VideoIcon } from "../../../assets/svg/addVideo.svg";


const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2 },
  },
};

const FeedTextModal = ({ closeFeedTextModal, createPost, feedModalConfig, closeMainModal, userData, openMainModal }) => {

  const [imagePreview, setImagePreview] = useState(null);
  const [imageToSend, setImageToSend] = useState(null);
  const [videoToSend, setVideoToSend] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [textToSend, setTextToSend] = useState("");
  const [isPublishEnabled, setIsPublishEnabled] = useState(false);
  //Traemos las props del modal
  const {
    image,
    video,
    text,
  } = feedModalConfig;


  //Maneja cerrar el modal
  const handleGoBack = () => {
    closeFeedTextModal();
  }

  //Maneja el cambio de texto
  useEffect(() => {
    if (textToSend !== null && textToSend !== "") {
      setIsPublishEnabled(true);
    } else {
      setIsPublishEnabled(false);
    }
  }, [textToSend]);

  //Funcion para crear el posteo
  const confirmHandler = () => {
    const descriptionTxt = document.getElementById("descriptionTxt").value;
    const images = imageToSend === null ? "" : imageToSend;
    const video = videoToSend === null ? "" : videoToSend;
    const data = {
      images: images,
      video: video,
      content: descriptionTxt ? descriptionTxt : "",
    }
    openMainModal({
      title: "¿Deseas continuar y publicar?",
      body: "Una vez publicado toda la comunidad podrá ver e interactuar con el posteo",
      isSucces: true,
      confirmHandler: () => {
        createPost(data);
      }
    });
    closeFeedTextModal();
  }
  
  //Definimos el nombre a mostrar
  const fullName = userData.type === "COMPANY" ? userData.razon_social : userData.name + " " + userData.last_name;

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="1"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.MainModal_overlay}
          onClick={closeMainModal}
        >
        <motion.div
          key="2"
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.MainModal}
        >
          <div className={styles.MainModal_container}>
          <div className={styles.MainModal_header}>
            <div
              className={styles.MainModal_header_decline}
              onClick={() => handleGoBack()}
            >
              X
            </div>
            <div className={styles.MainModal_header_imgContainer}>
              <img src={userData.image_profile} alt="profile" className={styles.MainModal_header_img} />
            </div>
            <div className={styles.MainModal_header_txtContainer}>
              <h3 className={styles.MainModal_header_txt}>{fullName}</h3>
              <h5 className={styles.MainModal_header_txt_sub}>Visible por todos</h5>
            </div>
          </div>
          {videoPreview ? (
            <>
              <div className={styles.MainModal_bodyWithVideoContainer}>
                <div className={styles.MainModal_bodyWithVideo}>
                  <video controls className={styles.MainModal_bodyWithVideo_selectedVideo}>
                    <source src={videoPreview} type="video/mp4" />
                  </video>
                </div>
                <div className={styles.MainModal_bodyWithVideo_txtInputContainer}>
                  <textarea
                    id="descriptionTxt"
                    name="descriptionTxt"
                    value={textToSend}
                    placeholder="¿Querés agregarle texto a la publicación?"
                    onChange={(e) => setTextToSend(e.target.value)}
                    className={styles.MainModal_bodyWithVideo_txtInput}
                  />
                </div>
              </div>
              <div className={styles.MainModal_buttonContainer}>
                <div
                  className={videoPreview || isPublishEnabled ? styles.MainModal_optionsItem : styles.MainModal_optionsItemDisabled}
                  onClick={confirmHandler}
                >
                  <span className={styles.margin}>
                    <Publicar />
                  </span>
                  Publicar
                </div>
              </div>
            </>
          ) : (
            <>
          {imagePreview ? (
            <>
              <div className={styles.MainModal_bodyWithImageContainer}>
                <div className={styles.MainModal_bodyWithImage}>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className={styles.MainModal_bodyWithImage_selectedImage}
                  />
                </div>
                <div className={styles.MainModal_bodyWithImage_txtInputContainer}>
                  <textarea 
                      id="descriptionTxt"
                      name="descriptionTxt" 
                      value={textToSend}
                      placeholder="¿Querés agregarle texto a la publicación?"
                      onChange={(e) => setTextToSend(e.target.value)} 
                      className={styles.MainModal_bodyWithImage_txtInput} />
                </div>
              </div>
              <div className={styles.MainModal_buttonContainer}>
                <div
                  className={imagePreview || isPublishEnabled ? styles.MainModal_optionsItem : styles.MainModal_optionsItemDisabled}
                  onClick={confirmHandler}
                >
                  <span className={styles.margin}>
                    <Publicar />
                  </span>
                  Publicar
                </div>
              </div>
            </>     
          ) : (<>
          {video ? (
            <label htmlFor="videoInput" className={styles.MainModal_header_withVideoContainer}>
              <div className={styles.MainModal_header_withVideoContainer}>
                <div className={styles.MainModal_header_withVideo}>
                  <p>Podés agregarle un video a tu posteo </p>
                  <input
                    type="file"
                    accept="video/*"
                    id="videoInput"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setVideoPreview(URL.createObjectURL(file));
                        setVideoToSend(file);
                      }
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </label>      
          ) : (
            <>
            {image ? (
              <label htmlFor="imageInput" className={styles.MainModal_header_withImgContainer}>
                <div className={styles.MainModal_header_withImgContainer}>
                  <div className={styles.MainModal_header_withImg}>
                    <p>Podés agregarle una imagen a tu posteo </p>
                    <input
                      type="file"
                      accept="image/*"
                      id="imageInput"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setImagePreview(URL.createObjectURL(file));
                          setImageToSend(file);
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
              </label>
            ) : (
              <div className={styles.bodyTxt} >
                  <textarea 
                      id="descriptionTxt" 
                      name="descriptionTxt" 
                      value={textToSend}
                      placeholder="¿Que deseas compartir con la comunidad?" 
                      onChange={(e) => setTextToSend(e.target.value)} 
                      className={styles.bodyTxt_input} />
              </div>
            )}
            </>
          )}
          </>
          )}
            {imagePreview ? null : (
              <div className={styles.MainModal_optionsButtonContainer}>
                <div className={styles.MainModal_optionsIconContainer}>
                  {!image && (
                    <>
                  <label htmlFor="imageInput" className={styles.MainModal_optionsIconContainer_label}>
                    <div className={styles.MainModal_optionsIconContainer_imgIconContainer}>
                      <FotoIcon width={30} height={30} />
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      id="imageInput"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setImagePreview(URL.createObjectURL(file));
                          setImageToSend(file);
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                  </label>
                  </>
                  )}
                  {!video && (
                    <>
                    <label htmlFor="videoInput" className={styles.MainModal_optionsIconContainer_label}>
                      <div className={styles.MainModal_optionsIconContainer_videoIconContainer}>
                        <span className={styles.MainModal_optionsIconContainer_videoIconContainer_span}>estoy</span>
                        <VideoIcon width={30} height={30} />
                      </div>
                      <input
                        type="file"
                        accept="video/*"
                        id="videoInput"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            setVideoPreview(URL.createObjectURL(file));
                            setVideoToSend(file);
                          }
                        }}
                        style={{ display: 'none' }}
                      />
                    </label>
                    </>
                  )}
                </div>
                <div
                  className={imagePreview || isPublishEnabled ? styles.MainModal_optionsItem : styles.MainModal_optionsItemDisabled}
                  onClick={confirmHandler}
                >
                  <span className={styles.margin}>
                    <Publicar />
                  </span>
                  Publicar
                </div>             
              </div>
            )}
          </>
          )}
          </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mainModalConfig: state.general.mainModalConfig,
    feedModalConfig: state.general.feedModalConfig,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, { closeFeedTextModal, openFeedTextModal, createPost, openMainModal })(FeedTextModal);