import React, { useEffect, useState, useRef } from "react";
import styles from "./WelcomeLayout.module.scss";
import { connect } from "react-redux";
import {
  sendValidationCode,
  resendMailUserConfirmation,
} from "../../store/actions/authActions";
import { getProfileData } from "../../store/actions";
import Button from "../../components/UI/Forms/Button/Button";
import { fireAnalytics } from "../../utils/firebase.js";
import styled from "styled-components";
import ValidateSuccess from "../ValidateSuccess/ValidateSuccess.js";
import MessageResponse from "../../components/UI/MessageResponse/MessageResponse";
import InitialWrapper from "../../components/Layout/InitialWrapper/InitialWrapper";
import ValidationLoading from "../../components/UI/Loading/ValidationLoading";
import { useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const FormGroup = styled.div`
    margin: 15px 0;
    position: relative;
    width: 100%;
  `;

const CodeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CodeInput = styled.input`
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const WelcomeLayout = ({ codeValidation, sendValidationCode, resendMailUserConfirmation, ...props }) => {
 const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [isValidating, setIsValidating] = useState(false);
  const [itsMailOk, setItsMailOk] = useState(true);
  const [showReSendOptions, setShowReSendOptions] = useState(false);
  const [showReSend, setShowReSend] = useState(false);
  const [countDown, setCountDown] = useState(60);
  const inputRefs = useRef([]);

  const handleCodeChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;
    const newCode = code.substring(0, index) + value + code.substring(index + 1);
    setCode(newCode);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text').slice(0, 6);
    const newCode = pastedText.split('').map((char, index) => {
      if (index < 6) {
        handleCodeChange(index, char);
        return char;
      }
      return '';
    }).join('');
    setCode(newCode);
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      e.preventDefault();
      inputRefs.current[index - 1].focus();
      handleCodeChange(index - 1, '');
    }
  };

useEffect(() => {

  setTimeout(() => {
    setShowReSend(true);
  }, 60000);
}, []);

useEffect(() => {
  const interval = setInterval(() => {
    setCountDown(prevCount => {
      if (prevCount <= 1) {
        clearInterval(interval);
        setShowReSend(true);
        return 0;
      }
      return prevCount - 1;
    });
  }, 1000);

  return () => clearInterval(interval);
}, []);

  useEffect(() => {
    if (props.typeLogo === "success") {
      fireAnalytics("confirmation_success", { category: "navigation" });
    } else {
      fireAnalytics("pending_confirmation", { category: "navigation" });
    }
  }, []);

  const handleResend = (values) => {
    resendMailUserConfirmation(values);
    fireAnalytics("resend_confirmation", { category: "authentication" });
  };

  const handleSendValidationCode = (code) => {
    setIsValidating(true);
    sendValidationCode(code, navigate);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsValidating(false);
    }
      , 4000);
  }, [isValidating]);

  const mailResend = localStorage.getItem("EmailRegister") || props.mail;

  let interactionType = null;

  let saludoType = null;

  const isCodeValid = /^\d*$/.test(code) && code.length === 6;

  let linkCorreo = null;
  if (mailResend.includes("gmail")) {
    linkCorreo = "https://mail.google.com/";
  } else if (
    mailResend.includes("outlook") ||
    mailResend.includes("live") ||
    mailResend.includes("hotmail")
  ) {
    linkCorreo = "https://outlook.live.com/";
  } else if (mailResend.includes("yahoo")) {
    linkCorreo = "https://login.yahoo.com/";
  }
  if (props.typeLogo === "welcome" || props.typeLogo === "error") {
    interactionType = (
      <>
        {linkCorreo ? (
          <FormGroup>
            <a
              className={styles.btn}
              target="_blank"
              href={linkCorreo}
              rel="noopener noreferrer"
            >
              Ir al correo
            </a>
          </FormGroup>
        ) : null}
        <FormGroup>
          <Button disabled={!isCodeValid} onClick={() => handleSendValidationCode(code)}>
            {!isCodeValid ? "Completar el código" : "Verificar código"}
          </Button>
          {!showReSendOptions ? (
          <div className={styles.resend}>
            {showReSend ? (
            <Button
              colored="white"
              onClick={() => setShowReSendOptions(!showReSendOptions)}
              style={{ border: "none", padding: "0" }}
              className={styles.hover}
            >
              Reenviar código
            </Button>
          ) : (
            <p className={styles.resendText}>
              ¿No recibiste el código? Reenviar en {countDown} segundos.
            </p>
          )}
          </div>
        ) : (
          <Formik
            initialValues={{
              phone: '',
              confirmationMethod: 'sendEmail',
              email: mailResend,
              newEmail: '',
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <div className={styles.confirmationContainer}>
                <div className={styles.confirmationContainer_options}>
                  <label htmlFor="confirmationEmail">
                    <Field
                      id="confirmationEmail"
                      name="confirmationMethod"
                      type="radio"
                      value="sendEmail"
                      required
                      className={styles.confirmationContainer_radio}
                    />
                    Reenviar código por mail
                  </label>
                  <label htmlFor="confirmationSms">
                    <Field
                      id="confirmationSms"
                      name="confirmationMethod"
                      type="radio"
                      value="sendSms"
                      required
                      className={styles.confirmationContainer_radio}
                    />
                    Reenviar código por SMS
                  </label>
                </div>
                {values.confirmationMethod === "sendSms" && (
                  <div className={styles.confirmationContainer_phoneContainer}>
                    <PhoneInput
                      id="phone"
                      defaultCountry="AR"
                      name="phone"
                      placeholder="Número de teléfono (SMS)"
                      value={values.phone}
                      onChange={(value) => setFieldValue('phone', value)}
                    />
                    {errors.phone && touched.phone && (
                      <div className="error">{errors.phone}</div>
                    )}
                    <button 
                      className={styles.emailInputContainer_btnResend}
                      disabled={values.phone === ''}
                      onClick={() => handleResend(values)}
                    >
                      Reenviar
                    </button>
                  </div>
                )}
                {values.confirmationMethod === "sendEmail" && (
                  <div className={styles.emailInputContainer}>
                    {itsMailOk ? (
                      <div className={styles.emailInputContainer_emailResend}>
                        <div className={styles.emailInputContainer_emailOk}>
                          {mailResend || "Correo no encontrado"}
                          <button
                            onClick={() => handleResend(values)}
                            className={styles.emailInputContainer_btnResend}
                          >
                            Reenviar
                          </button>
                        </div>
                        <p onClick={() => setItsMailOk(false)} className={styles.emailInputContainer_notMyMail}>¿No es tu correo?</p>
                      </div>
                    ) : (
                      <div className={styles.emailInputContainer_newEmailResend}>
                        <input
                          id="email"
                          name="newEmail"
                          type="email"
                          placeholder="Correo electrónico"
                          onChange={(e) => setFieldValue('newEmail', e.target.value)}
                          className={styles.emailInputContainer_emailInput}
                        />
                        <button
                          onClick={() => handleResend(values)}
                          className={styles.emailInputContainer_btnResend}
                          disabled={values.newEmail === ''}
                        >
                          Reenviar
                        </button>
                      </div>
                    )}
                  </div>
                )}
          </div>
            )}
        </Formik>
      )}
      </FormGroup >
      </>
    );
if (props.typeLogo === "welcome") {
  saludoType = (
    <>
      <h1 className={styles.title}>
        ¡Bienvenido a Edify! <br />
      </h1>
      <p className={styles.welcomeParagraph}>
        Ingresá el código de 6 dígitos que te enviamos para continuar.
      </p>
    </>
  );
} else {
  saludoType = (
    <MessageResponse type="error" title="Error de verificación!">
      El token ha expirado. Por favor, vuelve a enviar la verificación.
    </MessageResponse>
  );
}
  } else if (props.typeLogo === "login" || props.typeLogo === "resend") {
  interactionType = (
    <>
      <Button colored="white">Verificación enviada</Button>{" "}
      <FormGroup>
        <Button to="/" typeBtn="link">
          Volver al inicio
        </Button>
      </FormGroup>
    </>
  );
  saludoType = (
    <MessageResponse type="email" title="¡Correo enviado!">
      Tu correo de verificacion fue enviado nuevamente. Por favor revisa tu
      casilla para terminar el registro.
    </MessageResponse>
  );
} else if (props.typeLogo === "success") {
  interactionType = (
    <Button
      typeBtn="link"
      to="/"
      onClick={() =>
        fireAnalytics("confirmation_success_submit", {
          category: "authentication",
        })
      }
    >
      Inicia sesión
    </Button>
  );
  saludoType = (
    <MessageResponse type="success" title="¡Verificación exitosa!">
      Inicia sesión y comienza todos tus proyectos.
    </MessageResponse>
  );
}

return (
  <>
    <InitialWrapper>
      {codeValidation ? (<ValidateSuccess />) : (
        <>
          <div className={styles.pageContent}>{saludoType}</div>
          {isValidating ? <ValidationLoading /> : (
            <div>
              <CodeContainer>
                {[...Array(6)].map((_, index) => (
                  <CodeInput
                    key={index}
                    type="text"
                    value={code[index] || ''}
                    maxLength="1"
                    onPaste={handlePaste}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </CodeContainer>
              {interactionType}
            </div>
          )}
        </>
      )}
    </InitialWrapper>
  </>
);
};
const mapStateToProps = (state) => {
  return {
    name: state.register.name,
    mail: state.general.userData.username,
    accessName: state.general.accessData.name,
    codeValidation: state.register.codeValidation,
  };
};
export default connect(mapStateToProps, { 
  getProfileData,
  resendMailUserConfirmation,
  sendValidationCode,
})(WelcomeLayout); 
