import React, { useState } from "react";
import { ReactComponent as XIcon } from "../../../assets/svg/x.svg";
import styles from "./PostulationItem.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Maps from "../Maps/Maps";
import { ReactComponent as NoImage } from "../../../assets/svg/noImageIcon.svg";

const PostulationItem = ({ postulation, coverImage }) => {
    const [showPostulation, setShowPostulation] = useState(false);
    const [showItemInfo, setShowItemInfo] = useState(1);

   const navigate = useNavigate();

    const handleShowPostulation = () => {
        setShowPostulation(!showPostulation);
    }

    //Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate();
        return `${day}/${month}/${year}`;
    };

    const handleRedirectToProject = () => {
        navigate(`/proyectosprivados/${postulation.proyecto.id}`);
    };

    const translateStatusPostulation = (status) => {
        switch (status) {
            case 'SEND':
                return 'Pendiente';
            case 'ALLOWED':
                return 'Aceptada';
            case 'DENIED':
                return 'Rechazada';
            default:
                return '';
        }
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.container_image}>
                    <img className={styles.container_image_img} src={coverImage} alt="postulation" />
                </div>
                <div className={styles.container_info}>
                    <div className={styles.container_info_text} onClick={() => handleShowPostulation()}>
                        <p>{postulation?.proyecto?.name}</p>
                        <div className={styles.container_info_button} >
                            <ArrowRightIcon width={18} height={18} />
                        </div>
                    </div>
                </div>
                <div className={`${styles.container_postulationStatus} ${postulation?.status === "SEND" && styles.postulationSend} 
                    ${postulation?.status === "ALLOWED" && styles.postulationAllowed} ${postulation?.status === "NEGATED" && styles.postulationDenied}`}>
                    <p>{translateStatusPostulation(postulation?.status)}</p>
                </div>
            </div>
            {showPostulation && (
                <div className={styles.container_portfolio}>
                    <div className={styles.container_portfolio_container}>
                        <div className={styles.container_portfolio_headerContainer}>
                            <h2 className={styles.container_portfolio_info_title} onClick={() => handleRedirectToProject()}>{postulation?.proyecto?.name}</h2>

                            <div className={styles.container_portfolio_close} onClick={() => handleShowPostulation()}>
                                <XIcon width={18} height={18} />
                            </div>
                        </div>
                        <div className={styles.container_portfolio_container_body}>
                            <div className={styles.container_portfolio_container_content}>
                                <div className={styles.container_portfolio_info}>
                                    <div className={styles.container_portfolio_info_header}>
                                        <h3 className={styles.container_portfolio_info_subTitle}>INFORMACIÓN GENERAL:</h3>
                                    </div>
                                    <div className={styles.container_portfolio_info_headers}>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 1 && styles.isActive}`} onClick={() => setShowItemInfo(1)}>Imágenes</h4>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 2 && styles.isActive}`} onClick={() => setShowItemInfo(2)}>Descripción</h4>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 3 && styles.isActive}`} onClick={() => setShowItemInfo(3)}>Zona</h4>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 4 && styles.isActive}`} onClick={() => setShowItemInfo(4)}>Fechas</h4>
                                    </div>
                                    {showItemInfo === 1 && (
                                        <div className={styles.container_portfolio_images}>
                                            <div className={styles.container_portfolio_images_imagesContent}>
                                                {postulation?.proyecto?.proyecto_images?.length === 0 ? (
                                                    <div className={styles.container_portfolio_images_noImages}>
                                                        <NoImage width={100} height={100} />
                                                        No hay imágenes en el proyecto
                                                    </div>) : (
                                                    <ResponsiveMasonry
                                                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                                    >
                                                        <Masonry gutter="10px">
                                                            {postulation?.proyecto?.proyecto_images?.map((image, index) => (
                                                                <img className={styles.container_portfolio_images_item_img} key={index} src={image.image} alt="portfolio" />
                                                            ))}
                                                        </Masonry>
                                                    </ResponsiveMasonry>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {showItemInfo === 2 && (
                                        < div className={styles.container_portfolio_info_text}>
                                            <p className={styles.container_portfolio_info_subTitle_txt}>"{postulation?.proyecto?.description}"</p>
                                        </div>

                                    )}
                                    {showItemInfo === 3 && (
                                        <div className={styles.container_portfolio_info_text}>
                                            <div className={styles.container_portfolio_info_map}>
                                                <Maps ciudad={postulation?.proyecto?.zone} />
                                            </div>
                                        </div>

                                    )}
                                    {showItemInfo === 4 && (
                                        <div className={styles.container_portfolio_info_text}>
                                            <p className={styles.container_portfolio_info_subTitle_txt}>Inicio: {formatDate(postulation?.proyecto?.startDate)}</p>
                                            <p className={styles.container_portfolio_info_subTitle_txt}>Fin: {formatDate(postulation?.proyecto?.endDate)}</p>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default PostulationItem;
