import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./NewNav.module.scss";
import {
  loadUserData,
  logout,
  openProfileMenu,
  viewAccessRequest,
  activateFreeTrial,
  closeSuscriptionModal,
  setActivateFreeTrial,
  openSuscriptionModal,
  openMainModal,
  openFeedTextModal,
  resetProjectToEdit,
  userInfoNavStyle,
  setProjectFormStyle,
  navStyle,
  closeMainModal,
} from "../../store/actions";
import { ReactComponent as Negocios } from "../../assets/svg/negocios.svg";
import { ReactComponent as NegociosActive } from "../../assets/svg/negociosActive.svg";
import { ReactComponent as TrabajadoresIcon } from "../../assets/svg/trabajadoresIcon.svg";
import { ReactComponent as NotifActive } from "../../assets/svg/nav-notif-active.svg";
import { ReactComponent as MarketPlace } from "../../assets/svg/marketplace.svg";
import { ReactComponent as MarketPlaceActive } from "../../assets/svg/marketplaceActive.svg";
import { ReactComponent as Search } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as SearchActive } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as Proyectos } from "../../assets/svg/nav-proyectos.svg";
import { ReactComponent as ProyectosActive } from "../../assets/svg/nav-proyectos-active.svg";
import { ReactComponent as Postulaciones } from "../../assets/svg/nav-postulaciones.svg";
import { ReactComponent as PostulacionesActive } from "../../assets/svg/nav-postulaciones-active.svg";
import { ReactComponent as Faq } from "../../assets/svg/FAQ.svg";
import { ReactComponent as FaqActive } from "../../assets/svg/FAQActive.svg";
import { ReactComponent as CreateIcon } from "../../assets/svg/addPublicationIcon.svg";
import { ReactComponent as LogOutIcon } from "../../assets/svg/logOutIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/xClose.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/svg/arrowRightIcon.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/svg/arrowLeftIcon.svg";
import Tutorial from "../../components/Tutorial/Tutorial";
import { useNavigate, useLocation } from "react-router-dom";

const NewNav = ({
  userData,
  closeMainModal,
  logout,
  handleReducedNav,
  openMainModal,
  openSuscriptionModal,
  userInfoNavStyle,
  navStyle,
  viewAccessRequest,
}) => {
  const [isActive, setIsActive] = useState(null);
  const [changedLocation, setChangedLocation] = useState(false);
  const [isPartVisible, setIsPartVisible] = useState(true);
 const navigate = useNavigate();
 const location = useLocation();


  const completeName =
    userData.type === "COMPANY"
      ? userData.razon_social
      : `${userData.name} + " " + ${userData.last_name}`;

  const handleRedirectToProfile = () => {
    userInfoNavStyle(false)
    navigate(`/comunidad-edify/${userData.id}/${completeName}`);
  };

  const userName = userData?.type === "COMPANY" ? userData?.razon_social : userData?.name + " " + userData?.last_name;

  const handleTogglePartVisibility = () => {
    handleReducedNav();
    setIsPartVisible(!isPartVisible);
    navStyle(!isPartVisible);
  };

  useEffect(() => {
    const checkForUpdate = setInterval(() => viewAccessRequest(), 10000);
    return () => clearInterval(checkForUpdate);
  }, []);

  const handleRedirect = (title) => {
    setChangedLocation(!changedLocation);
    if (title === "Obras privadas") {
      navigate("/proyectosprivados");
    } else if (title === "Obras publicas") {
      navigate("/licitacionespublicas");
    } else if (title === "Home") {
      navigate("/dashboard");
    } else if (title === "cotizaciondemateriales") {
      navigate("/cotizaciondemateriales");
    } else if (title === "Profesionales") {
      navigate("/comunidad-edify");
    } else if (title === "notificaciones") {
      navigate("/solicitudes");
    } else if (title === "FAQ") {
      navigate("/faq");
    } else if (title === "Mercado de trabajo") {
      navigate("/mercadodetrabajo");
    }
  };

  useEffect(() => {
    if (location?.pathname === "/dashboard") {
      setIsActive("Home");
    }
    if (location?.pathname === "/proyectosprivados") {
      setIsActive("Obras privadas");
    }
    if (location?.pathname === "/licitacionespublicas") {
      setIsActive("Obras publicas");
    }
    if (location?.pathname === "/cotizaciondemateriales") {
      setIsActive("cotizaciondemateriales");
    }
    if (location?.pathname.startsWith("/comunidad-edify")) {
        const pathParts = location.pathname.split("/");
        const idInPath = pathParts[2]; // Extract the ID part from the pathname
        if (idInPath === userData.id) {
            setIsActive("Perfil");
        } else {
            setIsActive("Profesionales");
        }
    }
    if (location?.pathname === "/segurosparatrabajadoresyequipos") {
      setIsActive("Seguros");
    }
    if (location?.pathname === "/alquilerdemaquinarias") {
      setIsActive("Alquiler de maquinarias");
    }
    if (location?.pathname === "/mercadodetrabajo") {
      setIsActive("Mercado de trabajo");
    }
    if (location?.pathname === "/solicitudes") {
      setIsActive("notificaciones");
    }
    if (location?.pathname === "/faq") {
      setIsActive("FAQ");
    }
}, [changedLocation, location?.pathname, userData.id]);

 
  const handleCloseSesion = () => {
    openMainModal({
      title: "¿Deseas cerrar la sesión activa?",
      body: "Dejaran de llegarte las notificaciones de actividad.",
      isSuccess: false,
      confirmHandler: () => {logout(true, navigate); closeMainModal()},
    });
  };

  return (
    <div className={`${styles.generalContainer}  ${isPartVisible ? "" : styles.containerReduced}`}>
      <div className={`${styles.nav_toggle}`} onClick={handleTogglePartVisibility}>
        {!isPartVisible ? (<ArrowRightIcon width={15} height={15} />) : (<ArrowLeftIcon width={15} height={15} />)}
      </div>
      <div className={`${styles.container} ${isPartVisible ? "" : styles.containerReduced}`}>
        <div className={styles.nav_userDataContainer}>
          <div className={`${styles.nav_imgContainer} ${isActive === "Perfil" && styles.isActive}`} onClick={() => handleRedirectToProfile()}>
            <img src={userData.image_profile} alt="profileImg" className={styles.nav_imgContainer_img} />
            <div className={styles.nav_userData}>           
              <p className={styles.nav_userData_name}>{userName}</p>             
              <span className={styles.nav_userData_status}>Online
              <div className={styles.nav_userData_status_circle}></div>
               </span>
            </div>
          </div>
        </div>
        <div className={styles.nav}>
          <ul className={styles.nav_list}>
            <li
              className={`${styles.nav_navItem} ${isActive === "Home" ? styles.activeHome : ""
                }`}
              onClick={() => handleRedirect("Home")}
            >
              <div className={styles.nav_navItemIcon}>
                {isActive === "Home" ? (
                  <ProyectosActive width={20} height={20} />
                ) : (
                  <Proyectos width={20} height={20} />
                )}
              </div>
              <div className={`${styles.nav_navItemText} ${!isPartVisible ? styles.hideText : ""}`}>Muro</div>
            </li>
            <li
              className={`${styles.nav_navItem} ${isActive === "Obras privadas" ? styles.activePrivate : ""
                }`}
              onClick={() => handleRedirect("Obras privadas")}
            >
              <div
                className={`${isActive === "Obras privadas"
                  ? styles.nav_navItemIcon
                  : styles.nav_navItemIconComunidad
                  }`}
              >
                {isActive === "Obras privadas" ? (
                  <SearchActive width={20} height={20} />
                ) : (
                  <Search width={20} height={20} />
                )}
              </div>
              <div className={`${styles.nav_navItemText} ${!isPartVisible ? styles.hideText : ""}`}>Obras privadas</div>
            </li>
            <li
              className={`${styles.nav_navItem} ${isActive === "Obras publicas" ? styles.activePublics : ""
                }`}
              onClick={() => handleRedirect("Obras publicas")}
            >
              <div className={styles.nav_navItemIcon}>
                {isActive === "Obras publicas" ? (
                  <PostulacionesActive width={20} height={20} />
                ) : (
                  <Postulaciones width={20} height={20} />
                )}
              </div>
              <div className={`${styles.nav_navItemText} ${!isPartVisible ? styles.hideText : ""}`}>Obras públicas</div>
            </li>
            <li
              className={`${styles.nav_navItem} ${isActive === "cotizaciondemateriales" ? styles.activeQuotation : ""
                }`}
              onClick={() => handleRedirect("cotizaciondemateriales")}
            >
              <div
                className={`${isActive === "cotizaciondemateriales"
                  ? styles.nav_navItemIcon
                  : styles.nav_navItemIconComunidad
                  }`}
              >
                {isActive === "cotizaciondemateriales" ? (
                  <MarketPlaceActive width={20} height={20} />
                ) : (
                  <MarketPlace width={20} height={20} />
                )}
              </div>
              <div className={`${styles.nav_navItemText} ${!isPartVisible ? styles.hideText : ""}`}>
                Cotización de materiales
              </div>
            </li>
            <li
              className={`${styles.nav_navItem} ${isActive === "Profesionales" ? styles.activeComunidad : ""
                }`}
              onClick={() => handleRedirect("Profesionales")}
            >
              <div
                className={`${isActive === "Profesionales"
                  ? styles.nav_navItemIcon
                  : styles.nav_navItemIconComunidad
                  }`}
              >
                {isActive === "Profesionales" ? (
                  <NegociosActive width={20} height={20} />
                ) : (
                  <Negocios width={20} height={20} />
                )}
              </div>
              <div className={`${styles.nav_navItemText} ${!isPartVisible ? styles.hideText : ""}`}>Profesionales</div>
            </li>
            <li className={`${styles.nav_navItem} ${isActive === "Mercado de trabajo" ? styles.activeWorkers : ""
              }`} onClick={() => handleRedirect("Mercado de trabajo")}>
              <div className={`${isActive === "Mercado de trabajo" ? styles.nav_navItemIconWorkers : styles.nav_navItemIcon}`}>
                {isActive === "Mercado de trabajo" ? (
                  <TrabajadoresIcon width={20} height={20} />
                ) : (
                  <TrabajadoresIcon width={20} height={20} />
                )}
              </div>
              <div className={`${styles.nav_navItemText} ${!isPartVisible ? styles.hideText : ""}`}>Mercado de trabajo</div>
            </li>
            <li
              className={styles.nav_navItem_lastItem}
              onClick={() => handleCloseSesion()}
            >
              <div className={styles.nav_navItemIcon}>
                <LogOutIcon width={20} height={20} />
              </div>
              <div className={`${styles.nav_navItemText_logout}  ${!isPartVisible ? styles.hideText : ""}`}>Cerrar sesión</div>
            </li>
          </ul>
        </div>
        <Tutorial />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    isLoaded: state.general.isLoaded,
    progressBarStatus: state.general.progressBarStatus,
    profileMenu: state.profile.menuShow,
    solicitudesList: state.dashboard.solicitudesList,
    isFreeTrialAvailable: state.general.isFreeTrialAvailable,
    isFreeTrialSuccess: state.profile.isFreeTrialSuccess,
    isUserPremium: state.general.isUserPremium,
    newNotifications: state.dashboard.newNotifications,
  };
};

export default connect(mapStateToProps, {
  loadUserData,
  logout,
  openProfileMenu,
  activateFreeTrial,
  closeSuscriptionModal,
  openMainModal,
  setActivateFreeTrial,
  openFeedTextModal,
  openSuscriptionModal,
  resetProjectToEdit,
  setProjectFormStyle,
  viewAccessRequest,
  navStyle,
  userInfoNavStyle,
  closeMainModal,
})(NewNav);
