import React from "react";
import ProjectsItem from "../project/ProjectsItem";

const LicitacionesItem = ({ item }) => {
  const nombre = item.name?.replace(/ /g, "-");

  const handleOnClick = () => {
    window.open(`/obra-publica-a-licitar/${item.id}/${nombre}`, "_blank");
  };

  return <ProjectsItem handleOnClick={handleOnClick} item={item} />;
};

export default LicitacionesItem;
