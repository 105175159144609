import { connect } from "react-redux";
import { useNavigate } from "react-router";
import React from "react";
import styles from "./QuotationItem.module.scss";
import { openMainModal, closeMainModal, deleteQuotation, updateQuotation } from "../../store/actions";
import BuenosAiresIcono from "../../assets/images/BuenosAiresIcono.jpg";
import CabaIcono from "../../assets/images/cabaIcon.jpg";
import CatamarcaIcono from "../../assets/images/catamarcaIcono.jpg";
import ChacoIcono from "../../assets/images/chacoIcono.jpg";
import ChubutIcono from "../../assets/images/chubutIcono.jpg";
import CordobaIcono from "../../assets/images/cordobaIcono.jpg";
import CorrientesIcono from "../../assets/images/corrientesIcono.jpg";
import EntreRiosIcono from "../../assets/images/entreRiosIcono.jpg";
import FormosaIcono from "../../assets/images/formosaIcono.jpg";
import JujuyIcono from "../../assets/images/jujuyIcono.jpg";
import LaPampaIcono from "../../assets/images/laPampaIcono.jpg";
import LaRiojaIcono from "../../assets/images/laRiojaIcono.jpg";
import MendozaIcono from "../../assets/images/mendozaIcono.jpg";
import MisionesIcono from "../../assets/images/misionesIcono.jpg";
import NeuquenIcono from "../../assets/images/neuquenIcono.jpg";
import RioNegroIcono from "../../assets/images/rioNegroIcono.jpg";
import SaltaIcono from "../../assets/images/saltaIcono.jpg";
import SanJuanIcono from "../../assets/images/sanJuanIcono.jpg";
import SanLuisIcono from "../../assets/images/sanLuisIcono.jpg";
import SantaCruzIcono from "../../assets/images/santaCruzIcono.jpg";
import SantaFeIcono from "../../assets/images/santaFeIcono.jpg";
import SantiagoDelEsteroIcono from "../../assets/images/santiagoDelEsteroIcono.jpg";
import TierraDelFuegoIcono from "../../assets/images/tierraDelFuegoIcono.jpg";
import TucumanIcono from "../../assets/images/tucumanIcono.jpg";
import ArgentinaIcono from "../../assets/images/argentinaIcon.jpg";

const QuotationItem = ({
  openMainModal,
  closeMainModal,
  deleteQuotation,
  userData,
  updateQuotation,
  ...props }) => {
  const { item } = props;
 const navigate = useNavigate();

  //Array de iconos
  const iconJPG = [
    { id: 1, name: "Ciudad Autónoma de Buenos Aires", icon: CabaIcono },
    { id: 2, name: "Buenos Aires", icon: BuenosAiresIcono },
    { id: 3, name: "Catamarca", icon: CatamarcaIcono },
    { id: 4, name: "Chaco", icon: ChacoIcono },
    { id: 5, name: "Chubut", icon: ChubutIcono },
    { id: 6, name: "Córdoba", icon: CordobaIcono },
    { id: 7, name: "Corrientes", icon: CorrientesIcono },
    { id: 8, name: "Entre Ríos", icon: EntreRiosIcono },
    { id: 9, name: "Formosa", icon: FormosaIcono },
    { id: 10, name: "Jujuy", icon: JujuyIcono },
    { id: 11, name: "La Pampa", icon: LaPampaIcono },
    { id: 12, name: "La Rioja", icon: LaRiojaIcono },
    { id: 13, name: "Mendoza", icon: MendozaIcono },
    { id: 14, name: "Misiones", icon: MisionesIcono },
    { id: 15, name: "Neuquén", icon: NeuquenIcono },
    { id: 16, name: "Río Negro", icon: RioNegroIcono },
    { id: 17, name: "Salta", icon: SaltaIcono },
    { id: 18, name: "San Juan", icon: SanJuanIcono },
    { id: 19, name: "San Luis", icon: SanLuisIcono },
    { id: 20, name: "Santa Cruz", icon: SantaCruzIcono },
    { id: 21, name: "Santa Fe", icon: SantaFeIcono },
    { id: 22, name: "Santiago del Estero", icon: SantiagoDelEsteroIcono },
    { id: 23, name: "Tierra del Fuego,Antártida e Isla del Atlántico Sur", icon: TierraDelFuegoIcono },
    { id: 24, name: "Tucumán", icon: TucumanIcono },
    { id: 25, name: "Argentina", icon: ArgentinaIcono }
  ]

  //Formateamos las fechas 
  const formattedStartDate = item.startDate
    ? (() => {
      const dateObj = new Date(item.startDate);
      dateObj.setUTCHours(dateObj.getUTCHours());
      const day = dateObj.getUTCDate();
      const month = dateObj.getUTCMonth() + 1;
      const year = dateObj.getUTCFullYear();
      return `${day}/${month}/${year}`;
    })()
    : null;

  const formattedEndDate = item.endDate
    ? (() => {
      const dateObj = new Date(item.endDate);
      dateObj.setUTCHours(dateObj.getUTCHours());
      const day = dateObj.getUTCDate();
      const month = dateObj.getUTCMonth() + 1;
      const year = dateObj.getUTCFullYear();
      return `${day}/${month}/${year}`;
    })()
    : null;


  //Maneja la redireccion a la cotizacion
  const handleRedirectToQuotation = () => {
    navigate(`/cotizaciondemateriales/${item?.id}`);
  }

  return (
    <div className={styles.fullCardContainer}>
      <ul className={styles.cardContainerList}>
        <li className={styles.cardContainerList_item}>
          <div className={styles.cardContainerList_iconContainer}>
            <p>
              {item.jurisdiccion === null || !item.jurisdiccion ? "No especificado" : (
                item.jurisdiccion?.name.charAt(0).toUpperCase() + item.jurisdiccion?.name.slice(1))}
            </p>
            {iconJPG.filter((icon) => icon.name === item.jurisdiccion?.name).map((icon, index) => {
              return (
                <img src={icon.icon
                } alt="icono" className={styles.cardContainerList_iconContainer_img} key={index}/>
              );
            })}
            {(item.jurisdiccion === null || item?.jurisdiccion === undefined) && (
              <img src={ArgentinaIcono} alt="icono" className={styles.cardContainerList_iconContainer_img} />
            )}
          </div>
        </li>
        <li className={styles.cardContainerList_item}>
            <p className={styles.name_campo}>
              {item?.name?.charAt(0).toUpperCase() + item?.name?.slice(1)}
            </p>
        </li>
        <li className={styles.cardContainerList_item}>
          {Array.isArray(item?.subquotation) && item?.subquotation?.length > 0 ? (
            <div className={styles.rubros_subQuotation}>
              {item?.subquotation?.map((subQuotation, index) => {
                return (
                  <div className={styles.rubros_subQuotation_container} key={index}>
                    <p className={styles.rubros_subQuotation_title}>{subQuotation?.name}</p>
                  </div>
                )
              })}
            </div>
          ) : null}
        </li>
        <li className={styles.cardContainerList_item}>
            <p className={styles.container_tableDate_startDate_date}>
              {formattedStartDate}
            </p>
        </li>
        <li className={styles.cardContainerList_item}>         
            <p className={styles.container_tableDate_endDate_date}>
              {formattedEndDate}
            </p>
        </li>
        <li className={styles.cardContainerList_item}>      
            <p className={styles.container_input_lugar}>
              {item?.zone ? item.zone : "No especificado"}
            </p>
        </li>
        <li className={styles.cardContainerList_item}>
          <p className={styles.container_input_lugar}>
            {item?.currency ? item.currency : "$"}
          </p>
        </li>
        <li className={styles.cardContainerList_item}>
            <span className={styles.buttonText} onClick={() => handleRedirectToQuotation()}>
              <div className={styles.fullQuotation}  >
                    Ver más
                  </div>
            </span>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileMenu: state.profile.menuShow,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, {
  openMainModal,
  closeMainModal,
  updateQuotation,
  deleteQuotation,
})(QuotationItem);