import React, { useState } from "react";
import styles from "./FreeTrialModal.module.scss";
import alertWarning from "../../../assets/svg/modalAtencion.svg"
import successIcon from "../../../assets/svg/success.svg"
import { activateFreeTrial, closeSuscriptionModal, setActivateFreeTrial } from "../../../store/actions/profileActions";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { loadUserData } from "../../../store/actions";
import { uniqueId } from "lodash";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    // y: "-100vh",
    // x: "-50%",
    opacity: 0,
  },
  visible: {
    // y: "-50%",
    // x: "-50%",
    opacity: 1,
    transition: { delay: 0.2 },
  },
};

const newUserContent = (
  <>
    <p className={styles.trialHeader}>Comienza tus 60 dias de prueba gratis y accede los siguientes beneficios:</p>
    <ul className={styles.trialItems}>
      <li>Podes publicar tus productos/servicios en la página principal</li>
      <li>Crear proyectos de Obra Privada y obtener cotizaciones</li>
      <li>Postularte en las publicaciónes de otros usuarios y enviar presupuestos</li>
      <li>Acceder a toda la información en Obras Públicas</li>
      <li>Crear y cotizar solicitudes de Listado de Materiales</li>
      <li>Dar y recibir calificaciónes a todos los usuarios</li>
      <li>Atención personalizada para consultas</li>
      <li>Mailing de difusión a base de datos (Una vez al més)</li>
    </ul>
    <p className={styles.trialTarjeta}><span className={styles.important}>¡Importante!</span>NO SE NECESITA TARJETA DE CRÉDITO</p>
    <p className={styles.trialAclaracion}>Una vez finalizado el período de prueba el plan se cancela automáticamente
      vas a poder ingresar y chequear nuevas funcionalidades pero no vas a poder
      interactuar con los demás usuarios.</p>
  </>
)

const earlyAdopterContent = (
  <>
    <p className={styles.trialHeader}>Muchas gracias por ser uno de los usuarios iniciales de Edify.</p>
    <p className={styles.trialHeader}>Lamentablemente el tiempo de uso libre e irrestricto ha terminado. Debes suscribirte para acceder al contenido. Obtienes los siguientes beneficios:</p>
    <ul className={styles.trialItems}>
      <li>Podes publicar tus productos/servicios en la página principal</li>
      <li>Crear proyectos de Obra Privada y obtener cotizaciones</li>
      <li>Postularte en las publicaciónes de otros usuarios y enviar presupuestos</li>
      <li>Acceder a toda la información en Obras Públicas</li>
      <li>Crear y cotizar solicitudes de Listado de Materiales</li>
      <li>Dar y recibir calificaciónes a todos los usuarios</li>
      <li>Atención personalizada para consultas</li>
      <li>Mailing de difusión a base de datos (Una vez al més)</li>
    </ul>
    <p>Ademas por ser un usuario inicial tienes el beneficio de <span style={{ fontWeight: "bold" }}>90 dias gratis</span> por unica vez.</p>
    <p className={styles.trialTarjeta}><span className={styles.important}>¡Importante!</span>NO SE NECESITA TARJETA DE CRÉDITO</p>
    <p className={styles.trialAclaracion}>Una vez finalizado el período de prueba el plan se cancela automáticamente
      vas a poder ingresar y chequear nuevas funcionalidades pero no vas a poder
      interactuar con los demás usuarios.</p>

  </>
)

const FreeTrialModal = ({
   closeSuscriptionModal, 
   activateFreeTrial, 
   userData, 
   loadUserData, 
   setActivateFreeTrial, 
   isFreeTrialSuccess, 
   ...props
   }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDashboard, setisDashboard] = useState(location.pathname == "/dashboard")


  const onStartFreeTrial = () => {
    setActivateFreeTrial(true);
    activateFreeTrial();
  }

  const closeModal = () => {
    if (!isDashboard) {
      navigate("/dashboard");
    }
    if (isFreeTrialSuccess) {
      loadUserData()
    }
    if (props.isFromNav) {
      activateFreeTrial();
      loadUserData();
    }
    setActivateFreeTrial(false)
    closeSuscriptionModal()

  }
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          variants={backdrop}
          initial='hidden'
          animate='visible'
          exit='hidden'
          className={styles.SuscriptionModal_overlay}
          onClick={closeModal}
          key={uniqueId()}
        ></motion.div>
        <motion.div
          variants={modal}
          initial='hidden'
          animate='visible'
          exit='hidden'
          className={styles.SuscriptionModal}
        >
          {
            isFreeTrialSuccess ? (
              <>
                <div className={styles.SuscriptionModal_ImageContainer}>
                </div>
                <div className={styles.SuscriptionModal_FreeTrial}>
                  <div className={styles.SuscriptionModal_title}>
                    <img src={successIcon} className={styles.SuscriptionModal_titlealertWarning} alt={"succesIconImg"} />
                  </div>
                  {
                    userData.free_trial.early_adopter ? (<h3>90 dias activados</h3>) : (<h3>60 dias activados</h3>)
                  }
                  <p className={styles.trialHeader}>Obtienes los siguientes beneficios</p>
                  <ul className={styles.trialItems}>
                    <li>Crea proyectos de Obra Privada y obten cotizaciones</li>
                    <li>Postulate en las publicaciónes de otros usuarios y cotiza</li>
                    <li>Accedé a toda la información en Obras Públicas</li>
                    <li>Crea y cotiza solicitudes de Listado de Materiales</li>
                    <li>Publica tu perfil en la sección Usuarios Registrados</li>
                    <li>Dar y recibir calificaciónes a todos los usuarios</li>
                    <li>Atención personalizada para consultas</li>
                    <li>Mailing de difusión a Base de Datos (Una vez al mes)</li>
                  </ul>
                  <p className={styles.trialTarjeta}><span className={styles.important}>¡Importante!</span>NO SE NECESITA TARJETA DE CRÉDITO</p>
                  <p className={styles.trialAclaracion}>Una vez finalizado el período de prueba el plan se cancela automáticamente
                    vas a poder ingresar y chequear nuevas funcionalidades pero no vas a poder
                    interactuar con los demás usuarios.</p>
                  <div
                    className={styles.SuscriptionModal_PlanContaineritem_success}
                    onClick={closeModal}
                  >
                    ¡Bienvenido a edify Premium!
                  </div>
                </div>
              </>
            ) : (
              <>
          <div className={styles.SuscriptionModal_ImageContainer}>
          </div>
          <div className={styles.SuscriptionModal_FreeTrial}>
            <div className={styles.SuscriptionModal_title}>
              <img src={alertWarning} className={styles.SuscriptionModal_titlealertWarning} />
            </div>
            <h3>Contenido Premium</h3>
            {
              userData.free_trial.early_adopter ? earlyAdopterContent : newUserContent
            }
            <button
              className={styles.SuscriptionModal_PlanContaineritem_success}
              onClick={onStartFreeTrial}

            >
              Comenzar Free Trial
            </button>
            <div
              className={styles.SuscriptionModal_PlanContaineritem_decline}
              onClick={closeModal}
            >
              {
                isDashboard ? "Volver" : "Volver al dashboard"
              }
            </div>
          </div>
            </>
            )
          }
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userData: state.general.userData,
    isFreeTrialSuccess: state.profile.isFreeTrialSuccess,
  };
};

export default connect(mapStateToProps, { closeSuscriptionModal, activateFreeTrial, loadUserData, setActivateFreeTrial })(FreeTrialModal);
