import React, { useState, useEffect } from "react";
import { setNewPasswordRecovery, setSuccessFalse } from "../../store/actions";
import * as Yup from "yup";
import styled from "styled-components";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import viewPasswordIcon from "../../assets/svg/viewpasswordsprit.svg";
import Logo from "../../assets/images/logo.png";
import Input from "../../components/UI/Forms/Input/Input";
import Button from "../../components/UI/Forms/Button/Button";
import { fireAnalytics }  from '../../utils/firebase.js';
import styles from "./RecuperarPassword.module.scss";
import MessageResponse from "../../components/UI/MessageResponse/MessageResponse";
import { useLocation } from "react-router-dom"

//REact-reveal
const RecuperarPassword = (props) => {
  const [showHidePassword, changeShowHidePassword] = useState(true);
  const [showHideRepeatPassword, changeShowHideRepeatPassword] = useState(true);
  const location = useLocation();
  const actualYear = new Date().getFullYear();

  const recoverSchema = Yup.object().shape({
    password: Yup.string()
      .required("El campo contraseña es requerido")
      .min(6, "Debe contener al menos 6 caracteres."),

    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], `La contraseña no coincide.`)
      .required("Por favor confirma la contraseña."),
  });
  const FormGroup = styled.div`
    margin: 15px 0;
    position: relative;
    width: 100%;
  `;
  let navigate = props.navigate;
  const token = location.pathname.slice(10);

  useEffect(() => {
    props.setSuccessFalse();
    fireAnalytics('discard_draft', {category: 'publishing'})
  }, []);
  return (
    <section className={styles.sectionContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div>
            <div className={styles.registerContainer}>
              <div className={styles.registerContent}>
                <div className={styles.logo}>
                  <img src={Logo} alt="edify" />
                </div>
                {props.success ? (
                  <>
                    <MessageResponse type="success" title="¡Listo!">
                      Tu contraseña se ha cambiado correctamente
                    </MessageResponse>
                    <FormGroup>
                      <div onClick={() => props.setSuccessFalse()}>
                        <Button to="/" typeBtn="link">
                          Volver al inicio
                        </Button>
                      </div>
                    </FormGroup>
                  </>
                ) : (
                  <>
                    {" "}
                    <h3 className={styles.formTitle}>
                      ¿Olvidaste tu contraseña?
                      <br />
                      Creá una nueva
                    </h3>
                    <p className={styles.formSubtitle}>
                      Ingresá una contraseña nueva para tu cuenta. Luego
                      presioná Cambiar contraseña
                    </p>
                    <Formik
                      initialValues={{
                        password: "",
                        repeatPassword: "",
                      }}
                      validationSchema={recoverSchema}
                      onSubmit={(values) => {
                        props.setNewPasswordRecovery({ values, token, navigate });
                        fireAnalytics('change_password', { category: 'authentication' });
                      }}>
                      {({ handleSubmit, isValid, values, errors }) => (
                        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                          <FormGroup className={styles.logos}>
                            {values.password !== "" ? (
                              <span
                                className={`${styles.viewPassword} ${
                                  errors.password
                                    ? styles.viewPasswordError
                                    : null
                                }`}
                                onClick={() =>
                                  changeShowHidePassword(!showHidePassword)
                                }>
                                <svg class="icon">
                                  <use
                                    href={
                                      viewPasswordIcon + "#viewpassword"
                                    }></use>
                                </svg>
                              </span>
                            ) : null}
                            <Field
                              type={showHidePassword ? "password" : "text"}
                              name="password"
                              className={`${styles.formInput} ${
                                errors.password ? "error" : null
                              }`}
                              placeholder={`${
                                errors.password
                                  ? errors.password
                                  : "Nueva contraseña"
                              }`}
                              component={Input}
                            />
                          </FormGroup>
                          <FormGroup className={styles.logos}>
                            {values.repeatPassword !== "" ? (
                              <span
                                className={`${styles.viewPassword} ${
                                  errors.repeatPassword
                                    ? styles.viewPasswordError
                                    : null
                                }`}
                                onClick={() =>
                                  changeShowHideRepeatPassword(
                                    !showHideRepeatPassword
                                  )
                                }>
                                <svg class="icon">
                                  <use
                                    href={
                                      viewPasswordIcon + "#viewpassword"
                                    }></use>
                                </svg>
                              </span>
                            ) : null}
                            <Field
                              type={
                                showHideRepeatPassword ? "password" : "text"
                              }
                              name="repeatPassword"
                              className={`${styles.formInput} ${
                                errors.repeatPassword ? "error" : null
                              }`}
                              placeholder={`${
                                errors.repeatPassword
                                  ? errors.repeatPassword
                                  : "Repetir contraseña"
                              }`}
                              component={Input}
                            />
                          </FormGroup>

                          <FormGroup>
                            <button
                              className={`${styles.btn} ${
                                errors.password || errors.repeatPassword
                                  ? styles.error
                                  : null
                              }`}
                              type="submit">
                              Cambiar contraseña
                            </button>
                          </FormGroup>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
              </div>
              <div className={styles.copyright}>
                Copyright © {actualYear} Edify, All rights reserved. Buenos Aires,
                Argentina.
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.content} ${styles.background}`}></div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.general.success,
  };
};

export default connect(mapStateToProps, {
  setNewPasswordRecovery,
  setSuccessFalse,
})(RecuperarPassword);
