import React, { useState, useEffect, useRef } from "react";
import { getSubprojectsType, getSkills, createNewSkill, resetNewSkill, getAllUsersBySkills } from "../../../../store/actions";
import { fireAnalytics } from "../../../../utils/firebase";
import { connect } from "react-redux";
import styles from "./RubroInputSelect.module.scss";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrowDownIcon.svg";



const RubroInputSelect = ({
    clear,
    rubros,
    getSubprojectsType,
    createNewSkill,
    getSkills,
    getAllUsersBySkills,
    resetNewSkill,
    selectedRubro,
    setSelectedRubro,
    rubrosConUsuarios,
    newSkill,
    setShowSubProjectDocus,
    ...props }) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [showCompleteList, setShowCompleteList] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        getSkills();
        resetNewSkill();
        getAllUsersBySkills();
    }, []);

    const handleRubroSelect = (value, name) => {
        props.setFieldValue(props.inputName, value);
        setShowSuggestions(false);
        setSelectedRubro(value.split("*")[1]);
        setInputValue("");
        setShowCompleteList(false);
    };

    const handleShowSuggestions = (value) => {
        const regex = /^[a-zA-Z\s]+$/
        setIsValid(regex.test(value));
        if (value.length < 3) {
            setShowSuggestions(false);
        } else if (value.length >= 3) {
            setShowSuggestions(true);
        }
        setInputValue(value);
    };

    const filteredSuggestions = rubrosConUsuarios.filter((rubro) => {
        return rubro.name.toLowerCase().includes(inputValue.toLowerCase());
    }
    );

    const handleCreateRubro = async () => {

        const normalizeString = (str) => {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        };
    
        const normalizedInputValue = normalizeString(inputValue);

        const matchedRubro = rubros.find((rubro) => normalizeString(rubro.name) === normalizedInputValue);
    
        if (inputValue.length >= 3 && !matchedRubro) {
            try {
             await createNewSkill(inputValue, true); 
            } catch (error) {
                console.error("Error creando el nuevo skill:", error);
            }
        } else if (inputValue.length >= 3 && matchedRubro) {
            props.setFieldValue(props.inputName, `${matchedRubro.id}*${matchedRubro.name}`);
            setShowSuggestions(false);
            setSelectedRubro(inputValue);
            setInputValue("");
        }
    };

    useEffect(() => {
        if (newSkill) {
            props.setFieldValue(props.inputName, `${newSkill.id}*${newSkill.name}`);
            setShowSuggestions(false);
            setSelectedRubro(newSkill.name);
            setInputValue("");
        }
    }, [newSkill]);

    const handleCancel = () => {
        setSelectedRubro("");
        props.setFieldValue(props.inputName, "");
        setShowSubProjectDocus(false);
        resetNewSkill();
    };

     useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }
  , []);

    return (
        <div className={styles.rubroInputSelect}>
            {selectedRubro !== "" ? (
                <div className={styles.rubroInputSelect_inputRubro}>
                    <div className={styles.rubroInputSelect_inputRubro_label}>
                        {selectedRubro}
                        <button
                            type="button"
                            className={styles.rubroInputSelect_inputRubro_label_btn}
                            onClick={() => handleCancel()}
                            title="Eliminar rubro"
                        >
                            x
                        </button>
                    </div>
                </div>
            ) : (
                <div className={styles.rubroInputSelect_inputRubroContainer}>
                    <input
                        type="text"
                        name={props.inputName}
                        className={styles.rubroInputSelect_inputRubro}
                        value={selectedRubro === "" ? inputValue : selectedRubro}
                        onChange={(e) => handleShowSuggestions(e.target.value)}
                        placeholder={"Ingrese al menos 3 caracteres"}
                    />

                    <ArrowIcon
                        width={15}
                        title="Ver lista completa de rubros"
                        height={15}
                        onClick={() => setShowCompleteList(!showCompleteList)}
                        className={`${styles.arrow} ${showCompleteList ? styles.arrowIconUp : styles.arrowIconDown}`} />
                </div>
            )}
            {showCompleteList && (
                <div className={styles.rubroInputSelect_suggestions}>
                    {rubrosConUsuarios.map((rubro, index) => (
                        <div className={styles.rubroInputSelect_suggestions_item} key={index} onClick={() => handleRubroSelect(`${rubro.id}*${rubro.name}`, props.inputName)}>
                            {rubro.name}
                            <p className={styles.rubroInputSelect_suggestions_item_count}>&nbsp;- hay {rubro.users} {rubro.users === 1 ? "profesional" : "profesionales"}  en edify</p>
                        </div>
                    ))}
                </div>
            )}
            {showSuggestions && (
                <div className={styles.rubroInputSelect_suggestions}>
                    {!isValid && <div className={styles.rubroInputSelect_suggestions_item} ref={inputRef}>Ingrese solo letras</div>
                    }
                    {filteredSuggestions.length === 0 && isValid ?
                        <div className={styles.rubroInputSelect_suggestions_item} ref={inputRef}>No se encontraron profesionales con ese rubro. ¿Desea crearlo? El equipo de edify se encargará de ayudarte con la búsqueda del profesional indicado.</div>
                        : filteredSuggestions.map((rubro, index) => (
                            <div className={styles.rubroInputSelect_suggestions_item} key={index} onClick={() => handleRubroSelect(`${rubro.id}*${rubro.name}`, props.inputName)}>
                                {rubro.name}
                                <p className={styles.rubroInputSelect_suggestions_item_count}>&nbsp;- hay {rubro.users} {rubro.users === 1 ? "profesional" : "profesionales"}  en edify</p>
                            </div>
                        ))}
                </div>
            )}
            {selectedRubro === "" && filteredSuggestions?.length === 0 && inputValue.length >= 3 && isValid && (
                <div className={styles.rubroInputSelect_buttonsContainer} onClick={handleCreateRubro}>
                    <button
                        type="button"
                        className={styles.rubroInputSelect_buttonsContainer_btn}                   
                    >
                        Crear
                    </button>
                </div>
            )}
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        rubros: state.register.skills,
        rubrosConUsuarios: state.register.skillsList,
        newSkill: state.profile.newSkill,
    };
};

export default connect(mapStateToProps, { getSkills, createNewSkill, resetNewSkill, getAllUsersBySkills })(RubroInputSelect);

