/* login */
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_END = "LOGIN_END";
export const LOGOUT = "LOGOUT";
export const LOGIN_TYPE = "LOGIN_TYPE";
export const SET_LOGIN_ANIMATION = "SET_LOGIN_ANIMATION";

/* mail recovery*/
export const RECOVERY_START = "RECOVERY_START";
export const RECOVERY_SUCCESS = "RECOVERY_SUCCESS";
export const RECOVERY_FAIL = "RECOVERY_FAIL";
export const SET_SUCCESS_FALSE = "SET_SUCCESS_FALSE";

/* Register */

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const VALIDATION_SUCCESS = "VALIDATION_SUCCESS";

/* Bolsa de Trabajo */
export const GET_CITIES = "GET_CITIES";

/*Set new password recovery */
export const SET_NEW_RECOVERY_PASSWORD = "SET_NEW_RECOVERY_PASSWORD";

/* Login Token */
export const SET_ACCESS = "SET_ACCESS";

/*Loading */
export const SET_LOADING = "SET_LOADING";

/* User Data */
export const GET_DATA = "GET_DATA";
export const SET_PERSONA_DATA = "SET_PERSONA_DATA";
export const SET_PERSONA_FORM = "SET_PERSONA_FORM";
export const SET_EMPRESA_DATA = "SET_EMPRESA_DATA";
export const SET_EMPRESA_FORM = "SET_EMPRESA_FORM";
export const CLEAR_ONBOARDING_FORM = "CLEAR_ONBOARDING_FORM";

/**MODAL OPEN */

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

/* Profile Modal Open */

export const OPEN_PORTFOLIO_ADD = "OPEN_PORTFOLIO_ADD";
export const CLOSE_PORTFOLIO_ADD = "CLOSE_PORTFOLIO_ADD";


/* Main Modal */
export const OPEN_MAIN_MODAL = "OPEN_MAIN_MODAL";
export const CLOSE_MAIN_MODAL = "CLOSE_MAIN_MODAL";

/* Edify Spinner */
export const OPEN_EDIFY_SPINNER = "OPEN_EDIFY_SPINNER";
export const CLOSE_EDIFY_SPINNER = "CLOSE_EDIFY_SPINNER";

/* NDA Modal*/
export const OPEN_NDA_MODAL = "OPEN_NDA_MODAL";
export const CLOSE_NDA_MODAL = "CLOSE_NDA_MODAL";

//Contact notification modal
export const OPEN_SEND_CONTACT_NOTIFICATION_MODAL = "OPEN_SEND_CONTACT_NOTIFICATION_MODAL";
export const CLOSE_SEND_CONTACT_NOTIFICATION_MODAL = "CLOSE_SEND_CONTACT_NOTIFICATION_MODAL";

//Calification Modal
export const OPEN_CALIFICATION_MODAL = "OPEN_CALIFICATION_MODAL";
export const CLOSE_CALIFICATION_MODAL = "CLOSE_CALIFICATION_MODAL";

//Filter Users Modal 
export const OPEN_FILTER_USERS_MODAL = "OPEN_FILTER_USERS_MODAL";
export const CLOSE_FILTER_USERS_MODAL = "CLOSE_FILTER_USERS_MODAL";

//WorfOffer Modal
export const OPEN_WORKOFFER_MODAL = "OPEN_WORKOFFER_MODAL";
export const CLOSE_WORKOFFER_MODAL = "CLOSE_WORKOFFER_MODAL";

// Feed Text Modal
export const OPEN_FEED_TEXT_MODAL = "OPEN_FEED_TEXT_MODAL";
export const CLOSE_FEED_TEXT_MODAL = "CLOSE_FEED_TEXT_MODAL";

// Feed Image Modal
export const OPEN_FEED_IMAGE_MODAL = "OPEN_FEED_IMAGE_MODAL";
export const CLOSE_FEED_IMAGE_MODAL = "CLOSE_FEED_IMAGE_MODAL";

// Nav Style
export const NAV_STYLE = "NAV_STYLE";

// User Info Nav Style 
export const USER_INFO_NAV_STYLE = "USER_INFO_NAV_STYLE"

/* Tipe Manager */
export const CHANGE_TYPE = "CHANGE_TYPE";

/* SET FILE ACTION */
export const SET_FILE = "SET_FILE";

/* UPLOAD PERSONA */
export const SET_PERSONA = "SET_PERSONA";

/* UPLOAD PERSONA */
export const SET_EMPRESA = "SET_PERSONA";

/* PORTFOLIO */
export const SET_PORTFOLIO = "SET_PORTFOLIO";
export const ADD_PORTFOLIO = "ADD_PORTFOLIO";
export const EDIT_PORTFOLIO = "EDIT_PORTFOLIO";
export const SEARCH_SKILL = "SEARCH_SKILL";
export const SET_SKILLS_LIST = "SET_SKILLS_LIST";
export const SET_SKILLS = "SET_SKILLS";
export const GET_USER_SKILL = "GET_USER_SKILL";
export const ADD_SKILL = "ADD_SKILL";
export const REMOVE_SKILL = "REMOVE_SKILL";
export const SET_USERS_BY_SKILL = "SET_USERS_BY_SKILL";
export const RESET_SELECTEDSKILL = "RESET_SELECTEDSKILL";
export const SET_USERS_IN_SKILL_NAME = "SET_USERS_IN_SKILL_NAME";
export const UPLOAD_IMAGE_PORTFOLIO = "UPLOAD_IMAGE_PORTFOLIO";
export const UPLOAD_IMAGE_PREVIEW = "UPLOAD_IMAGE_PREVIEW";
export const EDIT_IMAGE_NEW_PORTFOLIO = "EDIT_IMAGE_NEW_PORTFOLIO";
export const EDIT_IMAGE_OLD_PORTFOLIO = "EDIT_IMAGE_OLD_PORTFOLIO";
export const DELETE_IMAGE_PORTFOLIO_EDIT = "DELETE_IMAGE_PORTFOLIO_EDIT";
export const DELETE_IMAGE_PORTFOLIO_NEW = "DELETE_IMAGE_PORTFOLIO_NEW";
export const DELETE_IMAGE_BUFFER = "DELETE_IMAGE_BUFFER";

/* SUSCRIPTION */
export const GET_PLANS = "GET_PLANS";
export const GET_USER_PLAN = "GET_USER_PLAN";
export const SET_PLANID = "SET_PLANID";
export const SEND_PLAN = "SEND_PLAN";
export const SET_TERMINOS = "SET_TERMINOS";
export const SET_SUCCESS = "SET_SUCCESS";

/* TUTORIAL */
export const CLOSE_TUTORIAL_MODAL = "CLOSE_TUTORIAL_MODAL";   
export const OPEN_TUTORIAL_MODAL = "OPEN_TUTORIAL_MODAL";

/* PROFILE */

export const SET_NEW_PROFILE_IMAGE = "SET_NEW_PROFILE_IMAGE";
export const SET_OPEN_MAIN_ID = "SET_OPEN_MAIN_ID";
export const SET_NEWSKILL_TO_LIST = "SET_NEWSKILL_TO_LIST"; 
export const OPEN_SHARE_PROFILE = "OPEN_SHARE_PROFILE";
export const OPEN_PROFILE_MENU = "OPEN_PROFILE_MENU";
export const GET_SUBPROJECT_BUDGETS = "GET_SUBPROJECT_BUDGETS";
export const CLEAR_SUBPROJECT_BUDGETS = "CLEAR_SUBPROJECT_BUDGETS";
export const OPEN_EDIT_PROFILE_DATA = "OPEN_EDIT_PROFILE_DATA";
export const CLOSE_EDIT_PROFILE_DATA = "CLOSE_EDIT_PROFILE_DATA";
export const PROFILE_DATA_OF_USER_SENDER = "PROFILE_DATA_OF_USER_SENDER";
export const OPEN_EDIT_PROFILE_SKILL = "OPEN_EDIT_PROFILE_SKILL";
export const CLOSE_EDIT_PROFILE_SKILL = "CLOSE_EDIT_PROFILE_SKILL";
export const SET_GO_BACK_TO_CATEGORY_LIST = "SET_GO_BACK_TO_CATEGORY_LIST";
export const GET_POSTULATIONS = "GET_POSTULATIONS";
export const SET_EDIT_PROJECTS_SHOW = "SET_EDIT_PROJECTS_SHOW";
export const SET_PROJECT_TO_EDIT = "SET_PROJECT_TO_EDIT";
export const RESET_PROJECT_TO_EDIT = "RESET_PROJECT_TO_EDIT";
export const SET_LIKES = "SET_LIKES";
export const GET_VISITS = "GET_VISITS";
export const GET_PROJECTS = "GET_PROJECTS";
export const SET_SUBPROJECT_ID = "SET_SUBPROJECT_ID";
export const SET_SUBPROJECTFORM_STYLE = "SET_SUBPROJECTFORM_STYLE";
export const RESET_SUBPROJECTFORM_STYLE = "RESET_SUBPROJECTFORM_STYLE";
export const MY_DATA = "MY_DATA";
export const GET_COMMENTS = "GET_COMMETS";
export const POST_COMMENT = "POST_COMMENT";
export const COMMENT_POSTED = "COMMENT_POSTED";
export const SET_QUOTATION_TO_EDIT = "SET_QUOTATION_TO_EDIT";
export const SET_EDIT_PROFILE_VALUES = "SET_EDIT_PROFILE_VALUES";

/* DASHBOARD */

export const GET_DASHBOARD_PROJECTS = "GET_DASHBOARD_PROJECTS";
export const GET_TOTAL_USERS = "GET_TOTAL_USERS";
export const GET_LAST_NEW_USERS = "GET_LAST_NEW_USERS";
export const GET_TOTAL_PUBLIC_PROJECTS = "GET_TOTAL_PUBLIC_PROJECTS";
export const GET_TOTAL_PRIVATE_PROJECTS = "GET_TOTAL_PRIVATE_PROJECTS";
export const GET_TOTAL_ADJUDICATED_PROJECTS = "GET_TOTAL_ADJUDICATED_PROJECTS";
export const GET_LAST_ADJUDICATED_PROJECT = "GET_LAST_ADJUDICATED_PROJECT";
export const GET_TOTAL_NEW_PRIVATE_QUOTATIONS = "GET_TOTAL_NEW_PRIVATE_QUOTATIONS";
export const GET_TOTAL_PUBLIC_QUOTATIONS = "GET_TOTAL_PUBLIC_QUOTATIONS";
export const GET_TOTAL_NEW_PUBLIC_QUOTATIONS = "GET_TOTAL_NEW_PUBLIC_QUOTATIONS";
export const GET_TOTAL_PRIVATE_QUOTATIONS = "GET_TOTAL_PRIVATE_QUOTATIONS";
export const SET_PROFILE_VISUALIZER = "SET_PROFILE_VISUALIZER";
export const SET_LOCATION = "SET_LOCATION";
export const CLEAR_LOCATION = "CLEAR_LOCATION";
export const SET_RESPONSIVE_FALL = "SET_RESPONSIVE_FALL";
export const DISABLE_POSTULATE_BUTTON = "DISABLE_POSTULATE_BUTTON";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
export const GET_DASHBOARD_PROJECTS_LOADING = "GET_DASHBOARD_PROJECTS_LOADING";
export const PREVIOUS_ONBOARDING_MODAL = "PREVIOUS_ONBOARDING_MODAL";
export const NEXT_ONBOARDING_MODAL = "NEXT_ONBOARDING_MODAL";
export const CLOSE_ONBOARDING_MODAL = "CLOSE_ONBOARDING_MODAL";
export const SET_PREVIOUS_LOCATION = "SET_PREVIOUS_LOCATION";

/* POSTEOS */

export const GET_POSTEOS = "GET_POSTEOS";
export const GET_OWN_POSTEOS = "GET_OWN_POSTEOS";
export const RECENT_POST = "RECENT_POST";

/* SUBPROYECT */

export const UPDATE_SUBPROJECTS_LIST = "UPDATE_SUBPROJECTS_LIST";
export const DELETE_SUBPROJECT = "DELETE_SUBPROJECT";
export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const SET_PROJECTSTEP1_ID = "SET_PROJECTSTEP1_ID";
export const GET_SUBPROJECTS_TYPE = "GET_SUBPROJECTS_TYPE";
export const SHOW_SUB = "SHOW_SUB";
export const SET_DELETE_SUBPROJECT = "SET_DELETE_SUBPROJECT";
export const SET_EDIT_SUBPROJECT = "SET_EDIT_SUBPROJECT";
export const RESET_SUBPROJECTS = "RESET_SUBPROJECTS";
export const SET_SUBPROJECTS_FROM_EDIT = "SET_SUBPROJECTS_FROM_EDIT";

/* PROYECTO */
export const SET_SEND_PROJECT = "SET_SEND_PROJECT";
export const SET_SEND_SUBPROJECT = "SET_SEND_SUBPROJECT";
export const SET_NEW_SUBPROJECT = "SET_NEW_SUBPROJECT";
export const SET_ID_PROJECT = "SET_ID_PROJECT";
export const RESET_ID_PROJECT = "RESET_ID_PROJECT";
export const SET_PROJECT_LOADING = "SET_PROJECT_LOADING";
export const SET_SUBMIT_LOADING = "SET_SUBMIT_LOADING";
export const GET_PRIVATE_PROJECT_BY_ID = "GET_PRIVATE_PROJECT_BY_ID";
export const SET_PROJECT_FORM_STYLE = "SET_PROJECT_FORM_STYLE";
export const GET_POSTULATION_PROJECT_STATUS = "GET_POSTULATION_PROJECT_STATUS";
export const SET_PUBLIC_PROJECT = "SET_PUBLIC_PROJECT";
export const SET_ADJUDICATED_PUBLIC_PROJECT = "SET_ADJUDICATED_PUBLIC_PROJECT";

/* QUESTIONS */
export const GET_QUESTIONS = "GET_QUESTIONS";
export const DISPLAY_INFO = "DISPLAY_INFO";

/* POSTULACIONES */
export const SET_POSTULATION_PROJECT = "SET_POSTULATION_PROJECT";
export const CLEAR_POSTULATION_PROJECT = "CLEAR_POSTULATION_PROJECT";
export const SET_POSTULATION_PROJECT_ID = "SET_POSTULATION_PROJECT_ID";

/* SOLICITUDES */
export const GET_SOLICITUDE_LIST = "GET_SOLICITUDE_LIST";
export const SET_SOLICITUDE_ID = "SET_SOLICITUDE_ID";

/* BUDGETS */
export const GET_BUDGETS_LIST = "GET_BUDGETS_LIST";
export const SET_BUDGET_ID = "SET_BUDGET_ID";

/* FILTROS CONTROLLER */
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const FILTER_SUBPROJECT_LIST = "FILTER_SUBPROJECT_LIST";
export const RESET_FILTERBY = "RESET_FILTERBY";

/* Skeleton Loading */
export const SKELETON_END = "SKELETON_END";
export const SKELETON_START = "SKELETON_START";
export const PROGRESS_BAR_STATUS = "PROGRESS_BAR_STATUS";


/* COTIZACION */
export const SET_SEND_SUBQUOTATION = "SET_SEND_SUBQUOTATION";
export const SET_NEW_SUBQUOTATION = "SET_NEW_SUBQUOTATION";
export const SET_ID_QUOTATION = "SET_ID_QUOTATION";
export const RESET_ID_QUOTATION = "RESET_ID_QUOTATION";
export const SET_QUOTATION_LOADING = "SET_QUOTATION_LOADING";
export const GET_QUOTATIONS_TYPE = "GET_QUOTATIONS_TYPE";
export const GET_MY_QUOTATIONS = "GET_MY_QUOTATIONS";
export const GET_MY_QUOTATIONS_USERPANEL = "GET_MY_QUOTATIONS_USERPANEL";
export const GET_QUOTATION = "GET_QUOTATION";
export const CLEAR_QUOTATIONS = "CLEAR_QUOTATIONS";
export const SET_PUBLIC_QUOTATION = "SET_PUBLIC_QUOTATION";

/* USERS */
export const USERS_REGISTERED_DATA = "USERS_REGISTERED_DATA";
export const USERS_FEATURED_DATA = "USERS_FEATURED_DATA";
export const USER_CALIFICATIONS = "USER_CALIFICATIONS";

/* SUBQUOTATION */

export const SET_SUBQUOTATION = "SET_SUBQUOTATION";
export const SET_DELETE_SUBQUOTATION = "SET_DELETE_SUBQUOTATION";
export const GET_SUBQUOTATIONS_TYPE = "GET_SUBQUOTATIONS_TYPE";
export const SHOW_SUBQUOTATIONS = "SHOW_SUBQUOTATIONS";
export const CLEAR_SUBQUOTATIONS = "CLEAR_SUBQUOTATIONS";
export const GET_SUB_QUOTATIONS_POSTULATIONS = "GET_SUB_QUOTATIONS_POSTULATIONS";

/* SUSCRIPTION */

export const OPEN_SUSCRIPTION_MODAL = "OPEN_SUSCRIPTION_MODAL";
export const CLOSE_SUSCRIPTION_MODAL = "CLOSE_SUSCRIPTION_MODAL";
export const SUSCRIPTION_LOADING = "SUSCRIPTION_LOADING";
export const ACTIVATE_FREE_TRIAL = "ACTIVATE_FREE_TRIAL";
export const CANCEL_SUSCRIPTION_LOADING = "CANCEL_SUSCRIPTION_LOADING";

/* BOLSA DE TRABAJO */

export const GET_ALL_WORKBAG_USERS = "GET_ALL_WORKBAG_USERS";
export const FILTER_WORKBAG_USERS = "FILTER_WORKBAG_USERS";
export const GET_ALL_JOB_REQUESTS = "GET_ALL_JOB_REQUESTS";

