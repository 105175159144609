import React, { useEffect, useState } from "react";
import styles from "./PrivateProyects.module.scss";
import styled from "styled-components";
import Loading from "../../UI/Loading/Loading";
import Botonera from "../../Botonera/Botonera";
import NewMaterialBudget from "../../../components/NewMaterialBudget/NewMaterialBudget";
import NewProject from "../../../components/NewProject/NewProject";
import { ReactComponent as Faq } from "../../../assets/svg/FAQ.svg";
import { ReactComponent as Plus } from "../../../assets/svg/plus-icon.svg";
import { connect } from "react-redux";
import Modal from "../../../components/Modal/Modal";
import {
  getDashboardProjects,
  getProjects,
  resetFilterBy,
  getProjectsByType,
  openFeedTextModal,
  clearProjects,
  closeMainModal,
  setProjectFormStyle,
  setSubProjectStyle,
  openTutorialModal,
  resetProjectToEdit,
} from "../../../store/actions";
import EmptyState from "../../UI/EmptyState/EmptyState";
import useSuscriptionModalTimeout from "../../../hooks/useSuscriptionModalTimeout";
import Pagination from "../../../hooks/pagination";
import AllProjectsItemEmptyCard from "../AllProjectsItem/AllProjectsItemEmptyCard";
import FilterPrivateProjects from "../FilterBy/FilterPrivateProjects/FilterPrivateProjects";
import { motion } from "framer-motion";
import FondoListadoObra from "../../../assets/images/fondoObraPrivada1.jpg";
import FondoObraNueva from "../../../assets/images/fondoObraNueva.jpg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

function PrivateProjects({
  isLoaded,
  profileMenu,
  page,
  itemCount,
  openVisualizer,
  openTutorialModal,
  allProjects,
  isVisualizerOpen,
  getProjectsByType,
  clearProjects,
  closeMainModal,
  isFilter,
  loading,
  searchParams,
}) {

  const [isOpen, setIsOpen] = useState(false);
  const pageNumberLimit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [isOpenMaterialBudget, setIsOpenMaterialBudget] = useState(false);
  const [isOpenProject, setModalOpenProject] = useState(false);
  const [showProjectList, setShowProjectList] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  useSuscriptionModalTimeout();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  useEffect(() => {
    handleResize();
    clearProjects();
  }, []);



  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getProjectsByType("PRIVATE", currentPage, pageNumberLimit);
  }, [currentPage, clearProjects]);


  function setModalOpen(isOpen) {
    closeMainModal();
    setIsOpen(isOpen);
  }

  const handleNewOpenProject = () => {
    resetProjectToEdit();
    setProjectFormStyle(false);
    setModalOpenProject(true);
    closeMainModal();
  };


  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: allProjects,
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <Container>
      <Wrapper className={openVisualizer ? isVisualizerOpen : ""}>
        {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
          null}
        <div className={styles.container}>
          <section className={styles.containerList}>
            {!showProjectList ? (
              <>
            <motion.div className={styles.containerList_section} initial={{ x: -500 }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>
              <div className={styles.containerList_section_backdropList}>
                <div className={styles.containerList_section_button} onClick={() => setShowProjectList(true)}>Ver los proyectos existentes</div>
                <img src={FondoListadoObra} alt="FondoListadoObra" className={styles.containerList_background} />
              </div>
              <div className={styles.containerList_section_description}>
                <div className={styles.containerList_section_title}>Encontrá tu próximo proyecto: cotizá las obras en búsqueda de tu talento</div>
                <ul className={styles.containerList_section_description_txt}>
                  <li>Usá el filtrador para encontrar las obras por ubicación, tipo de trabajo solicitado, etc.</li>
                  <li>Postulate para acceder a la documentación del proyecto y realizar una cotizacion.</li>
                  <li>Podés ver todos los detalles de cada obra haciendo click en "ver más".</li>
                </ul>
              </div>
            </motion.div>
            <motion.div className={styles.containerList_section} initial={{ x: 500 }} animate={{ x: 0 }} transition={{ duration: 0.5 }}>
              <div className={styles.containerList_section_description}>
                <div className={styles.containerList_section_title}>¿Tenés un proyecto y necesitas una cotización?</div>
                <ul className={styles.containerList_section_description_txt}>
                  <li>Creá una publicación y adjuntá la documentación correspondiente.</li>
                  <li>Podés programar la recepción de cotizaciones de acuerdo a tu agenda.</li>
                  <li>Para cotizar, los usuarios te enviarán una solicitud para acceder a la documentación, en base a su perfil vos decidís quién puede descargarla.</li>
                  <li>Calificá al usuario de acuerdo a tu experiencia con él y la valoración de su trabajo.</li>
                </ul>
              </div>
              <div className={styles.containerList_section_backdropCalculator}>
                <div className={styles.containerList_section_buttonCalculator} onClick={() => handleNewOpenProject()}>Crear una publicación nueva</div>
                <img src={FondoObraNueva} alt="FondoObraNueva" className={styles.containerList_backgroundCalculator} />
              </div>
            </motion.div>
            </>
            ) : (
              <>
                <FilterPrivateProjects />
                {Array.isArray(allProjects) && allProjects.length === 0 && !loading ? (
                  <div className={styles.emptyState}>
                    <EmptyState title="No hay resultados para su búsqueda." />
                  </div>
                ) : loading ? (
                  <Loading noModal />
                ) : <div className={styles.AllProjects} id="allProjects">
                  {allProjects?.itemCount || allProjects?.length > 0 ? (
                    <Pagination
                      {...paginationAttributes}
                      onPrevClick={onPrevClick}
                      onNextClick={onNextClick}
                      onPageChange={onPageChange}
                      setModalOpen={setModalOpen}
                      isOpen={isOpen}
                    />
                  ) : (
                    <AllProjectsItemEmptyCard
                      setModalOpenProject={setModalOpenProject}
                      isOpen={isOpenProject} />
                  )}
                </div>}
              </>
            )}
          </section>
        </div>
      </Wrapper>
      <Modal isOpen={isOpenMaterialBudget}>
        <NewMaterialBudget closeModal={() => setIsOpenMaterialBudget(false)} />
      </Modal>
      <Modal isOpen={isOpenProject}>
        <NewProject closeModal={() => setModalOpenProject(false)} />
      </Modal>
    </Container>

  );
}

const mapStateToProps = (state) => {
  return {
    profileMenu: state.profile.menuShow,
    loading: state.dashboard.loading,
    allProjects: state.dashboard.viewProjects,
    searchParams: state.dashboard.searchParams,
    isFilter: state.dashboard.isFilter,
    page: state.dashboard.page,
    itemCount: state.dashboard.itemCount,
    take: state.dashboard.take,
    showButton: state.dashboard.allProjects,
    proyectos: state.profile.proyectos,
    postulaciones: state.profile.postulations,
    isLoaded: state.general.isLoaded,
    isVisualizerOpen: state.general.recoverOpen,
    tutorialModal: state.dashboard.tutorialModal,
  };
};

export default connect(mapStateToProps, {
  getDashboardProjects,
  getProjectsByType,
  getProjects,
  resetFilterBy,
  clearProjects,
  closeMainModal,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  openFeedTextModal,
  openTutorialModal,
})(PrivateProjects);
