import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getTotalUsers,
  getTotalPublicProjects,
  getTotalPublicQuotations,
  getTotalPrivateQuotations,
  getLastNewUsers,
  getTotalNewPublicQuotations,
  getLastAdjudicatedProject,
  getTotalNewPrivateQuotations,
  getTotalAdjudications,
} from "../../store/actions";
import styles from "./QuePasaEnEdify.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus-icon.svg";
import { ReactComponent as AdjudicatedIcon } from "../../assets/svg/empresaAdjudicadaIcon.svg";
import CheckedProfile from "../../assets/images/perfilChecked.png";
import { ReactComponent as LocationIcon } from "../../assets/svg/Location.svg";

const QuePasaEnEdify = ({
  getTotalUsers,
  totalUsers = 0,
  getTotalPublicProjects,
  totalPublicProjects,
  getTotalPublicQuotations,
  totalAdjudicatedProjects,
  getTotalPrivateQuotations,
  getLastAdjudicatedProject,
  lastAdjudicatedProject,
  getTotalAdjudications,
  totalPublicQuotations,
  lastNewUsers = [],
  getLastNewUsers, 
  lastNewUser = [],
  lastPublicProject = [],
  lastPublicQuotation = [],
}) => {
  const [loading, setLoading] = useState(true);
 const navigate = useNavigate();

  //Obtenemos los datos para el dashboard

  useEffect(() => {
    getTotalUsers();
    getLastNewUsers();
    getTotalPublicProjects();
    getLastAdjudicatedProject();
    getTotalAdjudications();
    getTotalPublicQuotations();
  }, [getTotalUsers, getTotalPublicProjects, getTotalPublicQuotations]);

  //Maneja la redirección al perfil del usuario
  const handleClickProfile = () => {
    const userName = lastNewUsers[0].type === "COMPANY" ? lastNewUsers[0].razon_social : lastNewUsers[0].name + " " + lastNewUsers[0].last_name;
    navigate(`/comunidad-edify/${lastNewUsers[0].id}/${userName}`);
  }

  //Maneja la redirección a la ultima obra pública
  const handleClickOpenProject = (quotation, publicProject) => {
    if (quotation) {
      window.open(`/materiales-a-licitar-publicos/${lastPublicQuotation.id}/${lastPublicQuotation.name}`, "_blank");
    } else if (publicProject) {
      window.open(`/obra-publica-a-licitar/${lastPublicProject?.id}/${lastPublicProject?.name}`, "_blank");
    } else {
      window.open(`/licitacionespublicas/proyectos-adjudicados/${lastAdjudicatedProject?.id}/${lastAdjudicatedProject?.name}`, "_blank");
    }
  }


  return (
    <main className={styles.QuePasaEnEdify}>
      <header className={styles.QuePasaEnEdify_container_header}>
          Novedades
          </header>
      <div className={styles.QuePasaEnEdify_container}>
        
        <div className={styles.QuePasaEnEdify_container_content}>
          <div className={styles.cantidadContainerFirst}>
            {totalUsers !== undefined && totalUsers > 0 && (
            <p className={styles.userCantidad}>{totalUsers}</p>
            )}
            <div className={styles.QuePasaEnEdify_container_content_item}>
              Usuarios
            </div>
          </div>
          <div className={styles.notificationContainer}>
            <div className={styles.notificationContainer_header}>
              <p className={styles.notificationContainer_title}>nuevo</p>
              <PlusIcon height={20} width={20} />
            </div>
            <div
              className={styles.notificationContainer_profile}
              onClick={() => handleClickProfile()}
            >
              <div className={styles.notificationContainer_profile_image}>
              <img
                className={styles.notificationContainer_profile_img}
                src={lastNewUsers[0]?.image_profile}
                alt="user"
              />
              {lastNewUsers[0]?.validated && (
              <img
                className={styles.notificationContainer_profile_imageChecked}
                src={CheckedProfile}
                alt="checked"
              />
              )}
              </div>
              <div className={styles.notificationContainer_info}>
                <span className={styles.notificationContainer_userName}>{lastNewUsers[0]?.type === "COMPANY" ? lastNewUsers[0].razon_social : lastNewUsers[0]?.name + " " + lastNewUsers[0]?.last_name}</span>
              </div>
            </div>
          </div>
          {totalPublicProjects !== undefined && totalPublicProjects > 0 && (
            <>
          <div className={styles.cantidadContainer}>
            <p className={styles.obrasCantidad}>{totalPublicProjects}</p>
            <div className={styles.QuePasaEnEdify_container_content_item}>
              Obras Públicas
            </div>
          </div>
          <div className={styles.notificationContainer}>
            <div className={styles.notificationContainer_header}>
              <p className={styles.notificationContainer_title}>nueva</p>
              <PlusIcon height={20} width={20} />
            </div>
            <div className={styles.notificationContainer_publicProject} onClick={() => handleClickOpenProject(false, true)}>
              <div className={styles.notificationContainer_publicProject_info}>
                <span className={styles.notificationContainer_publicProject_name}>
                  {lastPublicProject?.name}
                </span>
                <span className={styles.notificationContainer_publicProject_zone}>
                  <LocationIcon height={12} width={12} />
                  {lastPublicProject?.zone}
                </span>
              </div>
            </div>
          </div>
          </>
          )}
          {totalAdjudicatedProjects !== undefined && totalAdjudicatedProjects > 0 && (
            <>
          <div className={styles.cantidadContainer}>
            <p className={styles.obrasCantidad}>{totalAdjudicatedProjects}</p>
            <div className={styles.QuePasaEnEdify_container_content_item}>
              Adjudicaciones
            </div>
          </div>
          <div className={styles.notificationContainer}>
            <div className={styles.notificationContainer_header}>
              <p className={styles.notificationContainer_title}>nueva</p>
              <PlusIcon height={20} width={20} />
            </div>
            <div className={styles.notificationContainer_publicProject} onClick={() => handleClickOpenProject()}>
              <div className={styles.notificationContainer_publicProject_info}>
                <span className={styles.notificationContainer_publicProject_name}>
                  {lastAdjudicatedProject?.name}
                </span>
                <span className={styles.notificationContainer_publicProject_name}>
                  <AdjudicatedIcon height={12} width={12} />
                  {lastAdjudicatedProject?.empresa}
                </span>
                <span className={styles.notificationContainer_publicProject_zone}>
                  <LocationIcon height={12} width={12} />
                  {lastAdjudicatedProject?.province}
                </span>
              </div>
            </div>
          </div>
          </>
          )}
          <div className={styles.cantidadContainer}>
            <p className={styles.cotizacionCantidad}>{totalPublicQuotations}</p>
            <div className={styles.QuePasaEnEdify_container_content_item}>
              Cotización de Materiales
            </div>
          </div>
          {lastPublicQuotation.length !== 0 && (
          <div className={styles.notificationContainer}>
            <div className={styles.notificationContainer_header}>
              <p className={styles.notificationContainer_title}>nueva</p>
              <PlusIcon height={20} width={20} />
            </div>
            <div className={styles.notificationContainer_publicQuotation} onClick={() => handleClickOpenProject(true)}>
              <div className={styles.notificationContainer_publicQuotation_info}>
                <span className={styles.notificationContainer_publicQuotation_name}>
                  {lastPublicQuotation?.name}
                </span>
                <span className={styles.notificationContainer_publicQuotation_zone}>
                <LocationIcon height={12} width={12} />
                  {lastPublicQuotation?.zone}
                </span>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    totalUsers: state.dashboard.totalUsers,
    totalPublicProjects: state.dashboard.totalPublicProjects,
    totalPublicQuotations: state.dashboard.totalPublicQuotations,
    totalPrivateQuotations: state.dashboard.totalPrivateQuotations,
    totalAdjudicatedProjects: state.dashboard.totalAdjudicatedProjects,
    lastNewUsers: state.dashboard.lastNewUsers,
    totalNewPrivateProjects: state.dashboard.totalNewPrivateProjects,
    totalNewPublicQuotations: state.dashboard.totalNewPublicQuotations,
    totalNewPrivateQuotations: state.dashboard.totalNewPrivateQuotations,
    lastPublicProject: state.dashboard.lastPublicProject,
    lastPublicQuotation: state.dashboard.lastPublicQuotation,
    lastAdjudicatedProject: state.dashboard.lastAdjudicatedProject,
  };
}

export default connect(mapStateToProps, {
  getTotalUsers,
  getTotalPublicProjects,
  getTotalPublicQuotations,
  getTotalPrivateQuotations,
  getLastNewUsers,
  getTotalNewPublicQuotations,
  getTotalNewPrivateQuotations,
  getTotalAdjudications,
  getLastAdjudicatedProject,
})(QuePasaEnEdify);
