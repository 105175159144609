import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./WorkOfferById.module.scss";

const WorkOfferById = ({ workOffers }) => {

    const { id } = useParams();
    const workOffer = workOffers?.find((workOffer) => workOffer.id === id);
    
    return (
        <div className={styles.container}>
        <h1>{workOffer?.title}</h1>
        <p>{workOffer?.description}</p>
        </div>
    );
    }

const mapStateToProps = (state) => {
    return {
        workOffers: state.register.allJobRequests.workOffers
    };
}

export default connect(mapStateToProps)(WorkOfferById);