import React from 'react';
import styled from 'styled-components';


const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid #bfbfbf;
  padding: 12px 15px;
  outline: none;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  color: #707070;
  text-align: center;
  &:focus,
  &:active {
    border-bottom: 2px solid var(--color-mainBlue);
  }
  &::placeholder {
    color: #bfbfbf;
    font-weight: 600;
  }
  &.error {
    border-bottom: 2px solid var(--color-error);
    &::placeholder {
      color: var(--color-error);
    }
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #707070;
  }
`;


const Input = ({ field, ...props }) => {
  return (
    <InputWrapper>
      <StyledInput {...field} {...props} />
    </InputWrapper>
  );
};

export default Input;