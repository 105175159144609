import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import imagen1 from "../../../assets/images/CarrouselObras.png";
import imagen2 from "../../../assets/images/CarrouselMateriales.png";
import styles from "./ImageCarousel.module.scss";
import { fireAnalytics } from "../../../utils/firebase";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import { connect } from "react-redux";
import { closeMainModal, setProjectFormStyle, resetProjectToEdit } from "../../../store/actions/profileActions";
import Modal from "../../Modal/Modal";
import NewMaterialBudget from "../../NewMaterialBudget/NewMaterialBudget";
import NewProject from "../../NewProject/NewProject";

export const ImageCarousel = ({newMaterialBudget, newProject, closeMainModal, setProjectFormStyle, resetProjectToEdit}) => {
    const [isOpenMaterialBudget, setModalOpenMaterialBudget] = useState(false);
    const [isOpenProject, setModalOpenProject] = useState(false); 
    const premiumCheck = usePremiumCheck();

    const handleNewMaterialBudget = () => {
        fireAnalytics("dsh_create_quotation", { category: "dashboard" });
        setModalOpenMaterialBudget(true);
        closeMainModal();
      };
    
      const handleNewOpenProject = () => {
        fireAnalytics("dsh_create_project", { category: "dashboard" });
        resetProjectToEdit();
        setProjectFormStyle(false);
          setModalOpenProject(true);
          closeMainModal(); 
      };

  return (
    <>
    <Carousel showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} interval={6500} infiniteLoop={true}>
      <div className={styles.imageContainer} onClick={premiumCheck(handleNewOpenProject)} >
        <img src={imagen1} alt="Imagen 1" className={styles.image} />
      </div>
      <div className={styles.imageContainer} onClick={premiumCheck(handleNewMaterialBudget)}>
        <img src={imagen2} alt="Imagen 2" className={styles.image}/>
      </div>
    </Carousel>
     <Modal
     isOpen={isOpenMaterialBudget}      
   >
     <NewMaterialBudget closeModal={() => setModalOpenMaterialBudget(false)} />
   </Modal>
   <Modal
     isOpen={isOpenProject}          
   >
     <NewProject closeModal={() => setModalOpenProject(false)} />
   </Modal>
   </>
  );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, {closeMainModal, setProjectFormStyle, resetProjectToEdit})(ImageCarousel);

