import React, { useState, useEffect } from "react";
import styles from "./VoteReputation.module.scss";
import { ReactComponent as StarFull } from "../../../assets/svg/starFullSvg.svg";
import { ReactComponent as StarEmpty } from "../../../assets/svg/emptyStarSvg.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/svg/arrowLeftIcon.svg";
import { connect } from "react-redux";
import { getUserSkill, likeUser } from "../../../store/actions";
import Lottie from "react-lottie";
import checkMarkBlue from "../../../assets/lotties/checkMarkBlue.json";

const VoteReputation = ({ getUserSkill, userSkill, userCalificatedData, likeUser }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [showSkillMenu, setShowSkillMenu] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [comment, setComment] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [rateSteps, setRateSteps] = useState([
    { id: 1, name: "Elegir rubro a calificar (1 o más):" },
    { id: 2, name: "Escribir un comentario:" },
    { id: 3, name: "Calificar:" },
  ]);

  useEffect(() => {
    getUserSkill(userCalificatedData?.userData?.id);
  }, []);

  useEffect(() => {
    if (selectedSkills.length > 0 && currentStep === 3 && comment.trim() !== '' && selectedImage > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [selectedSkills, comment, currentStep, selectedImage]);

  const handleImageClick = (imageIndex) => {
    setSelectedImage(imageIndex);
  };

  const handleStarHover = (imageIndex) => {
    setSelectedImage(imageIndex);
  };

  const handleMouseLeave = (imageIndex) => {
    setSelectedImage(imageIndex);
  };

  const handleSelectSkill = (skill) => {
    if (!selectedSkills.find(selected => selected.id === skill.id)) {
      setSelectedSkills(prevSkills => [...prevSkills, skill]);
      setShowSkillMenu(false);
    }
  };

  const handleRemoveSkill = (skillToRemove, e) => {
    e.stopPropagation();
    setSelectedSkills(prevSkills => prevSkills.filter(skill => skill !== skillToRemove));
  };

  const handleVote = () => {
    const skillIds = selectedSkills.map(skill => parseInt(skill.id));
    likeUser(parseInt(selectedImage),  comment, parseInt(userCalificatedData?.userData?.id), skillIds);
  }

  const getImageComponent = (imageIndex) => {
    return selectedImage >= imageIndex ? <StarFull width={20} height={20} /> : <StarEmpty width={20} height={20} />;
  };

  return (
    <div className={styles.voteContainer}>
      <div className={styles.stepsContainer}>
        {rateSteps.map(step => (
          <div key={step.id} className={currentStep === step.id ? styles.activeStep : styles.step}>
            <div className={styles.stepNumber}>
              <p className={`${styles.stepNumber_number} ${currentStep === step.id ? styles.activeNumber : ""}`}>{step.id}</p>
            </div>
            <div className={styles.stepContent}>
              <div className={styles.stepName}>{step.name}
              <div className={styles.stepName_check}>
                {(step.id === 1 && selectedSkills.length > 0) && (<Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: checkMarkBlue,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                  }}
                  height={20}
                  width={30}
                  style={{ margin: "0"}}
                />)}
                {(step.id === 2 && comment.trim() !== '') && (<Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: checkMarkBlue,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                  }}
                  height={20}
                  width={30}
                  style={{ margin: "0"}}
                />)}
                {(step.id === 3 && selectedImage > 0) && (<Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: checkMarkBlue,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                  }}
                  height={20}
                  width={30}
                  style={{ margin: "0"}}
                />)}
                </div>
              </div>
              {currentStep === step.id && (
                <div className={styles.stepItem}>
                  {currentStep === 1 && (
                    <div className={styles.skillContainer}>
                      <div className={styles.skillMenu} onClick={() => setShowSkillMenu(!showSkillMenu)}>
                        {selectedSkills.length > 0 ? selectedSkills.map((skill, index) => (
                          <span  className={styles.skillMenu_selected} key={index}>
                            {skill.name}
                            <button className={styles.skillMenu_selected_xButton} onClick={(e) => handleRemoveSkill(skill, e)}>X</button>
                          </span>
                        )) : "Seleccionar rubro"}
                        <ArrowDownIcon width={10} height={10} className={showSkillMenu === false ? styles.arrowDownIcon : styles.arrowUpIcon} />
                      </div>
                      {showSkillMenu && (
                        <div className={styles.skillMenu_items}>
                          {userSkill && userSkill.map((skill) => (
                            <div className={styles.skillMenu_items_item} key={skill.id} onClick={() => handleSelectSkill(skill)}>{skill.name}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {currentStep === 2 && (
                    <div className={styles.addComment}>
                      <textarea
                        className={styles.addComment_textArea}
                        placeholder="Ej: Cumplió con todo lo pactado! Recomiendo al 100%"
                        type="text"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  )}
                  {currentStep === 3 && (
                    <div className={styles.starsContainer}>
                      {[1, 2, 3, 4, 5].map((index) => (
                        <div
                          key={index}
                          className={styles.star}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleStarHover(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                          onClick={() => handleImageClick(index)}
                        >
                          {getImageComponent(index)}
                        </div>
                      ))}
                    </div>
                  )}
  
                  <div className={styles.buttonsContainer}>
                  {(currentStep > 1) && (
                    <button className={styles.buttonsContainer_goBackButton} onClick={() => setCurrentStep(prevStep => prevStep - 1)}>
                      <ArrowLeftIcon width={10} height={10}/>Volver</button>
                  )}
                  {(currentStep < rateSteps.length && ((currentStep === 1 && selectedSkills.length > 0) || (currentStep === 2 && comment.trim() !== ''))) && (
                    <button className={styles.buttonsContainer_nextButton} onClick={() => setCurrentStep(prevStep => prevStep + 1)}>Siguiente<ArrowRightIcon width={10} height={10}/></button>
                  )}
                  </div>
                </div>
              )}
            </div>
            {currentStep !== step.id && <div></div>}
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.buttonContainer_btn} disabled={!isValid} onClick={() => handleVote()}>Enviar calificación</button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userSkill: state.register.userSkills,
    userCalificatedData: state.general.calificationModal,
  };
}

export default connect(mapStateToProps, { getUserSkill, likeUser })(VoteReputation);