import React from "react";
import SubProyectosWrapper from "./SubProyectosWrapper/SubProyectosWrapper";
import NuevoSubproyecto from "./NuevoSubproyecto/NuevoSubproyecto";
import styles from "./Subproyectos.module.scss";
import { connect } from "react-redux";

const Subproyectos = ({ 
  subProjects,
  subprojectsEdit, 
  isEditing, 
  projectIdForSub, 
  maxDateForSubProjects,
  minDateForSubProjects,
  closeModal
}) => {

  let subprojectsToMap = isEditing ? subprojectsEdit : subProjects;
  return (
    <div className={styles.subProyectos}>
      <NuevoSubproyecto 
        showProyects={true} 
        isEditing={isEditing} 
        projectIdForSub={projectIdForSub} 
        closeModal={closeModal} 
        maxDateForSubProjects={maxDateForSubProjects}
        minDateForSubProjects={minDateForSubProjects}/>
      {subprojectsToMap?.length > 0 ? <SubProyectosWrapper subprojectsEdit={subprojectsEdit} isEditing={isEditing} projectIdForSub={projectIdForSub} /> 
       : (
        <div className={styles.wrapper}>
    <h5 className={styles.wrapper_subTitle}>RUBROS CARGADOS</h5>
        <p className={styles.emptyDisclaimer}>No hay rubros a cotizar en este proyecto, debes agregar al menos 1 rubro a tu proyecto.</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subProjects: state.profile.sendSubprojects,
    subrpojectsToEdit: state.profile.projectToEdit.subproyecto,
  };
};

export default connect(mapStateToProps)(Subproyectos);
