import React from "react";
import styles from "./MainModal.module.scss";
import { useState } from "react";
import Logo from "../../../assets/images/logo-navbar.png";
import { closeMainModal } from "../../../store/actions/profileActions";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as Publicar } from "../../../assets/svg/publicarProyecto.svg";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2 },
  },
};

const MainModal = ({ closeMainModal, mainModalConfig }) => {
  
  const {
    isSuccess,
    title,
    body,
    confirmHandler,
    customCloseMainModal,
    yesLabel = "si",
    noLabel = "Volver",
    noActionNeeded,
  } = mainModalConfig;
  
  function handleGoBack() {
    if (customCloseMainModal) {
       customCloseMainModal();  
    } else {
      closeMainModal();
    }
  }


  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="1"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.MainModal_overlay}
          onClick={closeMainModal}
        ></motion.div>
        <motion.div
          key="2"
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.MainModal}
        >
          <div className={styles.edifyIcon}>
            <img src={Logo} alt="Edify" className={styles.edifyIcon_img}/>
          </div>
          <h3>{title}</h3>
          <div className={styles.bodyTxt}>{body}</div>
          {isSuccess && noActionNeeded ? (
            null
          ) : (
          <div className={styles.MainModal_options}>
            {isSuccess ? (
              <div
                className={styles.MainModal_optionsitem_success}
                onClick={() => { handleGoBack(); }}
              >
                {noLabel}
              </div>
            ) : (
              <>
                <div
                  className={styles.MainModal_optionsitem}
                  onClick={confirmHandler}
                >
                  {" "}
                  <span className={styles.margin}>
                    <Publicar />{" "}
                  </span>
                  {yesLabel}
                 
                </div>
                <div
                  className={styles.MainModal_optionsitem_decline}
                  onClick={() => handleGoBack()}              
                >
                  {noLabel}
                </div>
              </>
            )}
          </div>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mainModalConfig: state.general.mainModalConfig,
  };
};

export default connect(mapStateToProps, { closeMainModal })(MainModal);
