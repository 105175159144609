import React, { useState, useEffect } from "react";
import styles from "./FinancialData.module.scss";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";

const FinancialData = () => {
    const variables = [
        { id: 4, name: "Tipo de Cambio Minorista ($ por USD)" },
        { id: 5, name: "Tipo de Cambio Mayorista ($ por USD)" },
        { id: 6, name: "Tasa de Política Monetaria (en % n.a.)" },
        { id: 27, name: "Inflación mensual (variación en %)" },
        { id: 28, name: "Inflación interanual (variación en % i.a.)" },
        { id: 29, name: "Inflación esperada - REM próximos 12 meses" },
        { id: 31, name: "Unidad de Valor Adquisitivo (UVA)" },
        { id: 32, name: "Unidad de Vivienda (UVI)" },
        { id: 40, name: "Índice para Contratos de Locación (ICL-Ley 27.551)"}
    ];

    const [financialData, setFinancialData] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
  
    useEffect(() => {
        const fetchFinancialData = async () => {
            try {
                const storedData = localStorage.getItem("financialData");
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    const storedTime = new Date(parsedData.timestamp);
                    const currentTime = new Date();
                    // If less than a day since the last update, use stored data
                    if (currentTime - storedTime < 24 * 60 * 60 * 1000) {
                        setFinancialData(parsedData.data);
                        return;
                    }
                }
    
                const response = await fetch(`https://api.bcra.gob.ar/estadisticas/v2.0/PrincipalesVariables`);
                const responseData = await response.json();
    
                if (responseData && responseData.results && responseData.results.length > 0) {
                    const filteredData = responseData.results.filter(result => {
                        return variables.some(variable => variable.id === result.idVariable);
                    });

                    const formatDate = (dateString) => {
                        const date = new Date(dateString);
                        const day = String(date.getDate()).padStart(2, '0');
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const year = String(date.getFullYear()).slice(-2);
                        return `${day}/${month}/${year}`;
                    };
    
                    const formattedData = filteredData.map(result => {
                        return {
                            name: variables.find(variable => variable.id === result.idVariable).name,
                            fecha: formatDate(result.fecha),
                            valor: result.valor
                        };
                    });
    
                    setFinancialData(formattedData);
    
                    // Store new data in localStorage
                    localStorage.setItem("financialData", JSON.stringify({ data: formattedData, timestamp: new Date() }));
                }
    
                // Schedule next query for the next day at 13:00 hrs
                const nextDayAt13 = new Date();
                nextDayAt13.setDate(nextDayAt13.getDate() + 1);
                nextDayAt13.setHours(13, 0, 0, 0);
                const timeUntilNextDayAt13 = nextDayAt13 - new Date();
                setTimeout(fetchFinancialData, timeUntilNextDayAt13);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchFinancialData();
    }, []);

    const handleSort = (column) => {
        const isAsc = orderBy === column && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(column);
    };

    const parseDate = (dateString) => {
        if (!dateString || dateString === "N/A") {
            return null;
        }
        const parts = dateString.split("/");
        return new Date(parts[0], parts[1] - 1, parts[2]);
    };
    
    const sortedData = financialData.sort((a, b) => {
        let comparison = 0;
        if (orderBy === "name") {
            comparison = a.name.localeCompare(b.name);
        } else if (orderBy === "fecha") {
            const dateA = parseDate(a.fecha);
            const dateB = parseDate(b.fecha);
            comparison = dateA - dateB;
        } else if (orderBy === "valor") {
            comparison = parseFloat(a.valor.replace(",", ".")) - parseFloat(b.valor.replace(",", "."));
        }
        return order === "desc" ? comparison * -1 : comparison;
    });

    return (
        <div className={styles.container}>
            <div className={styles.container_header}>Datos financieros</div>
            <table className={styles.container_table}>
                <colgroup>
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "30%" }} />
                </colgroup>
                <thead className={styles.container_table_header}>
                    <tr>
                        <th className={styles.container_table_header_cell} onClick={() => handleSort("name")}>
                            <p className={styles.container_table_header_cell_first}>Nombre
                            <ArrowDown width={10} height={10} />
                            </p>
                        </th>
                        <th className={styles.container_table_header_cell} onClick={() => handleSort("fecha")}>
                            Fecha
                            <ArrowDown width={10} height={10} />
                        </th>
                        <th className={styles.container_table_header_cell} onClick={() => handleSort("valor")}>
                            Valor
                            <ArrowDown width={10} height={10} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((data, index) => (
                        <tr key={index} className={`${styles.container_table_row} ${index % 2 === 0 ? styles.container_table_row_even : ''}`}>
                            <td className={styles.container_table_row_cell}>{data.name}</td>
                            <td className={styles.container_table_row_cell}>{data.fecha || "N/A"}</td>
                            <td className={styles.container_table_row_cell}>{data.valor || "N/A"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FinancialData;


