import React, { useState } from "react";
import styles from "./PortfolioItem.module.scss";
import { ReactComponent as XIcon } from "../../../assets/svg/x.svg";
import { connect } from "react-redux";
import { deletePortfolio } from "../../../store/actions";
import { ReactComponent as TrashIcon } from "../../../assets/svg/trashIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svg/plusIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Maps from "../Maps/Maps";
import DetailsEdit from "../DetailsEdit/DetailsEdit";


const PortfolioItem = ({ portfolio, deletePortfolio, myPerson }) => {
    const [showPortfolio, setShowPortfolio] = useState(false);
    const [showItemInfo, setShowItemInfo] = useState(1);
    const [isEditing, setIsEditing] = useState(false);

    const coverImage = portfolio.portfolio_images[0].image;

    const handleShowPortfolio = () => {
        setShowPortfolio(!showPortfolio);
    }

    //Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate(); 
        return `${day}/${month}/${year}`;
      };

    //Maneja el borrado del portfolio
    const handleDeletePortfolio = () => {
        deletePortfolio(parseInt(portfolio.id))
        setTimeout(() => {
            setShowPortfolio(false)
        }, 1000)
    }

    //Manneja el estado de edicion
    const handleEdit = () => {
        setShowPortfolio(false)
        setIsEditing(true)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.container_image}>
                    <img className={styles.container_image_img} src={coverImage} alt="portfolio" />
                </div>
                <div className={styles.container_info}>
                    <div className={styles.container_info_text} onClick={() => handleShowPortfolio()}>
                        <p>{portfolio?.name}</p>
                        <div className={styles.container_info_button}>
                            <ArrowRightIcon height={12} width={12} />
                        </div>
                    </div>
                </div>
            </div>
            {showPortfolio && (
                <div className={styles.container_portfolio}>
                    <div className={styles.container_portfolio_container}>
                        <div className={styles.container_portfolio_headerContainer}>
                            <h2 className={styles.container_portfolio_info_title}>{portfolio?.name}</h2>
                            <div className={styles.container_portfolio_headerContainer_buttons}>
                                {myPerson && (
                                    <div className={styles.container_portfolio_delete} onClick={() => handleDeletePortfolio()}>
                                        <TrashIcon width={25} height={25} />
                                    </div>
                                )}
                                <div className={styles.container_portfolio_close} onClick={() => handleShowPortfolio()}>
                                    <XIcon width={18} height={18} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.container_portfolio_container_body}>
                            <div className={styles.container_portfolio_container_content}>
                                <div className={styles.container_portfolio_info}>
                                    <div className={styles.container_portfolio_info_header}>
                                        <h3 className={styles.container_portfolio_info_subTitle}>INFORMACIÓN GENERAL:</h3>
                                    </div>
                                    <div className={styles.container_portfolio_info_headers}>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 1 && styles.isActive}`} onClick={() => setShowItemInfo(1)}>Imágenes</h4>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 2 && styles.isActive}`} onClick={() => setShowItemInfo(2)}>Descripción</h4>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 3 && styles.isActive}`} onClick={() => setShowItemInfo(3)}>Zona</h4>
                                        <h4 className={`${styles.container_portfolio_info_subTitle} ${showItemInfo === 4 && styles.isActive}`} onClick={() => setShowItemInfo(4)}>Fechas</h4>
                                    </div>
                                    {showItemInfo === 1 && (
                                        <div className={styles.container_portfolio_images}>
                                            <div className={styles.container_portfolio_images_imagesContent}>
                                                <ResponsiveMasonry
                                                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                                >
                                                    <Masonry gutter="10px">
                                                        {portfolio.portfolio_images.map((image, index) => (
                                                            <img className={styles.container_portfolio_images_item_img} key={index} src={image.image} alt="portfolio" />
                                                        ))}
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </div>
                                        </div>
                                    )}
                                    {showItemInfo === 2 && (
                                        < div className={styles.container_portfolio_info_text}>
                                            <p className={styles.container_portfolio_info_subTitle_txt}>"{portfolio?.description}"</p>
                                        </div>

                                    )}
                                    {showItemInfo === 3 && (
                                        <div className={styles.container_portfolio_info_text}>
                                            <div className={styles.container_portfolio_info_map}>
                                                <Maps ciudad={portfolio?.zone} />
                                            </div>
                                        </div>

                                    )}
                                    {showItemInfo === 4 && (
                                        <div className={styles.container_portfolio_info_text}>
                                            <p className={styles.container_portfolio_info_subTitle_txt}>Inicio: {formatDate(portfolio?.startDate)}</p>
                                            <p className={styles.container_portfolio_info_subTitle_txt}>Fin: {formatDate(portfolio?.endDate)}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
}

export default connect(mapStateToProps, { deletePortfolio })(PortfolioItem);