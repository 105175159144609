import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    getPrivateProjectById,
    openTutorialModal,
    closeMainModal,
    setProjectFormStyle,
    resetProjectToEdit,
    getPostulationProjectStatus,
    validateAccessRequest,
    openNDAModal,
    getPostulations,
    setPostulationProjectId,
    getSubProjectBudgets,
    setProjectToEdit,
    deleteProyecto,
} from "../../../store/actions";
import Modal from "../../../components/Modal/Modal";
import Maps from "../../../components/UI/Maps/Maps";
import ButtonsProject from "../../UI/ButtonsProject/ButtonsProject";
import SubProjectBudgets from "../SubProjects/SubProjectBudgets";
import { ReactComponent as Faq } from "../../../assets/svg/FAQ.svg";
import { ReactComponent as Plus } from "../../../assets/svg/plus-icon.svg";
import NewProject from "../../../components/NewProject/NewProject";
import { ReactComponent as NoImage } from "../../../assets/svg/noImageIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as Check } from "../../../assets/svg/checkmark.svg";
import { ReactComponent as Close } from "../../../assets/svg/xClose.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svg/trashIcon.svg";
import Botonera from "../../Botonera/Botonera";
import { useNavigate, useParams } from "react-router-dom";
import FondoObra from "../../../assets/images/fondoObraPrivada.jpg";
import styles from "./ProyectoId.module.scss";

const ProyectoId = ({ 
    getPrivateProjectById, 
    getPostulationProjectStatus, 
    postulacionesDelUsuario,
    postulacionesDeProyecto, 
    getSubProjectBudgets,
    proyecto, 
    openTutorialModal,
    openNDAModal,
    validateAccessRequest,
    getPostulations,
    setProjectFormStyle,
    setSubProjectStyle,
    setProjectToEdit,
    setPostulationProjectId,
    deleteProyecto,
    budgets,
    userData,
     ...props }) => {
    const [isOpenProject, setModalOpenProject] = useState(false);
    const [showSubProjectInfo, setShowSubProjectInfo] = useState({});
    const [showPostulations, setShowPostulations] = useState({});
    const [editProject, setEditProject] = useState(false);
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const { id } = useParams();
   const navigate = useNavigate();
    //Definimos si es proycto propio o de otro usuario
    const isOwnProject = proyecto?.user?.id === userData?.id;

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 768);
        setMobileStylesFromParent(windowWidth < 768);
      };
    
      // Ejecutar handleResize al cargar el componente
      useEffect(() => {
        handleResize();
      }, []);
    
      // Agregar un event listener para el cambio de tamaño de la ventana
      useEffect(() => {
        window.addEventListener("resize", handleResize);
    
        // Limpiar el event listener al desmontar el componente
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    //Formateamos fechas
 
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate(); 
        return `${day}/${month}/${year}`;
      };

    useEffect(() => {
        getPrivateProjectById(id);
        getPostulationProjectStatus(parseInt(id));
        getPostulations(userData.id);
    }, []);

    //Muestra la info de los subproyectos
    const toggleSubProjectInfo = (index) => {
        setShowSubProjectInfo((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    //Muestra las postulaciones
    const togglePostulations = (index) => {
        setShowPostulations((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    //Maneja la solicitud de acceso al proyecto
    const handleAccessRequest = (permisoId, status, solicitudType) => {

        validateAccessRequest(permisoId, status, solicitudType);
        setTimeout(() => {
        getPostulationProjectStatus(parseInt(id));
        }, 3000);
    }

    //Abre el modal de Postulacion
    const openPostulationModal = () => {
        setPostulationProjectId(proyecto?.id);
        openNDAModal(true);
    }

    //Define que boton mostrar
    const isPostulationSent = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "SEND");
  const isPostulationAllowed = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "ALLOWED")
  const isPostulationDenied = postulacionesDelUsuario.find(postulacion => postulacion.proyecto?.id === proyecto?.id && postulacion.status === "NEGATED")

  // Estado actual de la postulación del usuario
  let userPostulationStatus;
  if (isPostulationSent) {
      userPostulationStatus = isPostulationSent.status;
  } else if (isPostulationAllowed) {
      userPostulationStatus = isPostulationAllowed.status;
  } else if (isPostulationDenied) {
      userPostulationStatus = isPostulationDenied.status;
  }

  //Muestra el PopUp de edicion de proyecto 
    const handleEditProject = () => {
        setEditProject(true);
        setProjectToEdit(parseInt(proyecto.id));
    }

    //Borrar el proyecto 
    const handleDeleteProject = () => {
        deleteProyecto(parseInt(proyecto.id), navigate, true);
    }

    //Volver atrás
    const goBack = () => {
        navigate.goBack();
    }

    //Maneja la redirección al perfil del creador del proyecto
    const handleUserRedirect = () => {
        const userId = proyecto?.user?.id;
        const userFullName = proyecto?.user?.type === "COMPANY" ? proyecto?.user.razon_social : proyecto?.user?.name + " " + proyecto?.user?.last_name;
        navigate(`/comunidad-edify/${userId}/${userFullName}`);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainTitle}>

                <div className={styles.mainTitle_background}>
                    <img src={FondoObra} alt="Fondo Obra" className={styles.mainTitle_img} />
                    <div className={styles.mainTitle_overlay}></div>
                    
                </div> 
            </div>
            <section className={styles.mainContent}>
                <h2 className={styles.mainContent_title}>PROYECTO: "{proyecto?.name}"</h2>
                {isOwnProject && <div className={styles.mainContent_buttons}>
                    <button title="Eliminar proyecto" className={styles.mainContent_buttons_delete} onClick={() => handleDeleteProject()}><TrashIcon width={15} height={15}/></button>
                    <button className={styles.mainContent_buttons_postularme} onClick={() => handleEditProject()}>Editar proyecto</button>                
                </div>}
                {!isOwnProject && !isPostulationSent && !isPostulationAllowed && !isPostulationDenied &&
                <div className={styles.mainContent_buttons}>
                    <button className={styles.mainContent_buttons_postularme} onClick={() => openPostulationModal()}>Quiero cotizar</button>
                </div> }
                {!isOwnProject && isPostulationSent &&
                <div className={styles.mainContent_buttons_txtContainer}>
                <p className={styles.mainContent_buttons_solicitudEnviada}>Tu postulacion esta pendiente de aprobación</p>
                </div>}
                {!isOwnProject && isPostulationAllowed &&
                <div className={styles.mainContent_buttons_txtContainer}>
                 <p className={styles.mainContent_buttons_solicitudAprobada}>Tu postulacion fue aceptada</p>
                 </div>}
                {!isOwnProject && isPostulationDenied && 
                <div className={styles.mainContent_buttons_txtContainer}>
                <p className={styles.mainContent_buttons_solicitudRechazada}>Tu postulacion fue rechazada</p>
                </div>}
                <div className={styles.mainContent_cards}>
                    <div className={styles.mainContent_cards_datos}>
                        <h3 className={styles.mainContent_cards_datos_title}>INFORMACIÓN GENERAL</h3>
                        <div className={styles.mainContent_cards_datos_container}>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Ubicacion:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{proyecto?.zone}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Fecha de inicio:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{formatDate(proyecto?.startDate)}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Fecha de finalizacion:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{formatDate(proyecto?.endDate)}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Descripcion:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{proyecto?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainContent_cards_map}>
                        <Maps ciudad={proyecto?.zone} />
                    </div>
                    <div className={styles.mainContent_cards_subProyectos}>
                        <h3 className={styles.mainContent_cards_subProyectos_title}>RUBROS A COTIZAR</h3>
                        <div className={styles.mainContent_cards_subProyectos_container}>
                            {proyecto?.subproyecto && proyecto?.subproyecto.length > 0 ? proyecto?.subproyecto.map((subProject, index) => {
                                return <div className={styles.mainContent_cards_subProyectos_container_subProject} key={index} >
                                    <div className={styles.mainContent_cards_subProyectos_container_subProject_header} onClick={() => toggleSubProjectInfo(index)}>
                                        <p className={styles.mainContent_cards_subProyectos_container_subProject_title}>{subProject.name}</p>
                                        <div className={styles.mainContent_cards_subProyectos_container_subProject_arrow}>
                                            <ArrowIcon
                                                className={showSubProjectInfo[index] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                            />
                                        </div>
                                    </div>
                                    {showSubProjectInfo[index] &&
                                        <div className={styles.mainContent_cards_subProyectos_container_subProject_info}>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Estado:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_value}>{subProject.status === "OPEN" && "Abierto"}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Inicio de recepción de presupuestos:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_value}>{formatDate(subProject.startDate)}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Fin de recepción de presupuestos:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_value}>{formatDate(subProject.endDate) === "" ? formatDate(proyecto?.endDate) : formatDate(subProject.endDate)}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Descripcion:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_description}>{subProject?.description === "undefined" ? "No especifica" : subProject.description}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Documentacion:</p>
                                                <ButtonsProject mainProjectId={proyecto?.id} subproyecto={subProject} isAllowed={userPostulationStatus} isMyProject={isOwnProject} type={"isProject"} />
                                            </div>
                                        </div>}
                                </div>
                            }) : <p className={styles.mainContent_cards_subProyectos_container_noResults}>No hay sub proyectos</p>}
                        </div>
                    </div>
                    <div className={styles.mainContent_cards_images}>
                        <h3 className={styles.mainContent_cards_images_title}>IMÁGENES</h3>
                        <div className={styles.mainContent_cards_images_container}>
                            {proyecto?.proyecto_images && proyecto?.proyecto_images.length > 0 ? proyecto?.proyecto_images.map((image, index) => {
                                return <img src={image.image} alt={`Imagen ${index}`} key={index} className={styles.mainContent_cards_images_container_image} />
                            }
                            ) :
                                <div className={styles.mainContent_cards_images_container_noImage}>
                                    <NoImage height={50} width={50} />
                                    <p className={styles.mainContent_cards_images_container_noImage_txt}>No hay imágenes</p>
                                </div>}
                        </div>
                    </div>
                    {isOwnProject &&
                    <div className={styles.mainContent_cards_cotizaciones}>
                    <h3 className={styles.mainContent_cards_cotizaciones_title}>
                        COTIZACIONES RECIBIDAS
                    </h3>
                    <div className={styles.mainContent_cards_cotizaciones_container}>
                    {proyecto?.subproyecto && proyecto?.subproyecto.length > 0 ? proyecto?.subproyecto.map((subProject, index) => {
                        return <SubProjectBudgets subproject={subProject} key={index}  mainProject={proyecto} />
                    }
                    ) : <p className={styles.mainContent_cards_cotizaciones_container_noResults}>No hay cotizaciones</p>}
                    </div>
                </div>}
                    {isOwnProject &&
                        <div className={styles.mainContent_cards_postulaciones}>
                            <h3 className={styles.mainContent_cards_postulaciones_title}>
                                POSTULACIONES
                            </h3>
                            <div className={styles.mainContent_cards_postulaciones_container}>
                                <div className={styles.mainContent_cards_postulaciones_container_header}>
                                    Recibidas
                                    <div className={styles.mainContent_cards_postulaciones_container_header_countContainer}>
                                    {postulacionesDeProyecto.filter(postulation => postulation.status === "SEND" ).length > 0 &&
                                        <span className={styles.mainContent_cards_postulaciones_container_header_count}>{postulacionesDeProyecto.filter(postulation => postulation.status === "SEND").length}</span>
                                    }
                                        <ArrowIcon
                                            className={showPostulations[0] ? styles.arrowIconDown : styles.arrowIconRight}
                                            height={15}
                                            width={15}
                                            onClick={() => togglePostulations(0)}
                                        />
                                    </div>
                                </div>
                                {showPostulations[0] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                    return postulation.status === "SEND" &&
                                        <div className={styles.mainContent_cards_postulaciones_container_userPostulation} key={index}>
                                            <div className={styles.mainContent_cards_postulaciones_container_user}>
                                                <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainContent_cards_postulaciones_container_user_image} />
                                                <p className={styles.mainContent_cards_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                    postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_postulaciones_container_status}>
                                                <p className={styles.mainContent_cards_postulaciones_container_status_accept} title="Aceptar">
                                                    <Check height={10} width={10} onClick={() => handleAccessRequest(parseInt(postulation.id), "ALLOWED", "POSTULACION_ACCEPTED")} />
                                                </p>
                                                <p className={styles.mainContent_cards_postulaciones_container_status_deny} title="Rechazar">
                                                    <Close height={10} width={10} onClick={() => handleAccessRequest(parseInt(postulation.id), "NEGATED", "POSTULACION_NEGATED")} />
                                                </p>
                                            </div>
                                        </div>
                                }
                                )}
                                <div className={styles.mainContent_cards_postulaciones_container_header}>
                                    Aceptadas
                                    <ArrowIcon
                                        className={showPostulations[1] ? styles.arrowIconDown : styles.arrowIconRight}
                                        height={15}
                                        width={15}
                                        onClick={() => togglePostulations(1)}
                                    />
                                </div>
                                {showPostulations[1] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                    return postulation.status === "ALLOWED" &&
                                        <div className={styles.mainContent_cards_postulaciones_container_userPostulation} key={index}>
                                            <div className={styles.mainContent_cards_postulaciones_container_user}>
                                                <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainContent_cards_postulaciones_container_user_image} />
                                                <p className={styles.mainContent_cards_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                    postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_postulaciones_container_status}>
                                                <p className={styles.mainContent_cards_postulaciones_container_status_acceptedTxt}>Aceptada</p>
                                            </div>
                                        </div>
                                }
                                )}
                            </div>
                            <div className={styles.mainContent_cards_postulaciones_container_header}>
                                Rechazadas
                                <ArrowIcon
                                    className={showPostulations[2] ? styles.arrowIconDown : styles.arrowIconRight}
                                    height={15}
                                    width={15}
                                    onClick={() => togglePostulations(2)}
                                />
                            </div>
                            {showPostulations[2] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                return postulation.status === "DENIED" &&
                                    <div className={styles.mainContent_cards_postulaciones_container_userPostulation} key={index}>
                                        <div className={styles.mainContent_cards_postulaciones_container_user}>
                                            <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainContent_cards_postulaciones_container_user_image} />
                                            <p className={styles.mainContent_cards_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                        </div>
                                        <div className={styles.mainContent_cards_postulaciones_container_status}>
                                            <p className={styles.mainContent_cards_postulaciones_container_status_deniedTxt}>Rechazada</p>
                                        </div>
                                    </div>
                            }
                            )}
                        </div>
                    }
                    <div className={styles.mainContent_cards_user}>
                        <h3 className={styles.mainContent_cards_user_title}>
                            CREADOR
                        </h3>
                        <div className={styles.mainContent_cards_user_container} onClick={() => handleUserRedirect()}>
                            <div className={styles.mainContent_cards_user_container_user}>
                                <img src={proyecto?.user?.image_profile} alt="Imagen de perfil" className={styles.mainContent_cards_user_container_user_image} />
                                <p className={styles.mainContent_cards_user_container_user_name}>{proyecto?.user?.type === "COMPANY" ?
                                    proyecto?.user?.razon_social : proyecto?.user?.name + " " + proyecto?.user?.last_name}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section >
            <Modal isOpen={isOpenProject}>
                <NewProject closeModal={() => setModalOpenProject(false)} />
            </Modal>
            {editProject && 
            <div className={styles.editBackDrop}>
                <NewProject
                        isEditing
                        handleClose={() => setEditProject(false)}
                        postulationProject={proyecto}
                        closeModal={() => setEditProject(false)}
                      />
            </div>}
            {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
          null}
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        proyecto: state.profile.proyectoPrivadoPorId,
        postulacionesDeProyecto: state.profile.postulationsInProject,
        postulacionesDelUsuario: state.profile.postulations,
        showEditProject: state.profile.editProjectsShow,
    };
}

export default connect(mapStateToProps, {
    getPrivateProjectById,
    openTutorialModal,
    closeMainModal,
    setProjectFormStyle,
    resetProjectToEdit,
    getPostulationProjectStatus,
    validateAccessRequest,
    openNDAModal,
    getPostulations,
    setPostulationProjectId,
    getSubProjectBudgets,
    setProjectToEdit,
    deleteProyecto,
})(ProyectoId);
