import React, { useMemo, useEffect, useState } from "react";
import styles from "./MisPostulaciones.module.scss";
import { connect } from "react-redux";
import { getPostulations } from "../../../store/actions/index";
import { useNavigate } from "react-router-dom";
import MainInfoItem from "../../MainInfoItem/MainInfoItem";
import { motion, AnimatePresence } from "framer-motion";
import queryString from "query-string";
import EmptyStateMyPostulations from "../../UI/EmptyState/EmptyStateMyPostulations";
import usePremiumCheck from "../../../hooks/usePremiumCheck"

const MisPostulaciones = (props) => {
 const navigate = useNavigate();
  const premiumCheck = usePremiumCheck();
  const { viewmore } = queryString.parse(navigate.location?.search);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(
    !!viewmore || props.fromPage
  );
  useEffect(() => {
    props.getPostulations(props.perfilAjeno);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [props.perfilAjeno]);

  const orderedPostulations = useMemo(() => {
    if (props.postulaciones && props.postulaciones.length) {
      let orderedPostulaciones = props.postulaciones.reverse();
      if (dropdownOpen && props.fromPage) {
        return orderedPostulaciones;
      } else {
        return [orderedPostulaciones[0]];
      }
    }
  }, [props.postulaciones, dropdownOpen]);


  const handleOnClick = () => {
    if (premiumCheck) {
      navigate("/proyectosprivados");
    }
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        className={styles.postulacionContainer}
      >
          {props.fromPage && (
        orderedPostulations && orderedPostulations.length > 0 ? ( 
          <div className={styles.MisPostulaciones}>
          {orderedPostulations.map((postulacion, index) => {
            if (props.fromPage) {
              return (
                <MainInfoItem
                  key={index}
                  item={postulacion}
                  isPostulation
                  defaultDropdownOpen={viewmore}
                  fromPage={props.fromPage}
                  perfilAjeno={props.perfilAjeno}
                  type="postulations"
                />
              );
            }
          }
          )}
          </div>
        ) : (
          <>
            <div className={styles.emptyState} onClick={premiumCheck(() => handleOnClick())}>
          <EmptyStateMyPostulations />           
              <h3>No hay postulaciones</h3>
              <h3>¿Querés postularte en un proyecto?</h3>
          </div>
          </>
        )
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => {
  return {
    postulaciones: state.profile.postulations,
    isLoaded: state.general.isLoaded,
  };
};

export default connect(mapStateToProps, { getPostulations })(MisPostulaciones);
