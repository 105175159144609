import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Input from "../UI/Forms/Input/Input";
import Button from "../UI/Forms/Button/Button";
import FileUpload from "../FileUpload/FileUpload";
import { connect } from "react-redux";
import {
  setEmpresa, openMainModal, getSkills,
  sendSkills,
  getUserSkill,
  addSkill,
  removeSkill
} from "../../store/actions/index";
import styles from "./FormularioEmpresa.module.scss";
import Lottie from "react-lottie";
import successData from "../../assets/lotties/success.json";
import { fireAnalytics } from "../../utils/firebase.js";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { useNavigate } from "react-router-dom";

const FormularioEmpresa = ({
  companyName,
  rubro,
  cuit,
  tel,
  address,
  webSite,
  file,
  setEmpresa,
  getSkills,
  sendSkills,
  getUserSkill,
  addSkill,
  removeSkill,
  skills,
  userSkills,
  skillContent,
}) => {
  const [showSkillsSuggestions, setShowSkillsSuggestions] = useState(false);
  const [showSkillsList, setShowSkillsList] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fireAnalytics("ob_step_2", { category: "navigation" });
    getSkills();
  }, []);


  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const webSiteRegex = /(^[a-zA-Z_-]{3})([.]{1})([a-zA-Z0-9_.+-]+)([.]{1})([a-zA-Z_-]+)/;
  const telRegex = /[0-9+]/;

  const companySchema = Yup.object().shape({
    companyName: Yup.string().required("El campo es requerido."),
    tel: Yup.string()
      .matches(telRegex, "Introduzca un formato válido")
      .min(7, "Ingrese un telefono válido")
      .max(20, "Ingrese un telefono válido"),

    address: Yup.string(),

    webSite: Yup.string().matches(webSiteRegex, "Introduzca un formato válido"),
  });

const ButtonContainer = styled.div`
display: flex;
a {
  margin-right: 10px;
}
`;

const matchedSkills = skills?.filter((skill) => skill.name.toLowerCase().includes(inputValue.toLowerCase())) || [];

  const handleShowSuggestions = (value) => {
    if (value.length < 3) {
      setShowSkillsSuggestions(false);
    } else if (value.length >= 3) {
      setShowSkillsSuggestions(true);
    }
    setInputValue(value);
  };

  const handleAddSkill = (id) => {
    addSkill(id);
    setShowSkillsSuggestions(false);
    setInputValue("");
    setShowSkillsList(false);

  };

  return (
    <>
      <Formik
        initialValues={{
          companyName,
          field: "",
          tel,
          address,
          webSite,
        }}
        onSubmit={(values) => {
          setEmpresa(values, file, navigate);
          fireAnalytics("ob_step_2_company", { category: "onboarding" });
        }}
        validationSchema={companySchema}
        validateOnMount={true}
      >
        {({ handleSubmit, isValid, values, touched, errors }) => (
          <>
            <Form onSubmit={handleSubmit} className={styles.formContainer}>
              <FileUpload file={file} userType="company" />
              <div className={styles.formGroup}>
                <Field
                  placeholder="Nombre de la empresa*"
                  type="text"
                  name="companyName"
                  component={Input}
                  className={`${errors.companyName && "error"} ${values.companyName !== "" && !errors.companyName ? "isValid" : ""}`}
                />
                {errors.companyName && <div className={styles.formGroup_errorMsg}>{errors.companyName}</div>}
                {values.companyName !== "" && !errors.companyName ? (
                  <div className={styles.lottieContainer}>
                  <Lottie
                    options={{
                      animationData: successData,
                      ...defaultOptions,
                    }}
                    height={35}
                    width={35}
                  />
                  </div>
                ) : null}
              </div>
              <div className={styles.inputRow}>
              <div className={styles.formGroup}>
                  <Field
                    placeholder="Zona de trabajo"
                    type="text"
                    name="address"
                    component={Input}
                    className={`${errors.address && "error"} ${values.address !== "" && !errors.address ? "isValid" : ""}`}
                  />
                  {errors.address && <div className={styles.formGroup_errorMsg}>{errors.address}</div>}
                  {values.address !== "" && !errors.address ? (
                    <div className={styles.lottieContainer}> 
                    <Lottie
                      options={{
                        animationData: successData,
                        ...defaultOptions,
                      }}
                      height={35}
                      width={35}
                    />
                    </div>
                  ) : null}
                </div> 
                <div className={styles.formGroup}>
                  <Field
                    placeholder="Teléfono"
                    type="number"
                    name="tel"
                    component={Input}
                    className={`${errors.tel && "error"} ${values.tel !== "" && !errors.tel ? "isValid" : ""}`}
                  />
                  {errors.tel && <div className={styles.formGroup_errorMsg}>{errors.tel}</div>}
                  {values.tel !== "" && !errors.tel ? (
                    <div className={styles.lottieContainer}>
                    <Lottie
                      options={{
                        animationData: successData,
                        ...defaultOptions,
                      }}
                      height={35}
                      width={35}
                    />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.formGroup}>
                <Field
                  placeholder="Página Web: www.example.com"
                  type="text"
                  name="webSite"
                  component={Input}
                  className={`${errors.webSite && "error"} ${values.webSite !== "" && !errors.webSite ? "isValid" : ""}`}
                />
                {errors.webSite && <div className={styles.formGroup_errorMsg}>{errors.webSite}</div>}
                {values.webSite !== "" && !errors.webSite ? (
                  <div className={styles.lottieContainer}>
                  <Lottie
                    options={{
                      animationData: successData,
                      ...defaultOptions,
                    }}
                    height={35}
                    width={35}
                  />
                  </div>
                ) : null}
              </div>
              <div className={styles.formGroup_lastOne}>
                <div className={styles.skillsContainer}>
                    {userSkills?.length > 0 && (
                      <div className={styles.skillsContainer_containerSelected}>
                        <div className={styles.skillsContainer_selected}>
                          {userSkills.map((skill) => {
                            return <div className={styles.skillsContainer_skillSelected} key={skill.id} onClick={() => removeSkill(skill.id)}>{skill.name} X</div>
                          })}
                        </div>
                      </div>
                    )}
                    <div className={`${styles.skillsContainer_inputContainer} ${userSkills.length === 0 && styles.inputError}`}>
                      <Field
                        className={styles.skillsContainer_input}
                        type="text"
                        placeholder="Ingrese al menos 3 caractéres(por ej: carpinterias)"
                        value={inputValue}
                        onChange={(e) => handleShowSuggestions(e.target.value)}
                      />
                      <ArrowDownIcon
                        width={15}
                        title="Ver lista completa de rubros"
                        height={15}
                        className={`${styles.arrow} ${showSkillsList ? styles.arrowIconUp : styles.arrowIconDown}`}
                        onClick={() => setShowSkillsList(!showSkillsList)} />
                        {userSkills.length > 0 &&
                        <div className={styles.lottieContainer_input}>
                         <Lottie
                          options={{
                            animationData: successData,
                            ...defaultOptions,
                          }}
                          height={35}
                          width={35}
                        />
                      </div>}
                    </div>
                    {showSkillsList && (
                      <div className={styles.skillsContainer_list}>
                        {skills?.map((skill) => {
                          if (skill.name.toLowerCase().includes(inputValue.toLowerCase())) {
                            return <div className={styles.skillsContainer_list_item} key={skill.id} onClick={() => handleAddSkill(skill.id)}>{skill.name}</div>
                          }
                        })}
                      </div>
                    )}
                    {showSkillsSuggestions && (
                      <div className={styles.skillsContainer_list}>
                        {matchedSkills.map((skill) => {
                          return <div className={styles.skillsContainer_list_item} key={skill.id} onClick={() => handleAddSkill(skill.id)}>{skill.name}</div>
                        })}
                      </div>
                    )}
                  </div>
                  {userSkills.length === 0 && <div className={styles.formGroup_errorMsg}>Seleccioná al menos 1 rubro</div>
                  } 
              </div>
               
              <ButtonContainer>
                <Button
                  colored="white"
                  typeBtn="link"
                  to="select-type"
                  onClick={() => fireAnalytics("ob_step_2_back", { category: "onboarding" })}                
                >
                  Volver
                </Button>

                <button className={styles.btn} disabled={!isValid || values.companyName === "" || userSkills?.length === 0 } type="submit" onClick={() => {
                    fireAnalytics("ob_step_2_profesional", {
                      category: "onboarding",
                    });
                    sendSkills(userSkills, navigate)
                  }
                  }>
                  Siguiente
                </button>
              </ButtonContainer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyName: state.register.empresaForm.companyName,
    rubro: state.register.empresaForm.field,
    cuit: state.register.empresaForm.cuit,
    tel: state.register.empresaForm.tel,
    address: state.register.empresaForm.address,
    webSite: state.register.empresaForm.webSite,
    file: state.register.file,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
    skillContent: state.register.skillContent,
  };
};

export default connect(mapStateToProps, {
  setEmpresa, openMainModal, getSkills,
  sendSkills,
  getUserSkill,
  addSkill,
  removeSkill
})(FormularioEmpresa);

