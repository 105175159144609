import React from "react";
import { connect } from "react-redux";
import styles from "./UserData.module.scss";
import { closeAction } from "../../store/actions";
import { motion, AnimatePresence } from "framer-motion";
import { fireAnalytics } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ProjectProfileData = (props) => {
 const navigate = useNavigate();
  let userProject = props?.userProject ? props.userProject : [];
  const user_type_person = props.item?.user?.type === "PERSON";
  const userName = props?.item?.user?.type === "COMPANY" ? props?.item?.user?.razon_social : `${props?.item?.user?.name} ${props?.item?.user?.last_name}`;
  const userItem = props?.item?.user;

  const handleClick = () => {
    navigate(`/comunidad-edify/${props.userId}/${userName}`);
    typeof props.onClick === "function" && props.onClick();
    if (props.type === "solicitud") {
      fireAnalytics("view_nda_profile", { category: "linking" });
    } else {
      fireAnalytics("dsh_view_profile", { category: "dashboard" });
    }
  };

  const projectUser = props?.item?.user || props?.userProject;
  const fecha = moment(projectUser.createdAt);
  const fechaFormateada = fecha.format("DD/MM/YY [a las] h:mm A");
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: "auto" }}
        exit={{ height: 0 }}
      >
        <div
          onClick={handleClick}
          className={`${styles.proyectProfile} ${
            props.type === "solicitud" || props.type === "comment"
              ? styles.solicitudes
              : ""
          }`}
        >
          {props.usuario || userProject ? (
            <>
              <div className={styles.responsiveRow}>
                <div className={styles.userInfo}>
                  <img
                    src={
                      props.usuarioImage
                        ? props.usuarioImage
                        : userProject.image_profile
                    }
                    alt=""
                  />
                  <div className={styles.contenedor}>
                    <div className={styles.namecompleteContainer}>
                      {props?.type !== "comment" ? (
                        <div className={styles.namecomplete}>
                          {" "}
                          {projectUser.type !== "PERSON"
                            ? projectUser.razon_social
                            : `${projectUser.name} ${projectUser.last_name}`}{" "}
                        </div>
                      ) : (
                        <>
                          {projectUser.type === "PERSON" ? (
                            <>
                              <div className={styles.upInfo}>
                                {projectUser.name}
                              </div>
                              <div className={styles.downInfo}>
                                {projectUser.last_name}
                              </div>
                            </>
                          ) : (
                            <div className={styles.downInfo}>
                              {projectUser.razon_social}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className={styles.userInfoDos}>
                      <div className={styles.downInfoComment}>
                        {props.usuarioActivity ? (
                          <>{props.usuarioActivity}</>
                        ) : (
                          <>
                            {user_type_person
                              ? userItem?.profession !== "undefined"
                                ? userItem.profession
                                : ""
                              : userItem?.rubro !== "undefined"
                              ? userItem?.rubro
                              : ""}
                          </>
                        )}
                        {props.type === "comment" && (
                          <div className={styles.commentAndDateContainer}>
                            {userProject.comment ? userProject.comment : ""}
                            <div className={styles.fechaFormateada}>
                              {userProject.createdAt
                                ? `enviado el  ${fechaFormateada}`
                                : ""}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>            
            </>
          ) : (
            <div>
              No se puede cargar la informacion del usuario en este momento.
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => {
  return {
    modalOpen: state.general.recoverOpen,
  };
};

export default connect(mapStateToProps, { closeAction })(
  ProjectProfileData
);
