import React, { useState } from "react";
import styles from "./SubProyectosWrapper.module.scss";
import SubProyectoItem from "./SubProyectoItem/SubProyectoItem";
import { connect } from "react-redux";

const SubProyectosWrapper = ({ isEditing, subprojectsEdit, type, subProjects, subQuotations, subProjectsToEdit, ...props }) => {
  const [showProyects, showProyectsHandler] = useState(false);

let items = isEditing ? (subProjectsToEdit || subProjects) : (type === 'subQuotations' ? subQuotations : (subProjects));

return (
    <div className={styles.container}>
      <div className={`${styles.wrapper} ${showProyects || items.length > 0 ? styles.active : ""}`}>
        <h5 className={styles.wrapper_headerTitle}>RUBROS A COTIZAR EN EL PROYECTO</h5>
        {items.length > 0 ? (
          items.map((item, index) => {
            return <SubProyectoItem subproject={item} key={index} index={index} type={type} isEditing={isEditing}/>;
          })
        ) : (
          <p>¡No hay rubros a cotizar en este proyecto!</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subProjects: state.profile.sendSubprojects,
    subQuotations: state.profile.sendSubQuotations,
    subProjectsToEdit: state.profile.projectToEdit.subproyecto,
  };
};

export default connect(mapStateToProps)(SubProyectosWrapper);
