import React, { useEffect } from "react";
import { connect } from "react-redux";
import RegisterSteps from "../RegisterStepsPage";
import PersonaForm from "../../../components/FormularioPersona/FormularioPersona";
import EmpresaForm from "../../../components/FormularioEmpresa/FormularioEmpresa";
import styles from "./RegisterForm.module.scss";
import InitialWrapper from "../../../components/Layout/InitialWrapper/InitialWrapper";
import { AnimatePresence } from "framer-motion";
import { fireAnalytics } from "../../../utils/firebase.js";
import { CHANGE_TYPE } from "../../../store/actions/types";

const RegisterForm = ({ type, user, onChangeHandler, ...props }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    fireAnalytics("ob_step_2", { category: "navigation" });
  }, []);

  const form = type === "EMPRESA" ? <EmpresaForm {...props}/> : <PersonaForm {...props}/>;

  return (
    <div className={styles.RegisterFormContainer}>
      <InitialWrapper>
        <h1 className={styles.title}>
          Datos personales
          <br />
        </h1>
        <h3 className={styles.formTitle}>Paso 2</h3>
        <AnimatePresence>{form}</AnimatePresence>
        <RegisterSteps stepCount={2} progress={"40%"} />
      </InitialWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.register.type,
    user: state.general.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeHandler: (tipo) => dispatch({ type: CHANGE_TYPE, payload: tipo }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);