import React, { useEffect, useState, useRef } from "react";
import styles from "./PerfilMain.module.scss";
import EmptyState from "../../UI/EmptyState/EmptyState";
import { getPortfolio } from "../../../store/actions/registerActions";
import { testDescriptionEdit } from "../../../store/actions/profileActions";
import { userInfoNavStyle } from "../../../store/actions";
import styled from "styled-components";
import { connect } from "react-redux";
import MainInfoItem from "../../MainInfoItem/MainInfoItem";
import NuevoPortfolio from "../MiPortfolio/NuevoPortfolio";
import ProfileEditModal from "../../UI/ProfileEditModal/ProfileEditModal";
import { ReactComponent as FacebookIcon } from "../../../assets/svg/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/svg/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/svg/LinkedIN.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/svg/twitterSvg.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg/arrowDownIcon.svg";
import Botonera from "../../../components/Botonera/Botonera";
import Reputacion from "../Reputacion/Reputacion";
import Star from "../../../assets/images/star.png";
import { ReactComponent as WhatsAppIcon } from "../../../assets/svg/whatsAppIcon.svg";
import plus from "../../../assets/images/+icono.png";
import Skills from "../Skills/Skills";
import useGuestAccountCheck from "../../../hooks/useGuestAccountCheck";
import UserInfo from "../../UI/UserInfo/UserInfo";
import ComentarioMain from "../../../components/Perfil/PerfilMain/ComentarioMain";
import MisProyectosNew from "../../Dashboard/MisProyectos/MisProyectosNew";
import MisPostulaciones from "../../Dashboard/MisPostulaciones/MisPostulaciones";
import MisCotizaciones from "../../Dashboard/MisCotizaciones/MisCotizaciones";
import MiSuscripcion from "./MiSuscripcion";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";
import Maps from "../../UI/Maps/Maps";


const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 90px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 100px;
  }
`;

const PerfilMain = ({
  userId,
  userData,
  getPortfolio,
  myPerson,
  portfolios,
  perfilAjeno,
  profileVisualizerData,
  openMainModal,
  closeMainModal,
  testDescriptionEdit,
  userInfoNavStyle,
  ...props
}) => {
  let isVisualizer = userData.id !== perfilAjeno ? true : false;
  const [createNewPortfolio, setCreateNewPortfolio] = useState(false);
  const [isCreatingNewPortfolio, setIsCreatingNewPortfolio] = useState(false);
  const [isActive, setIsActive] = useState(!myPerson ? "Resumen" : localStorage.getItem('isActive') || "Resumen");
  const [isLoading, setIsLoading] = useState(false);
  const [resumenSectionActive, setResumenSectionActive] = useState("rubros");
  const [isEditing, setIsEditing] = useState(false);
  const [visibleItems, setVisibleItems] = useState(8);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  useEffect(() => {
    handleResize();
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    scrollToTop();

    return () => {
      window.removeEventListener('scroll', scrollToTop);
    };
  }, []);


  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Extraemos datos del perfil a renderizar
  const {
    linkedin,
    instagram,
    facebook,
    twitter,
    phone,
    address,
    profession,
    type,
    description
  } = myPerson ? userData : profileVisualizerData;

  const misProductosRef = useRef(null);
  const comentariosRef = useRef(null);
  const obrasRef = useRef(null);
  const postulacionesRef = useRef(null);
  const cotizacionesRef = useRef(null);
  const resumenRef = useRef(null);
  const configuracionesRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 140,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const resumen = resumenRef?.current?.offsetTop;
      const misProductos = misProductosRef?.current?.offsetTop;
      const comentarios = comentariosRef?.current?.offsetTop;
      const obras = obrasRef?.current?.offsetTop;
      const postulaciones = postulacionesRef?.current?.offsetTop;
      const cotizaciones = cotizacionesRef?.current?.offsetTop;
      const configuraciones = configuracionesRef?.current?.offsetTop;
      const scrollPosition = window.scrollY + 120;

      if (scrollPosition >= resumen && scrollPosition < misProductos) {
        userInfoNavStyle(false);
        setIsActive("Resumen");
      } else if (scrollPosition >= misProductos && scrollPosition < comentarios) {
        userInfoNavStyle(true)
        setIsActive("Mis productos");
      } else if (scrollPosition >= comentarios && scrollPosition < obras) {
        setIsActive("Comentarios");
      } else if (scrollPosition >= obras && scrollPosition < postulaciones) {
        setIsActive("Obras privadas");
      } else if (scrollPosition >= postulaciones && scrollPosition < cotizaciones) {
        setIsActive("Postulaciones");
      } else if (scrollPosition >= cotizaciones && scrollPosition < configuraciones) {
        setIsActive("Cotizaciones");
      } else if (scrollPosition >= configuraciones) {
        setIsActive("Configuraciones");
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  //Maneja la cantidad de portfolios a mostrar 
  const handleToggleVisibility = () => {
    setVisibleItems(prevVisibleItems => {
      if (prevVisibleItems === portfolios.length) {
        return 4; 
      } else {
        return portfolios.length; 
      }
    });
  };

  const guestAccountCheck = useGuestAccountCheck();

  useEffect(() => {
    getPortfolio(perfilAjeno);
  }, [perfilAjeno, getPortfolio]);

  useEffect(() => {
    handleNewPortfolioClick(false);
  }, [props.creatingPortfolio]);

  const handleNewPortfolioClick = (value) => {
    setIsCreatingNewPortfolio(true);
    setCreateNewPortfolio(value);
  };

  useEffect(() => {
    if (isLoading)
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
  }, [isLoading]);

  useEffect(() => {
    localStorage.setItem('isActive', isActive);
  }, [isActive]);


  return (
    <Container>
      {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> : null}
      <UserInfo fromPage setIsEditing={setIsEditing} userData={perfilAjeno} profileVisualizerData={profileVisualizerData} myPerson={myPerson} />
      <div className={styles.sectionDivider}>
        <ul className={styles.sectionDivider_list}>
          <li className={`${styles.sectionDivider_list_item} ${isActive === "Resumen" ? styles.isActive : ""}`} onClick={() => { setIsActive("Resumen"); scrollToRef(resumenRef) }}>
            <p>Resúmen</p>
          </li>
          <li className={`${styles.sectionDivider_list_item} ${isActive === "Mis productos" ? styles.isActive : ""}`} onClick={() => { setIsActive("Mis productos"); scrollToRef(misProductosRef) }}>
            <p>Productos - servicios</p>
          </li>
          <li className={`${styles.sectionDivider_list_item} ${isActive === "Comentarios" ? styles.isActive : ""}`} onClick={() => { setIsActive("Comentarios"); scrollToRef(comentariosRef) }}>
            <p>Calificaciones</p>
          </li>
          {myPerson && (
            <>
              <li className={`${styles.sectionDivider_list_item} ${isActive === "Obras privadas" ? styles.isActive : ""}`} onClick={() => { setIsActive("Obras privadas"); scrollToRef(obrasRef) }}>
                <p>Obras publicadas</p>
              </li>
              <li className={`${styles.sectionDivider_list_item} ${isActive === "Postulaciones" ? styles.isActive : ""}`} onClick={() => { setIsActive("Postulaciones"); scrollToRef(postulacionesRef) }}>
                <p>Proyectos postulados</p>
              </li>
              <li className={`${styles.sectionDivider_list_item} ${isActive === "Cotizaciones" ? styles.isActive : ""}`} onClick={() => { setIsActive("Cotizaciones"); scrollToRef(cotizacionesRef) }}>
                <p>{ocultarEnMobile ? "Materiales" :  "Solicitud de materiales"}</p>
              </li>
              <li className={`${styles.sectionDivider_list_item} ${isActive === "Configuraciones" ? styles.isActive : ""}`} onClick={() => { setIsActive("Configuraciones"); scrollToRef(configuracionesRef) }}>
                <p>Configuración</p>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <main>
            <div className={styles.container}>
              {createNewPortfolio ? (
                <NuevoPortfolio cancel={() => handleNewPortfolioClick(false)} isCreatingNewPortfolio={isCreatingNewPortfolio} />
              ) : null}
              <main
                className={`${styles.PerfilMain} ${isVisualizer && styles.visualizer
                  }`}
              >
                <section className={styles.PerfilMain_section_resumen} ref={resumenRef}>
                  <div className={styles.PerfilMain_section_resumenContent}>
                    <div className={styles.description}>
                      <h4 className={styles.description_header}>
                        {type === "COMPANY" ? "Sobre nosotros" : "Sobre mi"}
                      </h4>
                      <div className={styles.description_infoContainer}>
                        <p className={styles.description_txt}>{description === null ? "No especificado" : description}</p>
                        <div className={styles.description_infoContainer_socialNetworksContainer}>
                          <h4 className={styles.description_infoContainer_socialNetworksContainer_title}>Redes</h4>
                          <div className={styles.description_infoContainer_socialNetworksContainer_icons}>
                            {facebook && (
                              <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                <a href={facebook} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                  <FacebookIcon width={20} height={20} />
                                </a>
                              </div>
                            )}
                            {instagram && (
                              <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                <a href={instagram} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                  <InstagramIcon width={20} height={20}  />
                                </a>
                              </div>
                            )}
                            {linkedin && (
                              <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                <a href={linkedin} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                  <LinkedinIcon width={20} height={20}  />
                                </a>
                              </div>
                            )}
                            {twitter && (
                              <div className={styles.description_infoContainer_socialNetworksContainer_item} >
                                <a href={twitter} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                  <TwitterIcon width={20} height={20}  />
                                </a>
                              </div>
                            )}
                            {phone && (
                              <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                <a href={`https://wa.me/${phone}`} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                  <WhatsAppIcon width={20} height={20}  />
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.PerfilMain_section_resumenContent_infoContainer}>
                      <div className={styles.PerfilMain_section_resumenContent_headers}>
                        <h4 className={`${styles.PerfilMain_section_resumenContent_headers_title} ${resumenSectionActive === "rubros" ? styles.isActive : ""}`} onClick={() => setResumenSectionActive("rubros")}>Rubros</h4>
                        <h4 className={`${styles.PerfilMain_section_resumenContent_headers_title} ${resumenSectionActive === "ubicacion" ? styles.isActive : ""}`} onClick={() => setResumenSectionActive("ubicacion")}>Ubicación</h4>
                      </div>
                      {resumenSectionActive === "rubros" && (
                        <Skills perfilAjeno={perfilAjeno} />
                      )}
                      {resumenSectionActive === "ubicacion" && (
                        <div className={styles.PerfilMain_section_resumen_info}>
                          <Maps ciudad={address} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.PerfilMain_section_resumen_rewiew}>
                    <Reputacion userId={perfilAjeno} myPerson={myPerson} />
                  </div>
                </section>
                <section className={styles.PerfilMain_section}>
                  <header className={styles.PerfilMain_headerSection} ref={misProductosRef}>
                    <h2 className={styles.PerfilMain_headerSection_header}>Productos - servicios</h2>
                    {myPerson && (
                    <div
                        className={styles.newPortfolio}
                        onClick={guestAccountCheck(() =>
                          handleNewPortfolioClick(true)
                        )}
                      >
                        Cargar nuevo                    
                        <ArrowRightIcon width={10} height={10} />                      
                      </div>
                    )}
                  </header>
                  <div className={`${styles.PerfilMain_content} ${!portfolios?.length && styles.empty}`}>
                    {portfolios?.length ? (
                      portfolios.slice(0, visibleItems).map((portfolio, index) => (
                        <MainInfoItem
                          key={index}
                          perfilAjeno={perfilAjeno}
                          item={portfolio}
                          isPortfolio
                          isVisualizer={isVisualizer}
                          displayInfo={true}
                          fromPage={false}
                          myPerson={myPerson}
                        />
                      ))
                    ) : (
                      <EmptyState title="No hay productos - servicios cargados." />
                    )}

                  </div>
                  {portfolios?.length > 0 && (
                    portfolios?.length > visibleItems ? (
                      <div className={styles.PerfilMain_content_seeMore} onClick={handleToggleVisibility}>
                        <ArrowDownIcon height={8} width={8} className={portfolios.length > visibleItems ? styles.arrowDownIcon : styles.arrowUpIcon} />
                        Mostrar más
                        <ArrowDownIcon height={8} width={8} className={portfolios.length > visibleItems ? styles.arrowDownIcon : styles.arrowUpIcon}/>
                        </div>
                    ) : (
                      portfolios?.length > 8 &&
                      <div className={styles.PerfilMain_content_seeMore} onClick={handleToggleVisibility}>
                        <ArrowDownIcon height={8} width={8} className={styles.arrowUpIcon} />
                        Ocultar
                        <ArrowDownIcon height={8} width={8} className={styles.arrowUpIcon}/>
                        </div>
                    )
                  )}
                </section>
              </main>
            </div>
            <section className={styles.PerfilMain_section} ref={comentariosRef}>
              <div className={styles.PerfilMain_headerSection}>
                <h2 className={styles.PerfilMain_headerSection_header}>Calificaciones</h2>
              </div>
              <ComentarioMain perfilAjeno={perfilAjeno} myPerson={myPerson} />
            </section>
            {myPerson && (
              <>
                <section ref={obrasRef} className={styles.PerfilMain_section}>
                  <div className={styles.PerfilMain_headerSection}>
                    <h2 className={styles.PerfilMain_headerSection_header}>Obras publicadas</h2>
                  </div>
                  <MisProyectosNew fromPage />
                </section>
                <section className={styles.PerfilMain_section} ref={postulacionesRef}>
                  <div className={styles.PerfilMain_headerSection}>
                    <h2 className={styles.PerfilMain_headerSection_header}>Proyectos postulados</h2>
                  </div>
                  <MisPostulaciones fromPage />
                </section>
                <section className={styles.PerfilMain_section} ref={cotizacionesRef}>
                  <div className={styles.PerfilMain_headerSection}>
                    <h2 className={styles.PerfilMain_headerSection_header}>Solicitud de materiales</h2>
                  </div>
                  <MisCotizaciones fromPage />
                </section>
                <section className={styles.PerfilMain_section} ref={configuracionesRef}>
                  <div className={styles.PerfilMain_headerSection}>
                    <h2 className={styles.PerfilMain_headerSection_header}>Configuración</h2>
                  </div>
                  <MiSuscripcion />
                </section>
              </>
            )}
          </main>
        </div>
      </div>
      {isEditing && (
        <ProfileEditModal setIsEditing={setIsEditing} />)}
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {

  return {
    perfilAjeno: ownProps.perfilAjeno,
    comments: ownProps.comments,
    commentsProfile: state.profile.comments,
    profileData: state.general.profileVisualizer,
    portfolios: state.register.portfolios,
    proyectos: state.profile.proyectos,
    creatingPortfolio: state.general.loading,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, {
  getPortfolio,
  testDescriptionEdit,
  userInfoNavStyle,
})(PerfilMain);
