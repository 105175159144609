import React from "react";
import styles from "./AllPrivateProjects.module.scss";
import { connect } from "react-redux";
import AllProjectsItem from "../../AllProjectsItem/AllProjectsItem";


const AllPrivateProjects = ({
  dashboardView,
  onFocusRef,
  refProjectId,
  typeViewer,
  userData,
  setModalOpen,
  isEditing,
  setIsEditing,
  idForEdit,
  setIdForEdit,
  showSubprojects = false,
  currentPage,
  item,
  key,
  ...props
}) => {



  return (
    <div className={styles.AllPrivateProjects}>
    <AllProjectsItem
    key={key}
    item={item}
    onFocusRef={props.onFocusRef}
    refProjectId={props.projectId}
    typeViewer={props.typeViewer}
    isPostulation={props.isPostulation}
    showSubprojects={true}
    currentPage={currentPage}
    />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileVisualizer: state.general.profileVisualizer,
  };
};

export default connect(mapStateToProps, {
  })(AllPrivateProjects);
