import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Backdrop from "./Backdrop/Backdrop";

const WrapperModal = styled.div`
  position: fixed;
  top: ${({ noMargin }) => (noMargin ? "50%" : "50%")};
  @media (max-width: 600px) {
    top: 100%;
  }
  left: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
  display: ${({ opened }) => (opened ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  z-index: 1005;
  max-width: ${({ width }) => {
    if (width === "sm") {
      return "546px";
    } else if (width === "lg") {
      return "550px";
    } else if (width === "xl") {
      return "750px";
    } else if (width === "datos") {
      return "700px";
    }
  }};
  height: ${({ height }) => {
    if (height === "sm") {
      return "413px;";
    } else if (height === "lg") {
      return "942px";
    } else {
      return "auto";
    }
  }};
  padding: 0;
  transition: all 0.3s;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0 8px 16px 4px rgba(210, 224, 233, 0.2);

  .title {
    width: 100%;
    background-color: #2f6aae;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    h1 {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      line-height: 61px;
      text-align: left;
      margin-left: 40px;
    }
  }
`;

const InsideWrapper = styled.div`
  position: fidex;
  width: 100%;
  padding: 50px 70px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
`;

const Modal = ({ opened, close, children, height, width, noMargin, title }) => {
  return ReactDOM.createPortal(
    <>
      <Backdrop closeModal={close} opened={opened} />
      <WrapperModal opened={opened} height={height} width={width} noMargin={noMargin}>
      { title && <div className='title'>
          <h1>{title}</h1>
        </div>}
        <InsideWrapper>{children}</InsideWrapper>
      </WrapperModal>
    </>,
    document.getElementById("root-modal")
  );
};

export default Modal;
