import React, { useEffect } from "react";
import styles from "./MiSuscripcion.module.scss";
import { connect } from "react-redux";
import { openSuscriptionModal, openMainModal, cancelCurrentSuscription, getVisits } from "../../../store/actions";
import moment from "moment";
import useGuestAccountCheck from "../../../hooks/useGuestAccountCheck";
import Loading from "../../UI/Loading/Loading";

function SuscriptionMain(props) {
  const {
    isUserPremium,
    openSuscriptionModal,
    isFreeTrialActive,
    userData,
    getVisits,
    cancelSuscriptionLoading,
  } = props;
  const guestAccountCheck = useGuestAccountCheck()

  useEffect(() => {
    getVisits()
  }, []);


  const handleSuscriptionButton = () => {
    openSuscriptionModal()
  }

  const formatDate = (date) => {
    if (date !== null) {
      const formattedDate = moment(date).format("DD/MM/YYYY HH:mm");
      return formattedDate;
    } else {
      return " - ";
    }
  };

  const renderSuscriptionStatus = () => {
    if (isFreeTrialActive) {
      return (
        < div className={styles.infoColumn}>
            <div>Free Trial Activado</div>
            <div>Inicio: {formatDate(userData.free_trial?.start_date ? userData.free_trial.start_date : null)}</div>
            <div>Finalizacion: {formatDate(userData.free_trial?.end_date)}</div>
        </div>
      )
    } else {
      return (
          <div className={styles.infoColumn}>
            {userData.subscription?.plan_name ? userData.subscription.plan_name : "Edify Plan "}
            {userData.subscription?.status === "pending" && <div>Estado: Pendiente de cobro</div>}
            {userData.subscription?.status === "active" && <div>Estado: Pagado</div>}
            {userData.subscription?.status === "expired" && <div>Estado: Cancelado</div>}
            <div>Inicio:{formatDate(userData.subscription?.start_date ? userData.subscription.start_date : null)}</div>
            <div>Finalizacion:{formatDate(userData.subscription?.end_date ? userData.subscription.end_date : null)}</div>
          </div>        
      )
    }
  }

  return (
    <div className={styles.container}>
      <main
        className={styles.PerfilMain}
      >
        <section className={styles.PerfilMain_section}>
          <header
            className={styles.PerfilMain_heading}
          >
            <h2 className={styles.PerfilMain_heading_h2}>Suscripción</h2>
          </header>
          {
            isUserPremium ? (
              <div className={styles.PerfilMain_content}>
                <div className={styles.miPerfil_header}>
                  {renderSuscriptionStatus()}
                </div>
              </div>
            ) : (
              <>
                <div className={styles.emptyState_title}>
                  <h3>¡Disfruta de Edify Premium!</h3>
                  <p>Tenés el plan basico gratuito</p>
                  </div>
                <button
                  className={styles.suscription_button}
                  onClick={guestAccountCheck(handleSuscriptionButton)}
                >
                  ¡Actualizar a Premium!
                </button>
              </>
            )
          }
        </section>
      </main>
      {cancelSuscriptionLoading && <Loading />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserPremium: state.general.isUserPremium,
    isFreeTrialActive: state.general.isFreeTrialActive,
    userData: state.general.userData,
    cancelSuscriptionLoading: state.profile.cancelSuscriptionLoading,
    isCancelledSuscriptionStillActive: state.general.isCancelledSuscriptionStillActive,
    profileVisits: state.profile.profileVisits,
  };
};

export default connect(mapStateToProps, { openSuscriptionModal, openMainModal, cancelCurrentSuscription , getVisits})(SuscriptionMain);
