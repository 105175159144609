import React from "react";
import UserData from "../../UserData/UserData";
import styles from "./RepAndComments.module.scss";
import { ReactComponent as StarEmpty } from "../../../assets/svg/emptyStarSvg.svg";
import { ReactComponent as StarFull } from "../../../assets/svg/starFullSvg.svg";
import { ReactComponent as StarHalf } from "../../../assets/svg/halfStarSvg.svg";

const RepAndComments = ({ comment, ...props }) => {

  let stars = [];
  let score = comment?.stars;
  for (let i = 0; i < 5; i++) {
    if (score >= 1) {
      stars.push(<StarFull key={i} width={15} height={15} />);
    } else if (score >= 0.5) {
      stars.push(<StarHalf key={i} width={15} height={15} />);
    } else {
      stars.push(<StarEmpty key={i} width={15} height={15} />);
    }
    score--;
  }

  //extraer las skills que fueron calificadaas
  const skills = comment?.skills?.map((skill) => {
    return skill.skill.name;
  });

  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentContainer_userSender}>
        <div className={styles.commentContainer_userSender_header}>
          <div>USUARIO</div>
        </div>
        <div className={styles.commentContainer_userSender_body}>
          <div className={styles.commentContainer_userSender_body_imageContainer}>
            <img className={styles.commentContainer_userSender_body_imageContainer_img} src={comment?.userSender?.image_profile} alt="user" />
          </div>
          <div className={styles.commentContainer_userSender_body_data}>
            <p>{comment?.userSender?.type === "COMPANY" ? comment?.userSender?.razon_social : comment?.userSender?.name + " " + comment?.userSender?.last_name}</p>
          </div>
        </div>
      </div>
      <div className={styles.commentContainer_commentData}>
      <div className={styles.commentContainer_comment}>
        <div className={styles.commentContainer_comment_header}>
          <div>CALIFICACIÓN</div>
        </div>
        <div className={styles.commentContainer_comment_body}>
          <p>"{comment?.comment}"</p>
          <div className={styles.commentContainer_date_body}>
            <p>creado {new Date(comment?.createdAt).toLocaleDateString()}</p>
          </div>
        </div>     
      </div>
      <div className={styles.commentContainer_skills}>
        <div className={styles.commentContainer_skills_header}>
          <div>SERVICIOS</div>
        </div>
        <div className={styles.commentContainer_skills_body}>
          <p>{skills?.join(", ")}</p>
        </div>
      </div>
      <div className={styles.commentContainer_score}>
          <div className={styles.commentContainer_score_header}>
            <div>PUNTUACIÓN</div>
          </div>
          <div className={styles.commentContainer_score_body}>
          {stars}
          </div>
          <div className={styles.commentContainer_score_footer}>
            <p>{comment?.stars}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepAndComments;
