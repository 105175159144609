import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Button = ({
  disabled,
  children,
  colored,
  bordered,
  link,
  typeBtn,
  ...rest
}) => {
  let tipoComp;

  typeBtn === "link" ? (tipoComp = styled(Link)) : (tipoComp = styled.button);
  let color = ({ colored }) => {
    if (colored === "white") {
      return "var(--color-mainBlue)";
    } else {
      return "var(--color-white)";
    }
  };
  const StyledButton = tipoComp`
        padding: 15px 30px;
        display: block;
        width: 140px;
        height: auto;
        outline: none;
        font-size: 15.5px;
        font-weight: 600;
        line-height: 17px;
        text-align: center;
        border: 2px solid var(--color-mainBlueBorder);
        background-color: var(--color-mainBlue);
        border-radius: 5px;

        color: ${color};
        box-shadow: ${({ shadow }) =>
          shadow ? "0px 20px 15px -15px var(--color-mainDark);" : null};
        width: ${({ widthed }) => {
          if (widthed === "xs") {
            return "140px";
          } else {
            return "100%";
          }
        }};

        background-color: ${({ colored }) => {
          if (colored === "red") {
            return "var(--color-error)";
          } else if (colored === "green") {
            return "var(--color-success)";
          } else if (colored === "white") {
            return "var(--color-white)";
          } else if (colored === "main") {
            return "var(--color-main)";
          } else {
            return "var(--color-mainBlue)";
          }
        }};

        border-radius: ${({ bordered }) => (bordered ? "7px" : null)};
        cursor: pointer;
        transition: all 0.2s;

        &:active {
            color: ${color};
        }
        ${({ disabled }) =>
          disabled
            ? `
                opacity: 0.4;
                pointer-events: none;
              `
            : null};
        &:disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
        `;
  return (
    <StyledButton
      to={`/${link}`}
      colored={colored}
      bordered={bordered}
      disabled={disabled}
      {...rest}
      whilehover={{ scale: 1.2 }}
      whiletap={{ scale: 0.8 }}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
