import React, { useState, useEffect } from 'react';
import styles from './WorkersMaps.module.scss';
import MapsLoading from '../../Loading/MapLoading';
import { connect } from 'react-redux';
import { openSuscriptionModal } from '../../../../store/actions';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import WorkersData from './WorkersData/WorkersData';
import SmallWorkerMap from '../SmallWorkerMap/SmallWorkerMap';
import useGetTodayDate from '../../../../hooks/useGetTodayDate';
import WorkerContact from './WorkerContact/WorkerContact';


const WorkersMaps = ({
    ciudadesArray,
    setSelectedUser,
    setIsFiltered,
    setShowJobOfferNotification,
    isFiltered,
    setLoading,
    setCoordinatesArray,
    setZoom,
    setCenter,
    loading,
    initialCenter,
    initialZoom,
    coordinatesArray,
    isUserPremium,
    setShowSmallMap,
    showSmallMap,
    zoom,
    center,
    bounds,
    setBounds,
}) => {

    const [uniqueWorker, setUniqueWorker] = useState(null);
    const [multipleWorkersSameCity, setMultipleWorkersSameCity] = useState(null);
    const [alternativeCoordinates, setAlternativeCoordinates] = useState(null);
    const [polygonBounds, setPolygonBounds] = useState(null);
    const [showWorkerContact, setShowWorkerContact] = useState(false);
    const [workerToPass, setWorkerToPass] = useState(null);
    const [isMarker, setIsMarker] = useState(false);

    const todayDate = useGetTodayDate();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const formattedTodayDate = parseDate(todayDate);

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleCloseSmallMap = () => {
        setShowSmallMap(false);
        setUniqueWorker(null);
        setAlternativeCoordinates(null);
        setMultipleWorkersSameCity(null);
        setCenter(initialCenter);
        setZoom(initialZoom);
        setPolygonBounds(null);
    }

    const handleWorkerContact = (worker) => {
        setShowWorkerContact(true);
        setWorkerToPass(worker);
    }

    return (
        <div className={styles.workerMapContainer}>
            <div className={styles.workerMapContainer_map}>
                {loading && <MapsLoading />}
                <MapContainer
                    center={center}
                    zoom={zoom}
                    style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                    scrollWheelZoom={true}
                    zoomControl={false}
                >
                    <TileLayer
                        url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    />
                    <WorkersData
                        ciudadesArray={ciudadesArray}
                        setCoordinatesArray={setCoordinatesArray}
                        setBounds={setBounds}
                        setZoom={setZoom}
                        setCenter={setCenter}
                        coordinatesArray={coordinatesArray}
                        bounds={bounds}
                        center={center}
                        zoom={zoom}
                        setShowSmallMap={setShowSmallMap}
                        setUniqueWorker={setUniqueWorker}
                        uniqueWorker={uniqueWorker}
                        setShowJobOfferNotification={setShowJobOfferNotification}
                        setMultipleWorkersSameCity={setMultipleWorkersSameCity}
                        multipleWorkersSameCity={multipleWorkersSameCity}
                        setAlternativeCoordinates={setAlternativeCoordinates}
                        setPolygonBounds={setPolygonBounds}
                        polygonBounds={polygonBounds}
                        isFiltered={isFiltered}
                        setIsFiltered={setIsFiltered}
                        setIsMarker={setIsMarker}
                    />
                    <ZoomControl position="bottomleft" />
                </MapContainer>
            </div>
            {showSmallMap && !isFiltered && (
                <div className={styles.smallMap}>
                    <button className={styles.smallMap_closeMap} onClick={() => handleCloseSmallMap()}>Cerrar</button>
                    <MapContainer
                        center={isMarker ? [uniqueWorker?.coordinates[0], uniqueWorker?.coordinates[1]] : [alternativeCoordinates[0], alternativeCoordinates[1]]}
                        zoom={10}
                        style={{ height: '50%', width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', border: '1px solid #004F98' }}
                        scrollWheelZoom={true}
                        zoomControl={false}
                    >
                        <TileLayer
                            url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        />
                        <SmallWorkerMap
                            coordinates={isMarker ? uniqueWorker?.coordinates : alternativeCoordinates}
                        />
                    </MapContainer>
                    {!isMarker ? (
                        <div className={styles.smallMap_card}>
                            <h3 className={styles.smallMap_card_title}>Información de los trabajadores</h3>
                            <div className={styles.smallMap_card_container}>
                                {multipleWorkersSameCity.map((worker, index) => (
                                    <div key={`${worker.userInfo.id}-${index}`}>
                                        <p className={styles.smallMap_card_name}>{worker.userInfo.name} {worker.userInfo.last_name}</p>
                                        <div className={styles.smallMap_card_uniqueCity}>
                                            <img src='https://cdn-icons-png.flaticon.com/128/149/149059.png' alt="location" className={styles.smallMap_card_locationIcon} />
                                            {worker.city
                                                .split(' ,')
                                                .slice(0, 2)
                                                .join(', ')}
                                        </div>
                                        <div className={styles.smallMap_card_work}>
                                            <img src='https://cdn-icons-png.flaticon.com/128/17208/17208568.png' alt="work" className={styles.smallMap_card_locationIcon} />
                                            {worker.userInfo.skill.map((skillName) => {
                                                return skillName?.skill?.name;
                                            }).join(', ')}
                                        </div>
                                        {new Date(worker.userInfo.employment_end_date) < formattedTodayDate ? (
                                            <div className={styles.smallMap_card_isAvailable}>
                                                <div className={styles.smallMap_card_isAvailable_icon}></div>
                                                <p>Disponible</p>
                                            </div>
                                        ) : (
                                            <div className={styles.smallMap_card_isAvailable}>
                                                <div className={styles.smallMap_card_isAvailable_iconNo}></div>
                                                <p>Disponible a partir del {formatEmploymentEndDate(worker.userInfo.employment_end_date)}</p>
                                            </div>
                                        )}
                                        <button className={styles.smallContactButtonMultiple} onClick={() => handleWorkerContact(worker.userInfo)}>Contactar</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.smallMap_card}>
                            <h3 className={styles.smallMap_card_title}>Información del trabajador</h3>
                            <div className={styles.smallMap_card_container}>
                                <p className={styles.smallMap_card_name}>{uniqueWorker.cluster.properties.userInfo.name} {uniqueWorker.cluster.properties.userInfo.last_name}</p>
                                <p className={styles.smallMap_card_uniqueCity}>
                                    <img src='https://cdn-icons-png.flaticon.com/128/149/149059.png' alt="location" className={styles.smallMap_card_locationIcon} />
                                    {uniqueWorker.cluster.properties.city
                                        .split(' ,')
                                        .slice(0, 2)
                                        .join(', ')}
                                </p>
                                <div className={styles.smallMap_card_work}>
                                    <img src='https://cdn-icons-png.flaticon.com/128/17208/17208568.png' alt="work" className={styles.smallMap_card_locationIcon} />
                                    {uniqueWorker.cluster.properties.userInfo.skill.map((skillName) => {
                                        return skillName?.skill?.name;
                                    }).join(', ')}
                                </div>
                                {new Date(uniqueWorker.cluster.properties.userInfo.employment_end_date) < formattedTodayDate ? (
                                    <div className={styles.smallMap_card_isAvailable}>
                                        <div className={styles.smallMap_card_isAvailable_icon}></div>
                                        <p>Disponible</p>
                                    </div>
                                ) : (
                                    <div className={styles.smallMap_card_isAvailable}>
                                        <div className={styles.smallMap_card_isAvailable_iconNo}></div>
                                        <p>Disponible a partir del {formatEmploymentEndDate(uniqueWorker.cluster.properties.userInfo.employment_end_date)} </p>
                                    </div>
                                )}
                            </div>
                            <button className={styles.smallMap_contactButton} onClick={() => handleWorkerContact(uniqueWorker.cluster.properties.userInfo)}>Contactar</button>
                        </div>
                    )}
                </div>
            )}
            {showWorkerContact && <WorkerContact setShowWorkerContact={setShowWorkerContact} worker={workerToPass} setWorkerToPass={setWorkerToPass} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isUserPremium: state.general.isUserPremium,
    };
};

export default connect(mapStateToProps, { openSuscriptionModal })(WorkersMaps);




