import React from "react";
import { ReactComponent as EmptyProyectIcon } from "../../../assets/svg/servicesIcon.svg";
import styles from "./EmptyState.module.scss";

const EmptyState = ({ title = "" }) => {
  return (
    <div className={styles.EmptyState}>
      <EmptyProyectIcon width={50} height={50} />
      <h3>{title}</h3>
    </div>
  );
};

export default EmptyState;
