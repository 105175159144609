import React, { useMemo, useEffect, useState } from "react";
import styles from "./MisCotizaciones.module.scss";
import { connect } from "react-redux";
import { getMyQuotations } from "../../../store/actions/index";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Postulaciones } from "../../../assets/svg/misPostulaciones.svg";
import { ReactComponent as SeeMore } from "../../../assets/svg/SeeMoreProjects.svg";
import EmptyState from "../../UI/EmptyState/EmptyState";
import { motion, AnimatePresence } from "framer-motion";
import queryString from "query-string";
import QuotationsItem from "../../MarketPlace/QuotationsItem";
import { closeMainModal } from "../../../store/actions/profileActions";
import Modal from "../../Modal/Modal";
import NewMaterialBudget from "../../NewMaterialBudget/NewMaterialBudget";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";


const MisCotizaciones = ({ ...props }) => {
 const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [idForEdit, setIdForEdit] = useState(null);
  const { viewmore } = queryString.parse(navigate.location?.search);
  const [dropdownOpen, setDropdownOpen] = useState(
    !!viewmore || props.fromPage
  );

  useEffect(() => {
    props.getMyQuotations(props.userData.id);
  }, []);



  const dropdownChange = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const setModalOpen = (isOpen) => {
    closeMainModal();
    setIsOpen(isOpen);
  };

  const orderedQuotations = useMemo(() => {
    if (props.cotizaciones && props.cotizaciones.length) {
      let orderedCotizaciones = props.cotizaciones.reverse();
      if (dropdownOpen && props.fromPage) {
        return orderedCotizaciones;
      } else {
        return [orderedCotizaciones[0]];
      }
    }
  }, [props.cotizaciones, dropdownOpen]);

  //En el perfil del usuario no queremos mostrar las cotizaciones de materiales del tipo públicas
  const filteredQuotations = orderedQuotations?.filter(cotizacion => cotizacion.type !== "PUBLIC");

  const isLoaded = props.isLoaded;
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        className={styles.MisCotizaciones}>
        {isLoaded ? (
          Array.isArray(orderedQuotations) && orderedQuotations.length > 0 ? (
            <>
              <div className={styles.sortInfo}>
                <ul className={styles.sortInfo_list}>
                  <li className={styles.sortInfo_list_item}>Geolocalización <ArrowDown height={15} width={15} /></li>
                  <li className={styles.sortInfo_list_item}> Nombre <ArrowDown height={15} width={15} /></li>
                  <li className={styles.sortInfo_list_item}>Rubro <ArrowDown height={15} width={15} /></li>
                  <li className={styles.sortInfo_list_item}>Fecha de inicio <ArrowDown height={15} width={15} /></li>
                  <li className={styles.sortInfo_list_item}>Fecha de finalización <ArrowDown height={15} width={15} /></li>
                  <li className={styles.sortInfo_list_item}>Ciudad <ArrowDown height={15} width={50} /></li>
                  <li className={styles.sortInfo_list_item}>Moneda <ArrowDown height={15} width={50} /></li>
                  <li className={styles.sortInfo_list_item}>Acciones <ArrowDown height={15} width={50} /></li>
                </ul>
              </div>
              {filteredQuotations.map((cotizacion, index) => {
                if (props.userData.id === cotizacion.user.id) {
                  return (
                    <QuotationsItem
                      userData={props.userData}
                      key={index}
                      item={cotizacion}
                      setModalOpen={setModalOpen}
                      setCurrentItem={setCurrentItem}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  );
                }
              })}
              <div
                className={styles.openDropdown}
                onClick={() => dropdownChange()}
              />
            </>
          ) : (
            <EmptyState title="No tienes solicitudes de cotización de materiales" />
          )
        ) : (
          <>
            <div className={styles.skeletonCarousel}></div>
            <div className={styles.skeletonInfo}></div>
          </>
        )}
        <Modal isOpen={isOpen} setIsOpen={(newValue) => setModalOpen(newValue)}>
          <NewMaterialBudget
            closeModal={() => setModalOpen(false)}
            postulationQuotation={currentItem}
            isEditing
          />
        </Modal>
      </motion.div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => {
  return {
    cotizaciones: state.profile.viewQuotations,
    isLoaded: state.general.isLoaded,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, { getMyQuotations })(MisCotizaciones);
