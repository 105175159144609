import React, { useState, useEffect } from "react";
import styles from "./Resumen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadUserData, getPortfolio, setSuccess, setLoginAnimation } from "../../../store/actions/index";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { motion } from "framer-motion";
import { opacityVariants } from "../../../utils/FramerConfigs";
import { fireAnalytics } from "../../../utils/firebase";
import InitialWrapper from "../../../components/Layout/InitialWrapper/InitialWrapper";
import { useNavigate } from "react-router-dom";

const Resumen = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.general.userData);
  const userSkills = useSelector((state) => state.register.userSkills);
  const file = useSelector((state) => state.register.file);
  const loginAnimation = useSelector((state) => state.general.loginAnimation);

  useEffect(() => {
    dispatch(loadUserData());
    dispatch(getPortfolio());
    fireAnalytics('ob_step_5', { category: 'navigation' });
  }, [dispatch]);


  const handleFinish = () => {
    dispatch(setLoginAnimation(true));
    fireAnalytics('ob_step_5_next', { category: 'onboarding' });
  };

  useEffect(() => {
    if (loginAnimation) {
      setTimeout(() => {
        dispatch(setLoginAnimation(false));
        navigate("/dashboard");
      }, 3000);
    }
  }, [loginAnimation, dispatch, navigate]);

  let {
    last_name,
    name,
    phone,
    type,
    url_site_web,
    razon_social,
    address,
  } = userData;

  return (
    <div className={styles.resumenContainer}>
      <InitialWrapper animation={loginAnimation} tipo='onboard' title='Resumen' height='1600px'>
        <h1 className={styles.title}>
          ¡Registro completo! <br />
        </h1>
        <section className={styles.sectionContainer}>
          <div className={styles.borderWrapper}>
            <motion.div className={styles.readyContainer} variants={opacityVariants} initial='out' animate='in' transition={opacityVariants.transition(0.5)}>
              <p className={styles.formSubtitle}>
                Por favor, revisa tus datos para finalizar
              </p>
            </motion.div>
            <motion.div className={styles.mainContainer} variants={opacityVariants} initial='out' animate='in' transition={opacityVariants.transition(1)}>
              <div className={styles.infoContainer}>
                <div className={styles.imagesWrapper} onClick={() => dispatch(setSuccess())}>
                  <FileUpload userType='person' file={file} name='file' />
                </div>
                {type === "PERSON" ? (
                  <>
                    <div className={styles.dataContainer}>
                      <div className={styles.dataTitle} title={name}>
                        {name}
                      </div>
                    </div>
                    <div className={styles.dataContainer}>
                      <div className={styles.dataTitle} title={last_name}>
                        {last_name}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.dataContainer} style={{ width: "100%" }}>
                    <div className={styles.dataTitle} title={razon_social}>
                      {razon_social !== "" ? razon_social : "Razon Social"}
                    </div>
                  </div>
                )}
                <div className={styles.dataContainer}>
                  <div className={styles.dataTitle} title={address}>
                    {address !== null ? address : "Dirección profesional"}
                  </div>
                </div>
                <div className={styles.dataContainer}>
                  <div className={styles.dataTitle} title={phone}>
                    {phone !== "" ? phone : "Telefono"}
                  </div>
                </div>
                <div className={styles.dataContainerBig}>
                  <div className={styles.dataTitle} title={url_site_web}>
                    {url_site_web !== null ? url_site_web : "Sitio Web"}
                  </div>
                </div>
                <div className={styles.dataContainerBig}>
                  {userSkills?.length > 0 &&
                    userSkills.map((skill, index) => {
                      return (
                        <div key={index} className={styles.dataTitle}>
                          {skill.name}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                <div className={styles.btn} onClick={handleFinish}>
                  Finalizar
                </div>
              </div>
            </motion.div>
          </div>
        </section>
      </InitialWrapper>
    </div>
  );
};

export default Resumen;

