import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Privado } from "../../assets/svg/botoneraPrivado.svg";
import { ReactComponent as Publico } from "../../assets/svg/botoneraPublico.svg";
import { ReactComponent as Persona } from "../../assets/svg/botoneraPersona.svg";
import { ReactComponent as Personas } from "../../assets/svg/botoneraPersonas.svg";
import { ReactComponent as Maquina } from "../../assets/svg/botoneraMaquina.svg";
import { ReactComponent as Producto } from "../../assets/svg/botoneraProducto.svg";
import { ReactComponent as Obras } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as Notificaciones } from "../../assets/svg/nav-notif-active.svg";
import { ReactComponent as WorkBagIcon } from "../../assets/svg/trabajadoresIcon.svg";
import styles from "./Botonera.module.scss";
import Boton from "./Boton";
import { useSelector } from "react-redux";
import { ReactComponent as HamburguerIcon } from "../../assets/svg/hamburgerIcon.svg";
import { ReactComponent as EdifyIcon } from "../../assets/svg/logo-nav.svg";
import LogOutMenu from "../Perfil/LogOutMenu/LogOutMenu";
import { connect } from "react-redux";

const Botonera = ({ mobileStylesFromParent, userData }) => {

  const OnBoardingIndex = useSelector((state) => state.dashboard.currentOnBoardingModal)
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [isSelected, setIsSelected] = useState({ name: "", isSelected: false });
  const [mobileStyles, setMobileStyles] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
 const navigate = useNavigate();
 const location = useLocation();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStyles(windowWidth < 768);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Da un estilo condicional al boton cuando se esta en una seccion
  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === "/dashboard") {
      setIsSelected({ name: "Muro", isSelected: true });
    } else if (pathname === "/proyectosprivados") {
      setIsSelected({ name: "O. Privadas", isSelected: true });
    } else if (pathname === "/licitacionespublicas") {
      setIsSelected({ name: "O. Públicas", isSelected: true });
    } else if (pathname === "/cotizaciondemateriales") {
      setIsSelected({ name: "Materiales", isSelected: true });
    } else if (pathname === "/solicitudes") {
      setIsSelected({ name: "Notificaciones", isSelected: true });
    } else if (pathname === "/comunidad-edify") {
      setIsSelected({ name: "Profesionales", isSelected: true });
    } else if (pathname === `/comunidad-edify/${userData.id}/${userData.type === "COMPANY" ? userData.razon_social : userData.name + "" + userData.last_name}`) {
      setIsSelected({ name: "Mi perfil", isSelected: true });
    } else if (pathname === "/mercadodetrabajo") {
      setIsSelected({ name: "Mercado de trabajo", isSelected: true });
    }
  }, []);

  const botones = [
    {
      nombre: "Mi perfil",
      icono: <Persona />,
      background: "#7000ff",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        const userName = userData.type === "COMPANY" ? userData.razon_social : userData.name + "" + userData.last_name;
        navigate(`/comunidad-edify/${userData.id}/${userName}`);
      },
    },
      {
      nombre: "Muro",
      icono: <Privado />,
      background: "#7000ff",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        navigate("/dashboard");
      },
    },
    {
      nombre: "O. Privadas",
      icono: <Obras />,
      background: "#234BA2",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        navigate("/proyectosprivados");
      },
    },
    {
      nombre: "O. Públicas",
      icono: <Publico />,
      background: "#f5b048",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        navigate("/licitacionespublicas");
      },
    },
    {
      nombre: "Profesionales",
      icono: <Personas />,
      background: "#eeeeee",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        navigate("/comunidad-edify");
      },
    },
    {
      nombre: "Mercado de trabajo",
      icono: <WorkBagIcon />,
      background: "#f5b048",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        navigate("/mercadodetrabajo");
      },
    },
    {
      nombre: "Materiales",
      icono: <Producto />,
      background: "#45a7ff",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        navigate("/cotizaciondemateriales");
      },
    },
    {
      nombre: "Notificaciones",
      icono: <Notificaciones />,
      background: "#D05004",
      isSelected: isSelected,
      ocultarEnMobile: false,
      mobileStyles: mobileStyles || mobileStylesFromParent,
      onClick: () => {
        navigate("/solicitudes");
      },
    }
    /* 
    {
      nombre: "Seguros",
      icono: <Persona />,
      background: "#D05004",
      image: Seguros,
      ocultarEnMobile: ocultarEnMobile || mobileStylesFromParent,
      mobileStyles: false,
      onClick: () => {
        navigate("/segurosparatrabajadoresyequipos");
      },
    },
    {
      nombre: "Alquiler de Maquinarias",
      icono: <Maquina />,
      background: "#22af86",
      image: Maquinarias,
      ocultarEnMobile: ocultarEnMobile || mobileStylesFromParent,
      mobileStyles: false,
      onClick: () => {
        navigate("/alquilerdemaquinarias");
      },
    },
     */
  ];

  return (
    <nav className={styles.container}>
      <button className={styles.menuButton} onClick={() => setShowMenu(!showMenu)}>
        <HamburguerIcon width={30} height={30} />
      </button>
      <span className={styles.logo}>
        <EdifyIcon width={80} height={40} />
      </span>
      {showMenu && (
        <>
          <div className={styles.overlay} onClick={() => setShowMenu(false)} />
          <div className={styles.menuContainer}>
            {botones.map((boton, index) => (
              boton?.ocultarEnMobile === true ? null : (
                <div key={boton.nombre} className={`${styles.relativeContainer} ${isSelected.name === boton.nombre && styles.isSelected}`} >
                  <Boton {...boton} isCurrentOnBoardingModal={OnBoardingIndex === (index + 1)} />
                </div>
              )
            ))}
          </div>
        </>
      )
      }
      <div className={styles.rightIcons}>
        <LogOutMenu />
      </div>
    </nav >
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
  }

}

export default connect(mapStateToProps)(Botonera);
