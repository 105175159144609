import React from "react";
import styles from "./ButtonSeeProject.module.scss";
import { ReactComponent as VerProyecto } from "../../assets/svg/verProyecto.svg";
import { ReactComponent as VerMenos } from "../../assets/svg/verMenos.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrowUpIcon.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrowDownIcon.svg";

function ButtonSeeProject({
  link,
  preparePostulation,
  onClick,
  rigthText,
  moreInfo,
}) {

  return (
    <div
      className={`${link === null ? styles.moreInfo : styles.dnone} ${
        moreInfo || preparePostulation ? styles.open : ""
      }`}
      onClick={onClick}
    >
      <p className={styles.text}>{rigthText}</p>     
      <span className={styles.svg}>
      </span>
    </div>
  );
}

export default ButtonSeeProject;
