import React, { useEffect, useState } from "react";
import styles from "./AllProjectsItem.module.scss";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  setPostulationProjectId,
  openNDAModal,
  closeNDAModal,
  setResponsiveFall,
  goToProjectId,
  getPostulationProjectStatus,
} from "../../../store/actions/index";
import {
  openMainModal,
  getPostulations,
  getSubProjectBudgets,
  clearSubProjectBudgets,
} from "../../../store/actions/profileActions";
import defaultImage from "../../../assets/images/foto-bienvenida.jpg";
import ButtonSeeProject from "../../ButtonSeeProject/ButtonSeeProject";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const AllProjectsItem = ({
  dashboardView,
  onFocusRef,
  refProjectId,
  typeViewer,
  isSettingPostulation,
  clearSubProjectBudgets,
  item,
  showSubprojects = false,
  quotation,
  userId,
  getPostulationProjectStatus,
  postulaciones,
  openNDAModal,
  closeNDAModal,
  getSubProjectBudgets,
  setPostulationProjectId,

  subProjectBudgetsToSend,
  profileVisualizer,
  projectsOnPage,
  getPostulations,
  userData,
  ...props
}) => {

  const [moreInfo, setMoreInfo] = useState(true);
  const [preparePostulation, setPreparePostulation] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [filteredProjectsStatus, setFilteredProjectsStatus] = useState([]);
 const navigate = useNavigate();
  const premiumCheck = usePremiumCheck();
  let {
    link,
    name,
    startDate,
    endDate,
    zone,
    sendDate,
    user,
  } = item;

  //Definimos de donde tomamos las fotos
  let imagenes = null;

  if (item?.proyecto_images) {
    if (Array.isArray(item?.proyecto_images) && item.proyecto_images.length > 0) {
      imagenes = item.proyecto_images.map((img, index) => (
        <div key={img.id} className={styles.customImg_container} onClick={() => handleOpenLightbox(index)}>
          <img src={img.image} alt="" className={`${styles.customImg} ${preparePostulation ? styles.whenOpen : ""}`} />
        </div>
      ));
    } else if (item?.image) {
      imagenes = (
        <div className={styles.customImg_container} >
          <img src={item.image} alt="" className={styles.customImg} />
        </div>
      );
    } else {
      imagenes = (
        <div className={styles.customImg_container}>
          <img src={defaultImage} alt="" className={`${styles.customImg} ${preparePostulation ? styles.whenOpen : ""}`} />
        </div>
      );
    }
  } else if (item?.image) {
    imagenes = (
      <div className={styles.customImg_container}>
        <img src={item.image} alt="" className={`${styles.customImg} ${preparePostulation ? styles.whenOpen : ""}`} />
      </div>
    );
  } else {
    imagenes = (
      <div className={styles.customImg_container}>
        <img src={defaultImage} alt="" className={`${styles.customImg} ${preparePostulation ? styles.whenOpen : ""}`} />
      </div>
    );
  }

  // Lee el contenido de los archivos y genera las URLs de imagen
  useEffect(() => {
    if (item?.proyecto_images) {
      if (Array.isArray(item?.proyecto_images) && item.proyecto_images.length > 0) {
        const urls = item.proyecto_images.map((img) => img.image);
        setImageUrls(urls);
      } else if (item?.image) {
        setImageUrls([item.image]);
      }
    } else if (item?.image) {
      setImageUrls([item.image]);
    } else {
      setImageUrls([defaultImage]);
    }
  }, [item]);
  const handleOpenLightbox = (index) => {
    setCurrentImageIndex(index);
    setShowLightbox(true);
  };

  const handleCloseLightbox = () => {
    setShowLightbox(false);
  };

  useEffect(() => {
    getPostulations(userData.id);
    getPostulationProjectStatus(item.id);
  }, []);



  endDate = props.isPostulation ? sendDate : endDate;
  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate(); 
    return `${day}/${month}/${year}`;
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Formatear los campos
  const formattedName = capitalizeFirstLetter(name);
  const textButton = preparePostulation ? "Ver menos" : "Ver más";

  const handleRigthText = () => {
    clearSubProjectBudgets(); 
    navigate(`/proyectosprivados/${item.id}`)
  };

  useEffect(() => {
    filterAndSetProjects();
  }, [postulaciones]);

  // Función para filtrar y setear los proyectos
  const filterAndSetProjects = () => {
    const filteredProjects = projectsOnPage.map(project => {
      const matchingPostulacion = postulaciones.find(postulacion => postulacion.proyecto.id === project.id);
      if (matchingPostulacion) {
        return { ...project, status: matchingPostulacion.status };
      }
      return project;
    });
    setFilteredProjectsStatus(filteredProjects);
  };

const handleRedirectToUser = () => {
  const userName = user?.type === "COMPANY" ? user?.razon_social : `${user?.name} ${user?.last_name}`;
  const userId = user?.id;
  navigate(`comunidad-edify/${userId}/${userName}`);
};

  //Manejan los estados de los botones de las postulaciones
  const isPostulationSent = postulaciones.find(postulacion => postulacion.proyecto.id === item.id && postulacion.status === "SEND");
  const isPostulationAllowed = postulaciones.find(postulacion => postulacion.proyecto.id === item.id && postulacion.status === "ALLOWED");
  const isPostulationDenied = postulaciones.find(postulacion => postulacion.proyecto.id === item.id && postulacion.status === "NEGATED");
  const isPostulationNotSent = !isPostulationSent && !isPostulationAllowed && !isPostulationDenied;

  return (
    <div className={styles.AllProjectsItemContainer}>
      <ul className={styles.AllProjectsItemList}>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column1}`}>
          <Carousel
            showThumbs={false}
            className={styles.AllProjectsItemList_carousel}
            selectedItem={currentImageIndex}
            onChange={(index) => setCurrentImageIndex(index)}
            useKeyboardArrows={true}
            showIndicators={false}
            showStatus={false}
          >
            {imagenes}
          </Carousel>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column2}`}>{formattedName}</li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column3}`}>
          <div className={styles.subprojectContainer_titles}>
            {Array.isArray(item?.subproyecto) &&
              item?.subproyecto.map((subproject, index) => {
                return (
                  <div className={styles.subprojectContainer_titles_item} key={index}>
                    <p className={styles.subprojectContainer_titles_item_p}>{subproject.name}</p>
                  </div>
                );
              })}
          </div>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column4}`}>
          <p className={styles.dateAndZone_dates_p}>
            {formatDate(startDate)}
          </p>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column5}`}>
          <p className={styles.dateAndZone_dates_p}>
            {formatDate(endDate)}
          </p>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column6}`}>{zone}</li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column7}`}>
            <div className={styles.flex_buttonSeeMore}>
              <ButtonSeeProject
                link={link}
                onClick={premiumCheck(handleRigthText)}
                rigthText={textButton}
                preparePostulation={preparePostulation}
                moreInfo={moreInfo}
                className={styles.viewProyect}
                key={item.id}
              />
            </div>
        </li> 
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column8}`}>
          <div className={styles.AllProjectsItemList_listItem_userData} onClick={() => handleRedirectToUser()}>
            <img src={user?.image_profile} alt="Foto de perfil" className={styles.AllProjectsItemList_listItem_userData_img} />
          {user?.type === "COMPANY" ? user?.razon_social : `${user?.name} ${user?.last_name}`}
          </div>
        </li>
      </ul>
      {
        showLightbox && (
          <div className={styles.lightbox}>
            <div className={styles.lightbox_container}>
              <span className={styles.close_btn} onClick={handleCloseLightbox}>X</span>
              <img src={imageUrls[currentImageIndex]} alt="Full-size" />
            </div>
          </div>
        )
      }
    </div >
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardView: ownProps.dashboardView || false,
    item: ownProps.item,
    isSettingPostulation: state.dashboard.isSettingPostulation,
    profileVisualizer: state.general.profileVisualizer,
    questions: state.profile.questions,
    postulaciones: state.profile.postulations,
    projectsOnPage: state.dashboard.allProjects.entities || state.dashboard.allProjects,
    userData: state.general.userData,
    subProjectBudgetsToSend: state.profile.subProjectBudgets,
  };
};

export default connect(mapStateToProps, {
  setPostulationProjectId,
  openMainModal,
  openNDAModal,
  closeNDAModal,
  goToProjectId,
  clearSubProjectBudgets,
  setResponsiveFall,
  getPostulations,
  getSubProjectBudgets,
  getPostulationProjectStatus,
})(AllProjectsItem);
