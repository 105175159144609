import React, { useEffect } from "react";
import styles from "./Skills.module.scss";
import { connect } from "react-redux";
import {
  getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  getUserSkill,
  deleteSkillFromProfile,
  openMainModal,
  closeMainModal,
} from "../../../store/actions";


const Skills = ({
  perfilAjeno,
  getSkills,
  getUserSkill,
  userSkills,
  userData,
}) => {

  useEffect(() => {
    getSkills();
    getUserSkill(perfilAjeno);
  }, [perfilAjeno, getSkills, getUserSkill]);

  const isVisualizer = perfilAjeno !== "" && perfilAjeno !== userData.id;
  const currentUserSkills = isVisualizer ? userSkills : userSkills;

  return (
    <div className={styles.Skills}>
      <div className={styles.Skills_main}>
        <ul className={styles.Skills_main_userSkills}>
          {currentUserSkills &&
            currentUserSkills.length > 0 ? (
            currentUserSkills.map((skill, index) => {
              return (
                <li
                  key={index}
                  className={styles.Skills_main_skill}
                >
                  -  {skill.name}
                </li>
              );
            })
          ) : (
            <div className={styles.Skills_main_skill}>
              No hay rubros especificados
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
    profileVisualizer: state.general.profileVisualizer,
  };
};

export default connect(mapStateToProps, {
  getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  getUserSkill,
  deleteSkillFromProfile,
  openMainModal,
  closeMainModal,
})(Skills);
