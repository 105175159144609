import React, {  useState } from 'react';
import { connect } from "react-redux";
import {
  loadUserData,
  logout,
  openProfileMenu,
  openFeedTextModal,
  viewAccessRequest,
  openTutorialModal,
} from "../../store/actions";
import styled from "styled-components";
import styles from "./Faq.module.scss";
import Tutorial from '../Tutorial/Tutorial';
import { ReactComponent as TutorialSvg } from "../../assets/svg/tutorialIcon.svg";
import FaqQuestions from "./FaqQuestions";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  @media (max-width: 500px) {
    padding-top: 10px;
  }`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
    width: 100%;
    margin-top: 30px;
  &.isVisualizerOpen {
    max-height: 1500px;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    width: 100%;
    max-width: auto;
    min-width: auto;
  }
`;

const StylesMenu = styled.div`
  position: absolute;
`;


const Faq = ({openTutorialModal, profileMenu, tutorialModal}) => {


    return (
      <Container>
        <div className={styles.Faq}>
          <div className={styles.Faq_title}>Preguntas frecuentes</div>
        </div>       
        <Wrapper>
          <div className={styles.Faq_subtitle} onClick={() => openTutorialModal()}>
          <span> Ver tutoriales </span>      
        <TutorialSvg width={30} height={30}/>
        </div>
          <FaqQuestions />
        </Wrapper>
      </Container>
    )}
    const mapStateToProps = (state) => ({
        userData: state.general.userData,
        isLoaded: state.general.isLoaded,
        progressBarStatus: state.general.progressBarStatus,
        profileMenu: state.profile.menuShow,
        solicitudesList: state.dashboard.solicitudesList,
        tutorialModal: state.dashboard.tutorialModal,
      });

    export default
        connect(mapStateToProps, {
          loadUserData,
          logout,
          openProfileMenu,
          viewAccessRequest,
          openFeedTextModal,
          openTutorialModal,
        })(Faq);