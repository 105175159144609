import React, { useState, useEffect } from "react";
import styles from "./UserInfo.module.scss";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setFile, openMainModal, closeMainModal, testPersonaEdit,
  testEmpresaEdit, setNewProfileImageForProfile, sendContactNotification } from "../../../store/actions/profileActions";
import  { openSendContactNotificationModal } from "../../../store/actions";
import { setPreviousLocation } from "../../../store/actions";
import { ReactComponent as WebIcon } from "../../../assets/svg/webIcon.svg";
import { ReactComponent as ContactMessageIcon } from "../../../assets/svg/contactMessageIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";



const UserInfo = ({
  isLoaded,
  userData,
  clickHandler,
  previousLocation, 
  setPreviousLocation,
  profileVisualizerData,
  myPerson,
  fromProfile,
  isEditing,
  reducedNav,
  setIsEditing,
  setFile,
  setNewProfileImageForProfile,
  openSendContactNotificationModal,
  userInfoNavStyle,
  newProfileValues = {},
}) => {
  const {
    image_profile,
    type,
    razon_social,
    name,
    last_name,
    url_site_web
  } = myPerson ? userData : profileVisualizerData;

  const [loading, setLoading] = useState(true);
 const navigate = useNavigate();
  
  let userNameInfo;
  if (type === "COMPANY") {
    userNameInfo = razon_social;
  } else if (name && last_name) {
    userNameInfo = `${name} ${last_name}`;
  }

  const formattedUrl = url_site_web ? (url_site_web.startsWith('http') ? url_site_web : `https://${url_site_web}`) : '#';

  useEffect(() => {
    setLoading(false);
  }, [userNameInfo]);


  return (
    <div className={`${styles.userInfoContainer} ${reducedNav && styles.reduceWidth} ${!userInfoNavStyle && styles.noZindex}`}>
      <div className={styles.userInfoContainer_imageAndName}>
        {image_profile &&
        <img  className={styles.userInfoContainer_imageAndName_img} src={image_profile} alt="Profile" />
        }
        {userNameInfo && 
        <h2 className={styles.userInfoContainer_imageAndName_name}>{userNameInfo}</h2>
        }
      </div>
      <div className={styles.userInfoContainer_buttonsContainer}>
        {myPerson && (
        <div className={styles.userInfoContainer_buttonsContainer_editProfile}  onClick={() => setIsEditing(true)}>
          <p>Editar perfil</p>
          <ArrowRightIcon width={10} height={10}/>
        </div>
        )}
        <div className={styles.userInfoContainer_buttonsContainer_webSite}>
          <a href={formattedUrl} target="_blank" rel="noreferrer" className={styles.userInfoContainer_buttonsContainer_webSiteButton}>
            <p className={styles.userInfoContainer_buttonsContainer_webSite_txt}>Ver sitio web</p>
            <WebIcon width={20} height={20}/>
          </a>
        </div>       
        <div className={styles.userInfoContainer_buttonsContainer_contact} onClick={() => openSendContactNotificationModal( myPerson ? userData : profileVisualizerData)}>
          <p>Contactar</p>
          <ContactMessageIcon width={20} height={20}/> 
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    isLoaded: state.general.isLoaded,
    reducedNav: state.general.reducedNav,
    userInfoNavStyle: state.general.userInfoNavStyle,
  };
};

export default connect(mapStateToProps, { setFile, openMainModal, closeMainModal, testPersonaEdit,
  testEmpresaEdit, setNewProfileImageForProfile, setPreviousLocation, sendContactNotification, openSendContactNotificationModal})(UserInfo);
