import * as actionTypes from "../actions/types";
import axios from "../../axios";
import img from "../../assets/images/user.jpg";
import { openEdifySpinner, openMainModal } from "./profileActions";
import { fireAnalytics } from "../../utils/firebase";
import { curry, forEach } from "lodash";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const setImage = (file) => {
  return {
    type: actionTypes.SET_FILE,
    payload: file,
  };
};

const setPortfolioToReducer = (data) => {
  return {
    type: actionTypes.SET_PORTFOLIO,
    payload: data,
  };
};

const setSkillsToReducer = (data) => {
  return {
    type: actionTypes.SET_SKILLS,
    payload: data,
  };
};


export const addPortfolio = (portfolio) => {
  return {
    type: actionTypes.ADD_PORTFOLIO,
    payload: portfolio,
  };
};

export const uploadImagePortfolio = (images) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_PORTFOLIO,
    payload: images,
  };
};

export const uploadImagePreview = (images) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_PREVIEW,
    payload: images,
  };
};

export const editImageNewPortfolio = (images) => {
  return {
    type: actionTypes.EDIT_IMAGE_NEW_PORTFOLIO,
    payload: images,
  };
};

export const editImageOldPortfolio = (images) => {
  return {
    type: actionTypes.EDIT_IMAGE_OLD_PORTFOLIO,
    payload: images,
  };
};

export const deleteImageCreateType = (imageId) => {
  return {
    type: actionTypes.DELETE_IMAGE_PORTFOLIO_NEW,
    payload: imageId,
  };
};

export const deleteImageEditType = (imageId, portfolioId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .delete(
        `/portfolio/images/delete/${imageId}?portfolioId=${portfolioId}`,
        bearerToken
      )
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_IMAGE_PORTFOLIO_EDIT,
          payload: imageId,
        });
      });
  };
};

export const deleteBuffer = () => {
  return { type: actionTypes.DELETE_IMAGE_BUFFER };
};

export const closeAll = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DELETE_IMAGE_BUFFER });
    dispatch({ type: actionTypes.CLOSE_PORTFOLIO_ADD });
    dispatch({ type: actionTypes.CLOSE_MODAL });
    dispatch({ type: actionTypes.CLOSE_EDIT_PROFILE_SKILL });
    dispatch({ type: actionTypes.CLOSE_EDIT_PROFILE_DATA });
  };
};

export const setPersona = (personaForm, file) => {
   const formData = new FormData();
   let fileToSend;
   if (!file.name) {
     fileToSend = dataURLtoFile(img, "defaultImage.jpg");
     formData.append("image", fileToSend);
   } else {
      formData.append("image", file);
    }
   formData.append("active", true);
   formData.append("dni_cuit", personaForm.dniCuil !== undefined ? personaForm.dniCuil : "");
  formData.append("name", personaForm.name !== undefined ? personaForm.name : "");
  formData.append("last_name", personaForm.lastName !== undefined ? personaForm.lastName : "");
  formData.append("phone", personaForm.tel !== undefined ? personaForm.tel : "");
  formData.append("profession", personaForm.field !== undefined || personaForm.field !== null ? personaForm.field : "");
  formData.append("address", personaForm.address !== undefined ? personaForm.address : "");
  formData.append("url_site_web", personaForm.webSite !== undefined ? personaForm.webSite : "");
   formData.append("type", "PERSON");

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .patch("/user/update", formData, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_PERSONA_FORM,
          payload: personaForm,
          file: file,
        });
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tus datos, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const imagePreview = (event) => {
  if (
    event.target.files &&
    event.target.files[0] &&
    event.target.files[0].size < 20000000
  ) {
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/png"
    ) {
      let imagePreview;
      let reader = new FileReader();
      reader.onload = (e) => {
        imagePreview = e.target.result;
      };
      return imagePreview;
    }
  }
};

export const setEmpresa = (empresaForm, file, navigate) => {
  const formData = new FormData();
  let fileToSend;
  if (!file.name) {
    fileToSend = dataURLtoFile(img, "defaultImage.jpg");
    formData.append("image", fileToSend);
  } else {
     formData.append("image", file);
   }
  formData.append("active", true);
  formData.append("razon_social", empresaForm.companyName !== undefined ? empresaForm.companyName : "");
  formData.append("name", empresaForm.name !== undefined ? empresaForm.name : "");
  formData.append("last_name", empresaForm.last_name !== undefined ? empresaForm.last_name : "");
  formData.append("rubro", empresaForm.rubro !== undefined || empresaForm.rubro !== null ? empresaForm.rubro : "" );
  formData.append("dni_cuit", empresaForm.cuit !== undefined ? empresaForm.cuit : "");
  formData.append("profession", empresaForm.field !== undefined ? empresaForm.field : "" );
  formData.append("phone", empresaForm.tel !== undefined ? empresaForm.tel : "" );
  formData.append("address", empresaForm.address !== undefined ? empresaForm.address : "" );
  formData.append("url_site_web", empresaForm.webSite !== undefined ? empresaForm.webSite : "");
  formData.append("type", "COMPANY");
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .patch("/user/update", formData, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_EMPRESA_FORM,
          payload: empresaForm, 
          file: file,
        });
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tus datos, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};


export const getPortfolio = (userId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = userId ? `/portfolio/user?userFind=${userId}` : "/portfolio/user";
  return (dispatch) => {
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch(setPortfolioToReducer(res.data));
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const setLoading = () => {
  return {
    type: actionTypes.SET_LOADING,
  };
};

export const setPortfolio = (portfolioForm, files, from) => {
  const formData = new FormData();
  if (from === "perfil") {
    files.forEach((image) => {
      formData.append("images", image);
    });
    formData.append("name", portfolioForm.nameEdit);
    formData.append("startDate", portfolioForm.startDateEdit);
    formData.append("endDate", portfolioForm.endDateEdit);
    formData.append("description", portfolioForm.descriptionEdit);
    formData.append("zone", portfolioForm.zoneEdit);
  } else {
    files && formData.append("images", files);
    formData.append("name", portfolioForm.name);
    formData.append("startDate", portfolioForm.startDate);
    formData.append("endDate", portfolioForm.endDate);
    formData.append("description", portfolioForm.description);
    formData.append("zone", portfolioForm.zoneEdit);
  }

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  if (portfolioForm.startDate > portfolioForm.endDate) {
    return (dispatch) => {
      dispatch(
        openMainModal({
          title: "¡Error!",
          body: "La fecha de inicio no puede ser mayor a la fecha de cierre",
          isSuccess: true,
          confirmHandler: null,
        })
      );
    };
  } else {
    return (dispatch) => {
      dispatch(setLoading(true));
      axios
        .post("/portfolio/assign", formData, bearerToken)
        .then((res) => {
          dispatch({ type: actionTypes.CLOSE_MODAL });
          dispatch(getPortfolio());
          dispatch(setLoading(false));
          if (from === "perfil") {
            dispatch(
              openMainModal({
                title: "¡Acabas de crear un nuevo proyecto en tu porfolio!",
                body: "El proyecto ya se encuentra publicado en tu perfil",
                isSuccess: true,
                confirmHandler: null,
              })
            );
          } else {
            fireAnalytics("ob_step_3_confirmportfolio", {
              category: "onboarding",
            });
            dispatch(
              openMainModal({
                title: "¡Éxito!",
                body: "El proyecto fue creado y se encuentra publicado en tu perfil",
                isSuccess: true,
                confirmHandler: null,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
            dispatch(
              openMainModal({
                title: "¡Error!",
                body: "Debe completar todos los campos para cargar un nuevo portfolio.",
                isSuccess: true,
                confirmHandler: null,
              })
            );
        });
    };
  }
};

export const deletePortfolio = (id, navigate) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios
      .delete("/portfolio/delete", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          idPortfolio: +id,
        },
      })
      .then((res) => {
        dispatch(getPortfolio());
        dispatch(
          openMainModal({
            title: "¡Acabas de eliminar tu portafolio!",
            body: "El portafolio ya no estará visible en tu perfil",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "No pudimos eliminar el portafolio. Por favor vuelve a intentarlo mas tarde.",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const updatePortfolio = (portfolioForm, newImages, id) => {
  const formData = new FormData();
  let updatedAt = new Date();

formData.append("name", portfolioForm.nameEdit);
formData.append("startDate", portfolioForm.startDateEdit);
formData.append("endDate", portfolioForm.endDateEdit);
formData.append("description", portfolioForm.descriptionEdit);
formData.append("zone", portfolioForm.zoneEdit);
formData.append("updatedAt", updatedAt.toISOString());
formData.append("id", id);

if (newImages.length > 0) {
  newImages.forEach((image) => {
    formData.append(`images`, image);
  });
}


 let authToken = localStorage.getItem("access-Token-Edify");
 let bearerToken = {
  headers: { Authorization: `Bearer ${authToken}` },
  'Content-Type': 'multipart/form-data'
};
  
  return (dispatch) => {
    if (portfolioForm.startDateEdit > portfolioForm.endDateEdit) {
      dispatch(
        openMainModal({
          title: "¡Error!",
          body: "La fecha de inicio no puede ser mayor a la fecha de cierre",
          isSuccess: true,
          confirmHandler: null,
        })
      );
       } else {
      axios
        .patch("/portfolio/update", formData, bearerToken)
        .then((res) => {
          dispatch(getPortfolio());
          dispatch(
            openMainModal({
              title: "¡Acabas de actualizar tu portfolio!",
              body: "Los cambios ya están visibles en tu perfil",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        })
        .catch((err) => {
          dispatch(
            openMainModal({
              title: "¡Error!",
              body: "No pudimos actualizar los cambios. Revisa los datos ingresados y vuelve a intentar.",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        });
    }
  };
};

//Borrar imagenes de portfolio

export const deleteImageFromPortfolio = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");

  return (dispatch) => {
    axios
    .delete("/portfolio/deletePortfoliotImage", {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        id: id,
      },
    })
    .then((res) => {
      dispatch(getPortfolio());
      dispatch(
        openMainModal({
          title: "¡Acabas de eliminar una imagen de tu portafolio!",
          body: "La imagen ya no estará visible en tu perfil",
          isSuccess: true,
          confirmHandler: null,
        })
      );
    
    })
    .catch((err) => {
      dispatch(
        openMainModal({
          title: "¡Error!",
          body: "No pudimos eliminar la imagen. Por favor vuelve a intentarlo mas tarde.",
          isSuccess: true,
          confirmHandler: null,
        })
      );
    });
  };
};

//Bolsa de Trabajo 

//Trae todas las ciudades de una jurisdicción
export const getCities = (id) => {

  const sendUrl = `/bolsa-de-trabajo/list?province=${id}`;

  return (dispatch) => {
    axios
      .post(sendUrl, {}, {
        headers: {
          Authorization:  "Basic ZWRpZnk6bGFjbGF2ZXNlY3JldGExMjM0",
        },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_CITIES,
          payload: res.data.cities,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Obtiene todas las ofertas de trabajo existentes

export const getAllWorkOffers = () => {

  return (dispatch) => {
    axios
      .get(`/bolsa-de-trabajo/all-job-requests`)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_ALL_JOB_REQUESTS, 
          payload: {workOffers: res.data[0], total: res.data[1]}
        });
      })
      .catch((err) => {
        console.log(err)
      })
  }
}


//Crea una nueva oferta de trabajo

export const createWorkOffer = (workOfferData) => {

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  const skillsIdToSend = workOfferData.skills.map((el) => parseInt(el.id));

  const workZonesToSend = workOfferData.workZone.map(zone => ({
    "city": zone.city,
    "jurisdiccion": zone.jurisdiccion,
    "name": zone.name
  }));
  
  const body = {
    "contactMethod": workOfferData.contactMethod,
    "alternativeContact": workOfferData.alternativeContact,
    "workZones": workZonesToSend,
    "skills": skillsIdToSend,
    "description": workOfferData.description,
    "salary": workOfferData.salary,
    "salaryType": workOfferData.salaryType,
    "immediateStart": workOfferData.inmediateStart, 
    "startDate": workOfferData.startDate,
    "activeUntil": workOfferData.activeUntil,
    "currency": "$ARS"
  }

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post(`/bolsa-de-trabajo/new-job-request`, body, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Gracias por publicar tu oferta de trabajo!",
            body: "Tu oferta ya está publicada en nuestra bolsa de trabajo. ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tu oferta, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};



//Crea un nuevo usuario en bolsa de trabajo

export const createWorkBagUser = (workBagData) => {

  const skillsIdToSend = workBagData.skills.map((el) => el.id);

  const body = {
    name: workBagData.name, 
    lastname: workBagData.lastname,
    email: workBagData.email,
    phone: workBagData.phone,
    skills: skillsIdToSend,
    workZone: workBagData.workZone,
    unemploymentFund: workBagData.unemploymentFund,
    currentJob: workBagData.currentJob,
    employmentEndDate: workBagData.employmentEndDate
  }

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post(`/bolsa-de-trabajo/create`, body, {
        headers: {
          Authorization:  "Basic ZWRpZnk6bGFjbGF2ZXNlY3JldGExMjM0",
        },
      })
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Gracias por registrarte!",
            body: "Tus datos se han cargado con éxito en nuestra bolsa de trabajo. Cuando tengamos una oferta que se ajuste a tu perfil, te contactaremos. ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tus datos, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Recupera los datos de todos los registros en bolsa de trabajo

export const getAllWorkBagUsers = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(`/bolsa-de-trabajo/all-registers`, bearerToken)  
      .then((res) => {
        dispatch({
          type: actionTypes.GET_ALL_WORKBAG_USERS,
          payload: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Filtra los registros de la bolsa de trabajo

export const filterWorkBagUsers = (filterParams) => {
  // Inicializa la URL base
  let sendUrl = `/bolsa-de-trabajo/get/getByFilter`;

  // Construye los parámetros de consulta
  let queryParams = [];
  if (filterParams.jurisdicciones && filterParams.jurisdicciones.length > 0) {
    queryParams.push(`jurisdicciones=${filterParams.jurisdicciones.join('&jurisdicciones=')}`);
  }
  if (filterParams.skills && filterParams.skills.length > 0) {
    queryParams.push(`skills=${filterParams.skills.join('&skills=')}`);
  }

  // Agrega los parámetros de consulta a la URL base
  if (queryParams.length > 0) {
    sendUrl += `?${queryParams.join('&')}`;
  }

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(sendUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.FILTER_WORKBAG_USERS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


//Trae la lista de todas las skills

export const getSkills = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/skill/list`, bearerToken)
      .then((res) => {
        let datos = res.data.map((el) => {
          let name = el.name.charAt(0).toUpperCase() + el.name.slice(1);
          return { id: el.id, name: name };
        });
        dispatch(setSkillsToReducer(datos));
      })
      .catch((err) => {
        console.log(err)
      });
  };
}; 

//Trae las Skills del usuario

export const getUserSkill = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = `/skill/user?userFind=${id}`;
  return (dispatch) => {
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_USER_SKILL,
          payload: res.data,
        });
      })
      .catch((err) => {});
  };
};

//Busca la skill dentro de la lista general de skills
export const searchSkill = (skill) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = `skill/listByFilter?name=${skill}`;
  return (dispatch) => {
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.SEARCH_SKILL,
          payload: res.data,
        });
      })
      .catch((err) => {});
  };
};

//Elimina Skill del usuario en el perfil

export const deleteSkillFromProfile = (skillId, id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  let query = parseInt(skillId);
  return (dispatch) => {
    axios
      .delete(`skill/delete?skillId=${query}`, bearerToken)
      .then((res) => {
        dispatch(getUserSkill(id));
        dispatch(
          openMainModal({
            title: "¡Acabas de eliminar un rubro de tu perfil!",
            body: "El mismo no estará visible en tu perfil",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "No pudimos eliminar el rubro. Por favor vuelve a intentarlo mas tarde o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

    

export const addSkill = (id) => {
  return {
    type: actionTypes.ADD_SKILL,
    payload: id,
  };
};

export const removeSkill = (id) => {
  return {
    type: actionTypes.REMOVE_SKILL,
    payload: id,
  };
};

const getPlanReducer = (data) => {
  return {
    type: actionTypes.GET_PLANS,
    payload: data,
  };
};

export const setTerminos = () => {
  return {
    type: actionTypes.SET_TERMINOS,
  };
};

export const getAllPlan = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get("/plan/list", bearerToken)
      .then((res) => {
        dispatch(getPlanReducer(res.data));
      })
      .catch((err) => {});
  };
};

export const getUserPlan = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get("/plan/user", bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_USER_PLAN,
          payload: res.data,
        });
      })
      .catch((err) => {});
  };
};

export const setPlanId = (id) => {
  return {
    type: actionTypes.SET_PLANID,
    payload: id,
  };
};

export const sendPlan = (planId, navigate) => {
  let planPost = { idPlan: planId };

  let authToken = localStorage.getItem("access-Token-Edify");
  // let bearerToken = { headers: { Authorization: `Bearer ${authToken}`}};
  return (dispatch) => {
    axios({
      method: "post",
      url: "/plan/assign",
      params: planPost,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {

      })
      .catch((error) => {

      });
  };
};

export const sendSkills = (userSkills, navigate) => {
  let userSkillPost = userSkills.map((elem) => {
    return parseInt(elem.id);
  });
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = {
    headers: { Authorization: `Bearer ${authToken}` },
    "Content-Type": "application/json ",
  };

  return (dispatch) => {
    axios
      .post("/skill/assign", { skillId: userSkillPost }, bearerToken)
      .then((res) => {
        if (navigate) navigate("/resume");
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const clearOnboardingForms = () => {
  return {
    type: actionTypes.CLEAR_ONBOARDING_FORM,
  };
};

export const setSuccess = () => {
  return {
    type: actionTypes.SET_SUCCESS,
  };
};
