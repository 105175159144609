import React, { useState, useEffect } from 'react';
import { Map, Overlay, ZoomControl } from 'pigeon-maps';
import { osm } from 'pigeon-maps/providers'
import { ReactComponent as Location } from "../../../assets/svg/locationPlaceholder.svg"
import styles from './Maps.module.scss';


const Maps = ({ciudad, ciudadesArray}) => {
    const [coordinates, setCoordinates] = useState([-34.61315, -58.37723]);
    const [loading, setLoading] = useState(true);

    const handleSearch = async () => {

        setLoading(true);
        try {
          let city = ciudad;
          if (!city.includes('Argentina')) {
            city += ', Argentina';
          }
    
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(city)}`
          );
          if (response.ok) {
            const data = await response.json();
            if (data.length > 0) {
              const { lat, lon } = data[0];
              setCoordinates([parseFloat(lat), parseFloat(lon)]);
            } else {
              setCoordinates([-34.61315, -58.37723]); // Buenos Aires, Argentina
            }
          } else {
            console.error('Error al obtener datos del servidor.');
          }
        } catch (error) {
          console.error('Error al procesar la solicitud:', error);
        } finally {
          setLoading(false);
        }
      };


    useEffect(() => {
      const timeoutId = setTimeout(() => {
        handleSearch();
      }, 2000);
  
      return () => clearTimeout(timeoutId); 
    }, [ciudad]); 

    return  (
        <div style={{height:"100%"}}>
        {loading ? ( 
          <div>Cargando...</div>
        ) : (
            <Map provider={osm} defaultCenter={coordinates} defaultZoom={8} >
              <Overlay anchor={coordinates} offset={[120, 79]}>
                <div className={styles.marker}>
                  <Location style={{width:"15px", height:"15px"}}/>
                  <p>{ciudad}</p>
                </div>
              </Overlay>
                <ZoomControl />
            </Map>
          )}
      </div>
    );
  }

export default Maps;