import React from 'react';
import styles from './ValidateSuccess.module.scss';
import { validationSuccess } from '../../store/actions/authActions';
import { Link } from 'react-router-dom';
import Facebook from '../../assets/svg/facebookSvg.svg';
import Twitter from '../../assets/svg/twitterSvg.svg';
import LinkedIN from '../../assets/svg/linkedinSvg.svg';
import Instragram from '../../assets/svg/instagramSvg.svg';
import { connect } from 'react-redux';


const ValidateSuccess = ({validationSuccess, ...props}) => {


    return (
        <section className={styles.ValidateSuccess}>
            <div className={styles.content}>
                <div className={styles.textContainer}>
                    <h2 className={styles.title}>
                        ¡Tu cuenta ha sido <span>validada con éxito!</span>
                    </h2>
                    <h4 className={styles.subtitle}>
                    Gracias por elegirnos. Tu cuenta ya fue validada. Dirigeté al login para ingresar a tu cuenta.
                    </h4>
                </div>


                <div className={styles.btnFinish} onClick={() => validationSuccess(false)}>
                    <Link to={'/'}>Ir al login</Link>
                </div>

                <div className={styles.socialsContainer}>
                    <div className={styles.followUs}>
                        Seguinos en:
                    </div>
                    <div className={styles.icons}>
                        <a target="_blank" rel="noopener noreferrer" href="https://facebook.com"><img src={Facebook} alt=""/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com"><img src={Twitter} alt=""/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com"><img src={LinkedIN} alt=""/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://instagram.com"><img src={Instragram} alt=""/></a>
                    </div>
                </div>             
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        codeValidation: state.general.codeValidation
    }
}

export default connect(mapStateToProps,{validationSuccess})(ValidateSuccess);
