import React, { useMemo, useEffect, useState } from "react";
import styles from "./MisProyectosNew.module.scss";
import _ from "lodash";
import { connect } from "react-redux";
import {
  getProjects,
  clearPostulationProject,
  closeMainModal,
} from "../../../store/actions/index";
import EmptyStateMyProjects from "../../UI/EmptyState/EmptyStateMyProjects";
import MainInfoItem from "../../MainInfoItem/MainInfoItem";
import NewProject from "../../NewProject/NewProject";
import { motion } from "framer-motion";
import { fireAnalytics } from "../../../utils/firebase";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import usePremiumCheck from "../../../hooks/usePremiumCheck"
import Modal from "../../../components/Modal/Modal";
import Button from "../../UI/Forms/Button/Button";
import { ReactComponent as AddProyect } from "../../../assets/svg/crearPP.svg";


const MisProyectosNew = ({
  clearPostulationProject,
  proyectos,
  perfilAjeno,
  closeMainModal,
  ...props
}) => {
 const navigate = useNavigate();
  const premiumCheck = usePremiumCheck();
  const { viewmore } = queryString.parse(navigate.location?.search);
  const [dropdownOpen, setDropdownOpen] = useState(
    !!viewmore || props.fromPage
  );
  const [isOpenProject, setModalOpenProject] = useState(false);

  const handleNewOpenProject = () => {
    fireAnalytics("dsh_create_project", { category: "dashboard" });
    setModalOpenProject(true);
    closeMainModal();
  };
  useEffect(() => {
    if (props.fromPage) {
      fireAnalytics("my_projects", { category: "navigation" });
    }
    props.getProjects(null, perfilAjeno);
    if (props.fromPage) window.scrollTo({ top: 0 });

    return () => {
      if (props.fromPage) clearPostulationProject();
    };
  }, []);
  const orderedProyects = useMemo(() => {
    if (proyectos && proyectos.length) {
      let orderedProyects = _.orderBy(proyectos, ["startDate"], ["desc"]);
      if (dropdownOpen && props.fromPage) {
        return orderedProyects;
      } else {
        return [orderedProyects[0]];
      }
    }
  }, [proyectos, dropdownOpen, props.fromPage]);

  return proyectos ? (
    <motion.div className={styles.MisProyectosContainer}>
          {proyectos?.length === 0 ? (
            <div className={styles.emptyState} onClick={premiumCheck(handleNewOpenProject)}>
              <EmptyStateMyProjects />
              <h3>No hay proyectos activos</h3>
              <Button
                style={{
                  height: props?.fromPage ? "47px" : "40px",
                  display: "flex",
                  width: props?.fromPage ? "313px" : "220px",
                  fontWeight: "700",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontSize: props?.fromPage ? 16 : 13,
                  lineHeight: "19.12px",
                  background: "#234BA2",
                  marginBottom: "1rem",
                  marginTop: "2rem"
                }}
              >
                <AddProyect
                  style={{
                    display: "flex",
                    margin: "4px",
                  }}
                />
                Publicar obra privada
              </Button>
            </div>
          ) : (
             orderedProyects && orderedProyects.length > 0 ? (
              <div className={styles.projectContainer}>
              {orderedProyects.map((proyecto, index) => {
                return (
                  <div className={styles.mainInfoContainer} key={index} >
                    <MainInfoItem
                      key={proyecto.id}
                      item={proyecto}
                      isProject
                      defaultDropdownOpen={viewmore}
                      fromPage={props.fromPage}
                      perfilAjeno={perfilAjeno}
                      type="proyecto"
                      id={proyecto.id}
                    />
                  </div>
                );
              }
              )}
              </div>
            ) : null  
          )}
      <Modal
        isOpen={isOpenProject}
      >
        <NewProject closeModal={() => setModalOpenProject(false)} />
      </Modal>
    </motion.div>
  ) : null;

};

const mapStateToProps = (state) => {
  return {
    proyectos: state.profile.proyectos,
    isLoaded: state.general.isLoaded,
  };
};

export default connect(mapStateToProps, {
  getProjects,
  clearPostulationProject,
  closeMainModal,
})(MisProyectosNew);