import React, { useState } from "react";
import styles from "./RegisteredUsersCategory.module.scss";
import {
  getUsersBySkillName,
  openMainModal,
  closeMainModal,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  setGoBackToCategoryList,
  setPreviousLocation,
  openSuscriptionModal,
  getUsersRegisteredData,
} from "../../store/actions";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrowRightIcon.svg";
import { ReactComponent as LeftArrow } from "../../assets/svg/arrowLeftIcon.svg";
import RegisteredUserItem from "../ComunidadEdify/RegisteredUserItem";


const RegisteredUsersCategory = ({
  getUsersBySkillName,
  selectedSkill,
  closeMainModal,
  setProjectFormStyle,
  setSubProjectStyle,
  resetProjectToEdit,
  getUsersRegisteredData,
  setGoBackToCategoryList,
  volverAlListado,
  openMainModal,
  getUserPortfolios,
  openSuscriptionModal,
  isUserPremium,
  usersBySKills,
  selectedSkillsList,
  itemCount,
  userPortfolios,
  setPreviousLocation,
  resetUserPortfolios,
  totalPages,
  edifySpinner,
  currentPage,
  ...props }) => {


  const [savedSelectedSkillList, setSavedSelectedSkillList] = useState([]);
  const premiumCheck = usePremiumCheck();
 const navigate = useNavigate();
 const location = useLocation();

  //Generamos el rango de páginas a mostrar 
  const generatePageRange = (totalPages) => {
    const pages = [];
    const maxPagesToShow = 10;
    const minRangeToShow = 10;
    const initialPage = 1;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= minRangeToShow) {
        for (let i = 1; i <= maxPagesToShow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages - minRangeToShow) {
        for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        for (let i = currentPage - 5; i <= currentPage + 5; i++) {
          pages.push(i);
        }
      }
      if (pages[pages.length - 1] < totalPages) {
        pages.push("...");
        pages.push(totalPages);
      }
      if (pages[0] > initialPage) {
        pages.unshift("...");
        pages.unshift(initialPage);
      }
    }

    return pages.map((page, index) => {
      if (page === "...") {
        return (
          <span key={`dots-${page}`} className={styles.paginationDots}>
            {page}
          </span>
        );
      }
      return (
        <div key={`page-${page}`} style={{display: "flex"}}>
          <button
            className={page == currentPage ? styles.currentPage : styles.pageNumber}
            onClick={() => {
              handlePagination(selectedSkill, false, page);
            }}
          >
            {page}
          </button>
          {page < totalPages ? <span className={styles.pageSeparator} >&nbsp;&nbsp;</span> : null}
        </div>
      );
    });
  };

  //Manejamos la paginación 
  const handlePagination = (selectedSkill, fromSelect, page) => {
    getUsersBySkillName(selectedSkill, false, page);
  }

  // Redireccionamos a la pagina del usuario
  const handleRedirect = (userId, userName) => {
    if (isUserPremium) {
      setPreviousLocation(location.pathname);
      setSavedSelectedSkillList(selectedSkill);
      setGoBackToCategoryList(false)
      navigate(`/comunidad-edify/${userId}/${userName}`);
    } else {
      openSuscriptionModal();
    }
  };

  // Sort users based on whether they have a portfolio
  let sortedUsers = [];
  if (Array.isArray(usersBySKills)) {
    sortedUsers = usersBySKills.slice().sort((a, b) => {
      const hasPortfolioA = a.portfolio && a.portfolio.length > 0;
      const hasPortfolioB = b.portfolio && b.portfolio.length > 0;

      if (hasPortfolioA && !hasPortfolioB) {
        return -1;
      } else if (!hasPortfolioA && hasPortfolioB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return (

    <div className={styles.container}>
      <div className={styles.AllProjects}>
        {premiumCheck ? (
          <>
            <header className={styles.header}>
              <div className={styles.searchResults}>
                <div className={styles.searchResults_container}>
                  <p className={styles.searchResults_title}>Encontramos {itemCount} usuarios</p>
                  <p className={styles.searchResults_subtitle}>Página {currentPage} de {totalPages === 0 ? 1 : totalPages}</p>
                </div>
                <section className={styles.pagination}>
                  {currentPage > 1 && (
                    <button
                    key={`prev-${currentPage}`}
                      className={styles.paginationButton}
                      onClick={() => {
                        if (currentPage > 1) {
                          handlePagination(selectedSkill, false, parseInt(currentPage) - 1);
                        }
                      }}
                    >
                      <LeftArrow width={20} height={20} />
                    </button>
                  )}
                  <div className={styles.paginationNumbers}>
                    {totalPages > 1 && generatePageRange(totalPages, currentPage)}
                  </div>
                  {currentPage < totalPages && (
                    <button
                    key={`next-${currentPage}`}
                      className={styles.paginationButton}
                      onClick={() => {
                        if (currentPage < totalPages) {
                          handlePagination(selectedSkill, false, parseInt(currentPage) + 1);
                        }
                      }}
                    >
                      <ArrowRight width={20} height={20} />
                    </button>
                  )}
                </section>
              </div>
            </header>
            <div className={styles.usersContainer}>
              {Array.isArray(sortedUsers) && sortedUsers?.length > 0 ? (
                sortedUsers.map((user, index) => {
                  return (
                    <RegisteredUserItem key={index} user={user} handleRedirect={handleRedirect} />
                  );
                }
                )
              ) : (
                <div className={styles.noResultsContainer}>
                  <p className={styles.noResults}>No hay usuarios con esa especialidad</p>
                </div>
              )
              }
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loading,
    usersRegisterData: state.general.usersRegisteredData,
    showButton: state.dashboard.allProjects,
    isLoaded: state.general.isLoaded,
    isVisualizerOpen: state.general.recoverOpen,
    userData: state.general.userData,
    profileMenu: state.profile.menuShow,
    usersBySKills: state.register.usersBySkill.userss,
    userPortfolios: state.register.userPortfolios,
    edifySpinner: state.general.edifySpinner,
    totalPages: state.register.usersBySkill.totalPages,
    itemCount: state.register.usersBySkill.itemCount,
    selectedSkill: state.register.selectedSkill,
    currentPage: state.register.usersBySkill.page,
    volverAlListado: state.profile.goBackToCategoryList,
    isUserPremium: state.general.isUserPremium,
  };
};

export default connect(mapStateToProps,
  {
    getUsersBySkillName,
    getUsersRegisteredData,
    openMainModal,
    closeMainModal,
    setProjectFormStyle,
    setSubProjectStyle,
    setGoBackToCategoryList,
    resetProjectToEdit,
    setPreviousLocation,
    openSuscriptionModal,
  })(RegisteredUsersCategory);