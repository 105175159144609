import React, { useEffect, useState } from "react";
import styles from "./SubQuotationBudget.module.scss";
import { getProfileDataOfUserSender, clearSubProjectBudgets, getSubQuotationsPostulation } from "../../../../store/actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Planos } from "../../../../assets/svg/planos.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrowDownIcon.svg";

const SubQuotationBudget = ({
    subproject,
    getSubQuotationsPostulation,
    budgets,
}) => {
    const [showSubProjectInfo, setShowSubProjectInfo] = useState({});
    const [count, setCount] = useState(0);
   const navigate = useNavigate();
    
    useEffect(() => {
        getSubQuotationsPostulation(parseInt(subproject.id));
    }, [subproject.id])

    useEffect(() => {
        setCount(budgets.length);
    }, [budgets]);

    const handleRedirectToProfile = (userId, fullName) => {
        navigate(`/comunidad-edify/${userId}/${fullName}`);
    }

    //Muestra la info de los subproyectos
    const toggleSubProjectInfo = (index) => {
        setShowSubProjectInfo((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className={styles.container}>
            <div className={styles.container_body}>
                <div className={styles.container_header} onClick={() => toggleSubProjectInfo(subproject.id)}>
                    <p className={styles.container_title}>{subproject.name}</p>
                    <div className={styles.container_arrow}>
                        <div className={styles.container_arrow_count}>
                            {count}
                        </div>
                        <ArrowIcon
                            className={showSubProjectInfo[subproject.id] ? styles.arrowIconDown : styles.arrowIconRight}
                            height={15}
                            width={15}
                        />
                    </div>
                </div>
                {showSubProjectInfo[subproject.id] && (
                    <div className={styles.budgetsContainer}>
                        {budgets.map((budget, index) => {
                            const fullName = budget.user.type === "COMPANY" ? budget.user.razon_social : budget.user.name + " " + budget.user.last_name;
                            const userId = budget.user.id;
                            const profession = budget.user.profession === "undefined" || budget.user.profession === undefined ? "No especificado" || budget.user.profession === null : budget.user.profession;
                            return (
                                <div className={styles.container_body_budgetFile} key={index}>
                                    <div className={styles.container_body_budgetItem} onClick={() => handleRedirectToProfile(userId, fullName)}>
                                        <div className={styles.container_body_budgetItem_overLay}>
                                            <p>Ver perfil</p>
                                        </div>
                                        <span className={styles.container_body_budgetItem_name}>{fullName}</span>
                                        <span className={styles.container_body_budgetItem_profession}>{profession}</span>
                                    </div>
                                    <div className={styles.container_body_budgetFileContainer} key={index}>
                                        <Planos width={15} height={15} />
                                        <a href={budget.file} target="_blank" rel="noreferrer" className={styles.container_body_budgetFile_link}>Descargar cotización</a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div >
    );
}

const mapStateToProps = (state) => {

    return {
        budgets: state.profile.subQuotationsPostulations,
    };
}

export default connect(mapStateToProps, { getProfileDataOfUserSender, clearSubProjectBudgets, getSubQuotationsPostulation })(SubQuotationBudget);