import React, { useState, useEffect } from "react";
import styles from "./OfertaDeTrabajo.module.scss";
import { connect } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import JurisdictionList from "../UI/JurisdictionList/JurisdictionList";
import { getSkills, getCities, createWorkOffer, openMainModalDashboard, closeMainModal, closeWorkOfferModal } from "../../store/actions";
import useGetTodayDate from '../../hooks/useGetTodayDate';
import { motion, AnimatePresence } from "framer-motion";

const OfertaDeTrabajo = ({
    openMainModalDashboard,
    closeMainModal,
    cities,
    skills,
    userData,
    getSkills,
    createWorkOffer,
    closeWorkOfferModal,
    getCities }) => {
    const [showJurisdictionList, setShowJurisdictionList] = useState(false);
    const [showCityList, setShowCityList] = useState(false);
    const [showCitySuggestions, setShowCitySuggestions] = useState(false);
    const [cityInputValue, setCityInputValue] = useState('');
    const [showFormSection, setShowFormSection] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [showSkillsList, setShowSkillsList] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showStartJobDate, setShowStartJobDate] = useState(false);
    const [selectedSalaryType, setSelectedSalaryType] = useState('Hour');

    const today = useGetTodayDate();


    const convertDateToISOFormat = (date) => {
        const [dd, mm, yyyy] = date.split('/');
        return `${yyyy}-${mm}-${dd}`;
    };

    let startDateInitialValue = new Date();
    startDateInitialValue.setDate(startDateInitialValue.getDate());
    startDateInitialValue = startDateInitialValue.toISOString().split('T')[0];

    useEffect(() => {
        getSkills();
    }, []);

    const initialValues = {
        selectedJurisdiction: null,
        workZone: [],
        skills: [],
        description: '',
        immediateStart: true,
        startDate: startDateInitialValue,
        contactMethod: 'email',
        alternativeContact: userData?.username,
        phone: userData?.phone,
        salary: 0,
        salaryFormatted: '',
        activeUntil: '',
        salaryType: '',
        city: ''
    };

    const formSchema = Yup.object({
        selectedJurisdiction: Yup.object().nullable(),
        workZone: Yup.array().min(1, 'Debes seleccionar al menos una ciudad'),
        immediateStart: Yup.boolean().required('Debes elegir si la oferta es de inicio inmediato o no'),
        activeUntil: Yup.string().required('Debes ingresar la fecha de finalización de la oferta'),
        skills: Yup.array().min(1, '*Debes seleccionar al menos un rubro de trabajo').required('*Debes seleccionar al menos un rubro de trabajo'),
    });

    const formik = useFormik({
        validateOnMount: true,
        validateOnBlur: true,
        initialValues,
        validationSchema: formSchema,
        onSubmit: values => {
            createWorkOffer(values);
            formik.resetForm();
            formik.setValues(formik.initialValues);
            closeWorkOfferModal();
        }
    });

    const handleAddSkill = (skillName, skillId) => {
        const skillExists = formik.values.skills.some(skill => skill.id === skillId);
        if (!skillExists) {
            formik.setFieldValue('skills', [...formik.values.skills, { id: skillId, name: skillName }]);
        }
        setShowSkillsList(false);
        setShowSuggestions(false);
        setInputValue('');
    };

    const handleAddWorkZone = (city) => {
        const newWorkZone = [
            ...formik.values.workZone,
            {
                jurisdiccion: formik.values.selectedJurisdiction.id,
                name: formik.values.selectedJurisdiction.name,
                city: city.name
            }
        ];
        formik.setFieldValue('workZone', newWorkZone);
        formik.setFieldValue('city', city.name);
        formik.setFieldValue('selectedJurisdiction', null);
        formik.setFieldValue('city', "");
        setShowCityList(false);
        setShowCitySuggestions(false);
        setCityInputValue('');

    };


    const handleSuggestions = (value) => {

        if (value.length < 3) {
            setShowSuggestions(false)
        } else if (value.length >= 3) {
            setShowSkillsList(false)
            setShowSuggestions(true)
        }
        setInputValue(value)

    }

    const matchingSkills = skills?.filter((rubro) => {
        return rubro.name.toLowerCase().includes(inputValue.toLowerCase())
    })


    useEffect(() => {
        if (formik.values.selectedJurisdiction) {
            getCities(formik.values.selectedJurisdiction?.id)
        }
    }, [formik.values.selectedJurisdiction?.id]);

    const handleCitySuggestions = (value) => {
        if (value.length < 3) {
            setShowCitySuggestions(false)
        } else if (value.length >= 3) {
            setShowCityList(false);
            setShowCitySuggestions(true);
        }
        setCityInputValue(value)
    }

    const matchingCities = cities?.filter((city) => {
        return city.name.toLowerCase().includes(cityInputValue.toLowerCase())
    })

    const toggleFormSection = (index) => {
        setShowFormSection({
            ...showFormSection,
            [index]: !showFormSection[index]
        })
    }

    const handleSalaryTypeChange = (e) => {
        formik.handleChange(e);
        setSelectedSalaryType(e.target.value);
    };

    const handleSalaryChange = (e) => {
        let value = e.target.value.replace(/\./g, '');
        value = value.replace(/\D/g, '');

        if (value) {
            const numberValue = parseInt(value, 10);
            if (!isNaN(numberValue)) {
                const formattedValue = new Intl.NumberFormat('es-ES').format(numberValue);
                formik.setFieldValue('salary', numberValue);
                formik.setFieldValue('salaryFormatted', formattedValue);
            }
        } else {
            formik.setFieldValue('salary', '');
            formik.setFieldValue('salaryFormatted', '');
        }
    };

    const handleContactMethod = (e) => {
        let value = e.target.value;

        if (value === 'phone') {
            formik.setFieldValue('contactMethod', value);
            formik.setFieldValue('alternativeContact', userData?.phone);
        } else if
            (value === 'email') {
            formik.setFieldValue('contactMethod', value);
            formik.setFieldValue('alternativeContact', userData?.username);
        }
    }

    useEffect(() => {
        if (formik.values.immediateStart) {
            formik.setFieldValue('startDate', convertDateToISOFormat(today));
        }
    }, [formik.values.immediateStart, formik.setFieldValue, today]);

    const handleCloseModal = () => {
            openMainModalDashboard({
                title: '¿Estás seguro que deseas salir?',
                body: 'Si cierras la ventana perderás los datos ingresados',
                isSuccess: false,
                confirmHandler: () => {
                    closeMainModal();
                    closeWorkOfferModal();
                },
            })
    } 

    return (
        <div className={styles.overlay}>
            <div className={styles.container}>
                <div className={styles.containerBody}>
                    <div className={styles.containerBody_header}>
                        <h1 className={styles.containerBody_header_title}>Crear oferta de trabajo</h1>
                        <button className={styles.containerBody_header_button} onClick={() => handleCloseModal()}>X</button>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.body_item}>
                            <div className={`${styles.body_item_title} ${showFormSection[1] && styles.backgroundSelected}`} onClick={() => toggleFormSection(1)}>
                                <h2 className={styles.body_item_title_txt}>
                                    Lugar
                                    {formik.values.workZone.length === 0 && (
                                        <i className={styles.body_item_title_required}>*</i>
                                    )}
                                </h2>
                                <span className={styles.body_item_title_arrow} >
                                    <ArrowDownIcon
                                        title="Ver opciones de lugar"
                                        width={15}
                                        height={15}
                                        className={showFormSection[1] ? styles.arrowIconRight : styles.arrowIconDown}
                                    />
                                </span>
                            </div>
                            <AnimatePresence>
                                {showFormSection[1] && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className={styles.body_formGroup_workZone}>
                                        {formik.values.workZone.length > 0 && (
                                            <div className={styles.body_item_workZoneSelected}>
                                                {formik.values.workZone.map((workZone, index) => (
                                                    <div
                                                        key={index}
                                                        className={styles.body_item_workZoneSelected_item}
                                                        onClick={() => { formik.setFieldValue('workZone', formik.values.workZone.filter((_, i) => i !== index)) }}>
                                                        {workZone.name} - {workZone.city.split(",")[0]}&nbsp;X
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className={styles.body_formGroup_workZone_inputsContainer}>
                                            <div className={styles.body_item_workZoneContainer}>
                                                <input
                                                    type='text'
                                                    id='jurisdictionList'
                                                    name='jurisdictionList'
                                                    placeholder='Elegir provincia'
                                                    className={styles.body_item_workZoneInput}
                                                    value={formik.values.selectedJurisdiction?.name || ''}
                                                    readOnly
                                                    onChange={formik.handleChange}
                                                />
                                                <ArrowDownIcon
                                                    title='Ver lista de provincias'
                                                    width={15}
                                                    height={15}
                                                    className={showJurisdictionList ? styles.workBag_workZoneArrowDown : styles.workBag_workZoneArrowUp}
                                                    onClick={() => setShowJurisdictionList(!showJurisdictionList)} />
                                            </div>
                                            {showJurisdictionList && (
                                                <ul className={styles.body_item_workZoneList}>
                                                    {JurisdictionList.map((jurisdiction) => (
                                                        <li
                                                            key={jurisdiction.id}
                                                            className={styles.body_item_workZoneList_item}
                                                            onClick={() => {
                                                                formik.setFieldValue('selectedJurisdiction', { id: jurisdiction.id, name: jurisdiction.name });
                                                                setShowJurisdictionList(false);
                                                            }}>
                                                            {jurisdiction.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            <div className={`${styles.body_item_workZoneCityContainer} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}>
                                                <input
                                                    type='text'
                                                    id='workZone'
                                                    name='workZone'
                                                    placeholder='Elegir ciudad'
                                                    value={cityInputValue}
                                                    onChange={(e) => handleCitySuggestions(e.target.value)}
                                                    className={`${styles.body_item_workZoneInput} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}
                                                />
                                                <ArrowDownIcon
                                                    title='Ver lista de ciudades'
                                                    width={15}
                                                    height={15}
                                                    className={`${showCityList ? styles.body_item_workZoneArrowDown : styles.body_item_workZoneArrowUp} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}
                                                    onClick={() => setShowCityList(!showCityList)} />
                                            </div>
                                            {showCityList && !showCitySuggestions && (
                                                <ul className={styles.body_item_workCityList}>
                                                    {cities?.map((city) => (
                                                        <li
                                                            key={city.name}
                                                            className={styles.body_item_workCityList_item}
                                                            onClick={() => { handleAddWorkZone(city) }}
                                                        >
                                                            {city.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {showCitySuggestions && !showCityList && (
                                                <ul className={styles.body_item_workCityList}>
                                                    {matchingCities.map((city) => (
                                                        <li
                                                            key={city.name}
                                                            className={styles.body_item_workCityList_item}
                                                            onClick={() => { handleAddWorkZone(city) }}
                                                        >
                                                            {city.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className={styles.body_item}>
                            <div className={`${styles.body_item_title} ${showFormSection[2] && styles.backgroundSelected}`} onClick={() => toggleFormSection(2)}>
                                <h2 className={styles.body_item_title_txt}>
                                    Rubro
                                    {formik.errors.skills && (
                                        <i className={styles.body_item_title_required}>*</i>
                                    )}
                                </h2>
                                <span className={styles.body_item_title_arrow} >
                                    <ArrowDownIcon
                                        title='Ver lista de rubros'
                                        width={15}
                                        height={15}
                                        className={showFormSection[2] ? styles.arrowIconRight : styles.arrowIconDown}
                                    />
                                </span>
                            </div>
                            <AnimatePresence>
                                {showFormSection[2] && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className={styles.body_formGroup_workZone}>
                                        {formik.values.skills.length > 0 && (
                                            <div className={styles.body_item_skillsSelected}>
                                                {formik.values.skills.map((skill, index) => (
                                                    <div
                                                        key={index}
                                                        className={styles.body_item_skillsSelected_item}
                                                        onClick={() => {
                                                            formik.setFieldValue('skills', formik.values.skills.filter((_, i) => i !== index))
                                                        }}
                                                    >
                                                        {skill.name}&nbsp;X
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className={styles.body_item_workZoneContainer}>
                                            <input
                                                type='text'
                                                id='skills'
                                                name='skills'
                                                placeholder='Ingrese 3 caracteres o despliegue la lista'
                                                value={inputValue}
                                                className={styles.body_item_workZoneInput}
                                                onChange={(e) => handleSuggestions(e.target.value)}
                                            />
                                            <ArrowDownIcon
                                                title='Ver lista de rubros'
                                                width={15}
                                                height={15}
                                                className={showSkillsList ? styles.body_item_skillsArrowDown : styles.body_item_skillsArrowUp}
                                                onClick={() => setShowSkillsList(!showSkillsList)} />
                                        </div>
                                        {showSkillsList && !showSuggestions && (
                                            <ul className={styles.body_item_skillsSelected_list}>
                                                {skills.map((skill) => (
                                                    <li
                                                        key={skill.id}
                                                        className={styles.body_item_skillsSelected_itemList}
                                                        onClick={() => handleAddSkill(skill.name, skill.id)}
                                                    >
                                                        {skill.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {showSuggestions && !showSkillsList && (
                                            <ul className={styles.body_item_skillsSelected_list}>
                                                {matchingSkills.map((suggestion) => (
                                                    <li
                                                        key={suggestion.id}
                                                        className={styles.body_item_skillsSelected_itemList}
                                                        onClick={() => handleAddSkill(suggestion.name, suggestion.id)}
                                                    >
                                                        {suggestion.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className={styles.body_item}>
                            <div className={`${styles.body_item_title} ${showFormSection[3] && styles.backgroundSelected}`} onClick={() => toggleFormSection(3)}>
                                <h2 className={styles.body_item_title_txt}>
                                    Fechas
                                    {formik.errors.activeUntil && (
                                        <i className={styles.body_item_title_required}>*</i>
                                    )}
                                </h2>
                                <span className={styles.body_item_title_arrow}>
                                    <ArrowDownIcon
                                        title="Ver opciones de fechas"
                                        width={15}
                                        height={15}
                                        className={showFormSection[3] ? styles.arrowIconRight : styles.arrowIconDown}
                                    />
                                </span>
                            </div>
                            <AnimatePresence>
                                {showFormSection[3] && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className={styles.body_formGroup_workZone}>
                                        <div className={styles.body_item_dateContainer}>
                                            <h4 className={styles.body_item_dateContainer_title}>
                                                ¿Cuándo comienza el trabajo?
                                            </h4>
                                            <div className={styles.body_item_dateContainer_start}>
                                                <label htmlFor='inmediateStart'>Inicio inmediato</label>
                                                <input
                                                    type='checkbox'
                                                    id='immediateStart'
                                                    name='immediateStart'
                                                    value={formik.values.immediateStart}
                                                    checked={formik.values.immediateStart}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <AnimatePresence>
                                            {!formik.values.immediateStart && (
                                                <motion.div
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                    exit={{ opacity: 0, height: 0 }}
                                                    className={styles.body_item_dateContainer_exactDate}>
                                                    <label htmlFor='startDate'>Fecha de inicio</label>
                                                    <input
                                                        type='date'
                                                        id='startDate'
                                                        name='startDate'
                                                        min={convertDateToISOFormat(today)}
                                                        max={formik.values.activeUntil}
                                                        placeholder='Fecha de inicio'
                                                        className={styles.body_item_dateContainer_input}
                                                        value={formik.values.immediateStart ? convertDateToISOFormat(today) : formik.values.startDate}
                                                        onChange={formik.handleChange}
                                                    />
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                        <div className={styles.body_item_dateContainer}>
                                            <h4 className={styles.body_item_dateContainer_title}>
                                                ¿Hasta cuándo es válida la oferta?
                                            </h4>
                                            <div className={styles.body_item_dateContainer_endDate}>
                                                <label htmlFor='activeUntil'>Fecha de finalización</label>
                                                <input
                                                    type='date'
                                                    id='activeUntil'
                                                    name='activeUntil'
                                                    min={formik.values.startDate || convertDateToISOFormat(today)}
                                                    placeholder='Fecha de publicación'
                                                    className={styles.body_item_dateContainer_input}
                                                    value={formik.values.activeUntil}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className={styles.body_item}>
                            <h2 className={`${styles.body_item_title} ${showFormSection[4] && styles.backgroundSelected}`} onClick={() => toggleFormSection(4)}>
                                Condiciones salariales
                                <span className={styles.body_item_title_arrow} >
                                    <ArrowDownIcon
                                        title="Agregar condiciones salariales"
                                        width={15}
                                        height={15}
                                        className={showFormSection[4] ? styles.arrowIconRight : styles.arrowIconDown}
                                    />
                                </span>
                            </h2>
                            <AnimatePresence>
                                {showFormSection[4] && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        className={styles.body_item_salaryOffer}>
                                        <div className={styles.body_item_salaryOffer_title}>
                                            Remuneración aproximada ofrecida
                                        </div>
                                        <input
                                            type='text'
                                            id='salary'
                                            name='salary'
                                            placeholder='Ingresar monto'
                                            className={styles.body_item_salaryOffer_input}
                                            value={formik.values.salaryFormatted}
                                            onChange={(e) => handleSalaryChange(e)}
                                        />
                                        <span className={styles.body_item_salaryOffer_currency}>$</span>
                                        <select
                                            name='salaryType'
                                            id='salaryType'
                                            value={selectedSalaryType}
                                            onChange={(e) => handleSalaryTypeChange(e)}
                                            className={styles.body_item_salaryOffer_select}
                                        >
                                            <option className={styles.body_item_salaryOffer_option} value='Hour'>Hora</option>
                                            <option className={styles.body_item_salaryOffer_option} value='Day'>Día</option>
                                            <option className={styles.body_item_salaryOffer_option} value='Week'>Semana</option>
                                            <option className={styles.body_item_salaryOffer_option} value='Month'>Mes</option>
                                            <option className={styles.body_item_salaryOffer_option} value='Job'>Trabajo completo</option>
                                        </select>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className={styles.body_item}>
                            <h2 className={`${styles.body_item_title} ${showFormSection[5] && styles.backgroundSelected}`} onClick={() => toggleFormSection(5)}>
                                Descripción
                                <span className={styles.body_item_title_arrow}>
                                    <ArrowDownIcon
                                        title="Agregar descripción"
                                        width={15}
                                        height={15}
                                        className={showFormSection[5] ? styles.arrowIconRight : styles.arrowIconDown}
                                    />
                                </span>
                            </h2>
                            <AnimatePresence>
                                {showFormSection[5] && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        className={styles.body_item_description}>
                                        <textarea
                                            name='description'
                                            id='description'
                                            placeholder='Escribe aquí los detalles de la oferta, requisitos, tareas a realizar, etc.'
                                            className={styles.body_item_description_textarea}
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className={styles.body_item}>
                            <h2 className={`${styles.body_item_title} ${showFormSection[6] && styles.backgroundSelected}`} onClick={() => toggleFormSection(6)} >
                                Contacto
                                <span className={styles.body_item_title_arrow} >
                                    <ArrowDownIcon
                                        title="Agregar modo de contacto"
                                        width={15}
                                        height={15}
                                        className={showFormSection[6] ? styles.arrowIconRight : styles.arrowIconDown}
                                    />
                                </span>
                            </h2>
                            <AnimatePresence>
                                {showFormSection[6] && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        className={styles.body_item_contact}>
                                        <div className={styles.body_item_contactMethod}>
                                            <label className={styles.body_item_contactAlternative_label} htmlFor='contactMethod'>Método de contacto</label>
                                            <select
                                                name='contactMethod'
                                                id='contactMethod'
                                                value={formik.values.contactMethod}
                                                onChange={(e) => handleContactMethod(e)}
                                                className={styles.body_item_contactMethod_select}
                                            >
                                                <option className={styles.body_item_contactMethod_option} value='email'>Email</option>
                                                <option className={styles.body_item_contactMethod_option} value='phone'>Teléfono</option>
                                            </select>
                                        </div>
                                        <div className={styles.body_item_contactAlternative}>
                                            <label className={styles.body_item_contactAlternative_label} htmlFor='alternativeContact'>Serás contactado</label>
                                            <input
                                                type='text'
                                                id='alternativeContact'
                                                name='alternativeContact'
                                                placeholder='Ingresar contacto'
                                                value={formik.values.alternativeContact}
                                                onChange={formik.handleChange}
                                                className={styles.body_item_contactAlternative_input}
                                            />
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className={styles.body_item}>
                        <button
                            className={`${styles.body_item_button} ${formik.dirty && formik.isValid && styles.isValid}`}
                            onClick={formik.handleSubmit}>Publicar oferta
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        skills: state.register.skills,
        cities: state.register.cities,
        userData: state.general.userData,
    }
}

export default connect(mapStateToProps, {
    getSkills, 
    getCities, 
    createWorkOffer, 
    openMainModalDashboard,
    closeWorkOfferModal, 
    closeMainModal })(OfertaDeTrabajo);
