import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { setSendSubQuotation, addSubQuotationToQuotation } from "../../../../store/actions/index";
import RubroSubQuotationSelect from "../../../MarketPlace/subCotizacion/nuevaSubCotizacion/RubroSubQuotationSelect";
import styles from "./NuevaSubCotizacion.module.scss";
import { ReactComponent as AddDescription } from "../../../../assets/svg/addDescriptionPlaceholder.svg"
import { ReactComponent as Cotizacion } from "../../../../assets/svg/cotizacionPlaceholder.svg"
import { ReactComponent as DateIcon } from "../../../../assets/svg/datePlaceholder1.svg"
import { ReactComponent as Plus } from "../../../../assets/svg/plus.svg"
import { ReactComponent as Trash } from "../../../../assets/svg/description-sub.svg";
import { fireAnalytics } from "../../../../utils/firebase";

const NuevaSubCotizacion = (props) => {
  const [fileName, setFileName] = useState('');
  const [clear, setClear] = useState(false);

  const resetClear = () => {
    setClear(false);
  };

  const addHandler = (values, initialValues, resetForm) => {
    if (props.isEditing) {
      props.addSubQuotationToQuotation(values, props.projectIdForSub, values.nameSub.split("*")[0], props.isEditing);
      resetForm(initialValues);
      setFileName('');
      setClear(true);
    } else {
    props.setSendSubQuotation(values);
    resetForm(initialValues);
    setFileName('');
    setClear(true);
  };
};

  const subCotizacionSchema = Yup.object().shape({
    nameSub: Yup.string(),
    descriptionSub: Yup.string(),
    endDateSub: Yup.date(),
    file: Yup.mixed().required('Debes adjuntar un archivo.').nullable(),
  });

  return (
    <div>
      <Formik
        initialValues={{
          nameSub: '',
          descriptionSub: '',
          endDateSub: '',
          file: null,
        }}
        validationSchema={subCotizacionSchema}
        onSubmit={(values, { resetForm, initialValues }) => {
          addHandler(values, initialValues, resetForm);
        }}
      >
        {({ handleSubmit, isValid, values, resetForm, initialValues, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className={styles.fieldWrapper}>
              <RubroSubQuotationSelect inputName="nameSub" rubros={props.subQuotations} setFieldValue={setFieldValue} clear={clear} resetClear={resetClear} />
            </div>
            <div className={values.nameSub ? styles.containerSubproject : ""}>
              <div
                className={props.subProjectStyleForm ? styles.fieldWrapper_hidden : `${styles.fieldWrapper_content} ${values.nameSub && styles.open
                  }`}
              >
                <h3 className={styles.tituloRubro}>{values.nameSub.split("*")[1]}</h3>
                <div className={styles.tituloDescripcion}>
                  <AddDescription />
                  <h4>Descripción</h4>
                </div>
                <div className={styles.fieldWrapper}>
                  <div className={styles.relative}>
                    <Field
                      id="descriptionSub"
                      name="descriptionSub"
                      placeholder="Añade una breve descripción del rubro."
                      as="textarea"
                    />
                  </div>
                </div>
                <div className={styles.tituloPlanilla}>
                  <Cotizacion />
                  <h4>Excel de cotización</h4><i>*obligatorio</i>
                </div>
                <div className={styles.fieldWrapperPlanilla_container}>
                  <label
                    htmlFor="fileType"
                    className={styles.fieldWrapperPlanilla}>
                    <p className={styles.fieldWrapperPlanilla_placeHolder}>{fileName === "" ? "Click para adjuntar un archivo Excel" : fileName.substring(0, 40) + "..."}</p>
                    <Field
                      className={styles.fileName}
                      id="fileType"
                      name="fileType"
                      type="file"
                      accept=".pdf, .xlsx, .xls"
                      onChange={(event) => {
                        event.currentTarget.files[0] &&
                          setFieldValue("file", event.currentTarget.files[0]);
                        setFileName(event.currentTarget.files[0]?.name);
                      }}
                    />
                  </label>
                </div>
                <div className={styles.buttonContainer}>
                  <button
                    className={`${styles.subbutton} ${styles.delete}`}
                    onClick={() => {
                      resetForm(initialValues);
                      setClear(true);
                    }}
                  >
                    {" "}
                    <span className={styles.trash}>
                      <Trash />{" "}
                    </span>
                    Cancelar
                  </button>
                  <button className={styles.subbutton} type="submit" disabled={!isValid || !values.file}>
                    <span className={styles.trash}>
                      <Plus />
                    </span>{' '}
                    Crear rubro
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};






const mapStateToProps = (state) => {
  return {
    subQuotations: state.profile.subQuotations,
    quotationToEdit: state.profile.quotationToEdit,
  };
};

export default connect(mapStateToProps, { setSendSubQuotation, addSubQuotationToQuotation})(NuevaSubCotizacion);
