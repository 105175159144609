import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  clearProjects,
  filterQuotations,
} from "../../store/actions";
import FilterQuotation from "../Dashboard/FilterBy/FilterQuotations/FilterQuotation";
import Botonera from "../Botonera/Botonera";
import Loading from "../UI/Loading/Loading";
import EmptyState from "../UI/EmptyState/EmptyState";
import useSuscriptionModalTimeout from "../../hooks/useSuscriptionModalTimeout";
import Pagination from "../../hooks/pagination";
import styles from "./QuotationsList.module.scss";

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

const QuotationsList = ({
  quotations,
  filterQuotations,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const pageNumberLimit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  useSuscriptionModalTimeout();
  
  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: quotations,
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    const initialFilter = {
      sort: "DESC",
            asociatedWords: null,
            zone: null,
            minAmount: "",
            maxAmount: "",
            currency: "$",
            jurisdiction: null,
            organism: null,
            workType: null,
            dateRange: { dateFrom: null, dateTo: null },
            projectType: "Publica" ,
    }
    setIsLoading(true);
    filterQuotations(initialFilter, currentPage, pageNumberLimit);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, [currentPage]);


  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <div className={styles.allProjects}>
    <FilterQuotation setIsFiltered={setIsFiltered} setIsLoading={setIsLoading}/>
    {isLoading ? (
      <Loading noModal />
    ) : (
      <>
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent}/>}
        {!quotations.entities || quotations.entities.length === 0  ? (
          <>
          <div className={styles.emptyState}>
            <EmptyState /> 
            <p className={styles.emptyStateText}>
              No hay cotizaciones disponibles
            </p>
          </div>
          </>
        ) : (
          <Pagination
            {...paginationAttributes}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageChange={onPageChange}
            isQuotation
          />
        )}
      </>
    )}
    </div>
  </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    profileMenu: state.profile.menuShow,
    quotations: state.profile.quotations ,
    loading: state.profile.loading,
    isLoaded: state.general.isLoaded,
    isFilter: state.profile.isFilter,
    isVisualizerOpen: state.general.recoverOpen,
    searchParams: state.profile.searchParams,
  };
};

export default connect(mapStateToProps, {
  clearProjects,
  filterQuotations,
})(QuotationsList);
