import React from "react";
import styles from "./ImageModal.module.scss";
import Close from "../../../../assets/images/cruz.svg";
import Modal from "../../../UI/Modal/Modal";

const imageModal = ({ onClose,image }) => {
    return (
<Modal opened={!!image} close={onClose}> 
<div>
<div className={styles.closeContent} onClick={onClose}>
  <div className={styles.close}>
              <span >
                <img src={Close} alt='Close' />
              </span>
              </div>
            </div>
            </div >
            <div className={styles.imgContainer} onClick={onClose}
          >
            <img className={styles.img} src={image}/>
            
            </div>
</Modal>)
  
};

export default imageModal;
