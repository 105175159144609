import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import {
  registerNewUser, setErrorMessage, setLoginAnimation,
  loginWithGoogle,
} from "../../store/actions";
import * as Yup from "yup";
import styled from "styled-components";
import styles from "./RegisterPage.module.scss";
import Input from "../../components/UI/Forms/Input/Input";
import Button from "../../components/UI/Forms/Button/Button";
import MessageResponse from "../../components/UI/MessageResponse/MessageResponse";
import InitialWrapper from "../../components/Layout/InitialWrapper/InitialWrapper";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { motion, AnimatePresence } from "framer-motion";
import { fireAnalytics } from "../../utils/firebase.js";
import PDF from "../../components/Footer/PDF/Terminos-y-condiciones-edify-2021.pdf";
import { GoogleLogin } from "@react-oauth/google";


const FormGroup = styled.div`
  margin: 15px 0;
  position: relative;
  width: 100%;
`;

const formVariant = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 50,
      dumping: 50,
    },
  },
  exit: {
    opacity: 0,
    x: "100vw",
  },
};



const RegisterPage = (props) => {

  const navigate = useNavigate();
  useEffect(() => {
    fireAnalytics("registration_form", { category: "navigation" });
  }, []);

  const [userEmail, setUserEmail] = useState("");
  const phoneRegex = /^\+[0-9]{1,3}[0-9]{9,}$/;

  const handleLink = () => {
    props.setErrorMessage("");
    fireAnalytics("register_to_login", { category: "navigation" });
  };
  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email("El mail no es válido")
      .required("El campo mail es requerido"),

    password: Yup.string()
      .required("El campo contraseña es requerido")
      .min(6, "Debe contener al menos 6 caracteres."),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], `La contraseña no coincide.`)
      .required("Por favor confirma la contraseña."),

    termsAndConditions: Yup.bool()
      .test(
        "termsAndConditions",
        "Debe aceptar términos y condiciones",
        (value) => value === true
      )
      .required("Por favor acepte términos y condiciones"),

    confirmationMethod: Yup.string()
      .oneOf(["sendEmail", "sendSms"], "Debe seleccionar un método de confirmación")
      .required("Por favor seleccione un método de confirmación"),
    phone: Yup.string().when("confirmationMethod", {
      is: "sendSms",
      then: Yup.string()
        .required("Por favor ingrese su número de teléfono")
        .matches(phoneRegex, "El número de teléfono no es válido"),
    }),
  });

  const handleFinish = () => {
    props.setLoginAnimation(true);
    fireAnalytics('ob_step_5_next', { category: 'onboarding' })
  };


  setTimeout(() => {
    if (props.loginAnimation === true) {
      props.setLoginAnimation(false);
    }
  }, 3000);


  return (
    <InitialWrapper animation={props.loginAnimation}>
      {props.errorMessage !== "" ? (
        <AnimatePresence>
          <motion.div
            variants={formVariant}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <MessageResponse type="error" title="Oops...">
              {props.errorMessage}
            </MessageResponse>
            <FormGroup>
              <Input className="error" value={userEmail} disabled />
            </FormGroup>
            <FormGroup>
              <Button
                className={styles.registerButton}
                onClick={() => props.setErrorMessage("")}
              >
                Volver
              </Button>
            </FormGroup>
          </motion.div>
        </AnimatePresence>
      ) : (
        <>
          <h1 className={styles.title}>
            ¡Bienvenido a Edify! <br />
          </h1>
          <h4>
            Creá tu cuenta <p className={styles.subtitle_highlighted}>100% GRATIS</p> con Gmail.
          </h4>

          <div className={styles.googleLogin}>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                props.loginWithGoogle(credentialResponse.credential, navigate);
                handleFinish();
              }}
              onError={() => {
                console.log("Login failed");
              }}
              text="continue_with"
              width="350px"
              logo_alignment="center"
              itp_support
              theme="filled_blue"
              shape="pill"
              type="icon"
            />
          </div>
          <div className={styles.googleDivider}>
            <span className={styles.googleDivider_line}></span>
            <span className={styles.googleDivider_text}>O</span>
            <span className={styles.googleDivider_line}></span>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
              confirmationMethod: "",
              phone: "",
            }}
            initialErrors={{ 
              email: "El campo mail es requerido",
              password: "El campo contraseña es requerido",
              confirmPassword: "Por favor confirma la contraseña.",
              confirmationMethod: "Por favor seleccione un método de confirmación",
            }}
            validadeOnBlur
            validationSchema={SignUpSchema}
            onSubmit={(values) => {
              handleFinish();
              props.registerNewUser(values, navigate);
              setUserEmail(values.email);            
              fireAnalytics("try_register", { category: "authentication" });
            }}          
          >
            {({ handleSubmit, isValid, errors, touched, values, setFieldValue })  => (            
              <>
                <span className={styles.googleLogin_txt}>Utilizá tu mail para registrarte</span>
                <motion.form
                  variants={formVariant}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  onSubmit={handleSubmit}
                >
                  <FormGroup>
                    <div className={styles.inputContainer}>
                      <Field
                        className={` ${errors.email && touched.email ? "error" : ""
                          }`}
                        placeholder={
                          errors.email && touched.email
                            ? errors.email
                            : "E-mail*"
                        }
                        type="mail"
                        name="email"
                        component={Input}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className={styles.inputContainer}>
                      <Field
                        className={` ${errors.password && touched.password ? "error" : ""
                          }`}
                        placeholder={
                          errors.password && touched.password
                            ? errors.password
                            : "Contraseña*"
                        }
                        type="password"
                        name="password"
                        component={Input}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className={styles.inputContainer}>
                      <Field
                        className={` ${errors.confirmPassword && touched.confirmPassword
                          ? "error"
                          : ""
                          }`}
                        placeholder={
                          errors.confirmPassword && touched.confirmPassword
                            ? errors.confirmPassword
                            : "Repite tu contraseña*"
                        }
                        type="password"
                        name="confirmPassword"
                        component={Input}
                      />
                    </div>
                  </FormGroup>
                  <div className={styles.confirmationContainer}>
                    <label htmlFor="confirmationEmail">
                      <Field
                        id="confirmationEmail"
                        name="confirmationMethod"
                        type="radio"
                        value="sendEmail"
                        required
                        className={styles.confirmationContainer_radio}
                      />
                      Validar cuenta por mail
                    </label>
                    <label htmlFor="confirmationSms">
                      <Field
                        id="confirmationSms"
                        name="confirmationMethod"
                        type="radio"
                        value="sendSms"
                        required
                        className={styles.confirmationContainer_radio}
                      />
                      Validar cuenta por SMS
                    </label>
                  </div>
                  {values.confirmationMethod === "sendSms" && (
                    <div className={styles.phoneInputContainer}>
                      <PhoneInput
                        id="phone"
                        defaultCountry="AR"                        
                        name="phone"
                        placeholder="Número de teléfono (SMS)"
                        value={values.phone}
                        onChange={(value) => setFieldValue('phone', value)}
                      />
                      {errors.phone && touched.phone && (
                        <div className="error">{errors.phone}</div>
                      )}
                    </div>
                  )}
                  <div className={styles.termsContainer}>
                    <Field
                      onClick={() =>
                        fireAnalytics("open_terms", {
                          category: "authentication",
                        })
                      }
                      id="termsAndConditions"
                      name="termsAndConditions"
                      type="checkbox"
                    />
                    <label
                      htmlFor="termsAndConditions"
                      className={`${styles.inputHelper} ${styles.inputHelperCheckbox} `}
                    >
                      <span>
                        Acepto los{" "}
                        <Link
                          to={PDF}
                          style={{
                            color: "rgba(0, 0, 0, 0.37)",
                            textDecorationLine: "revert",
                          }}
                          target="_blank"
                          download
                        >
                          Términos y Condiciones
                        </Link>
                      </span>
                    </label>
                  </div>
                  <FormGroup className="form-group">
                    <button
                      className={styles.btn}
                      disabled={!isValid}
                      type="submit"
                    >
                      Registrarme
                    </button>
                  </FormGroup>
                </motion.form>
              </>
            )}
          </Formik>
        </>
      )}
      <div className={styles.goBack}>
        <Link to="/" onClick={() => handleLink()}>
          ¿Ya tenes cuenta? Inicia sesión
        </Link>
      </div>
    </InitialWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.register.errorMessage,
    loginAnimation: state.general.loginAnimation,
  };
};

export default connect(mapStateToProps, {
  registerNewUser, setErrorMessage, setLoginAnimation,
  loginWithGoogle,
})(
  RegisterPage
);
