import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./DetailsInfo.module.scss";
import PortfolioItem from "./PortfolioItem";
import PostulationItem from "./PostulationItem";
import ProjectDetails from "./ProjectDetails";
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const DetailsInfo = ({
  isPostulation,
  isProject,
  isPortfolio,
  item,
  image,
  handler,
  myPerson,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
 
  //Definimos de donde tomamos las imágenes

  let imagenes;
  if (isPortfolio) {
    if (Array.isArray(item.portfolio_images)) {
      imagenes = item.portfolio_images.map((img, index) => {
        return (
          <div key={index} className={styles.customImg_container}>
            <img src={img.image} alt="" className={styles.customImg} />
          </div>
        );
      });
    } else {
      imagenes = <img src={item.portfolio_images?.image} alt="" />;
    }
  } else if (isProject) {
    if (Array.isArray(item.proyecto_images) && item.proyecto_images.length > 0) {
      imagenes = item.proyecto_images.map((img, index) => {
        return (
          <div key={index} className={styles.customImg_container}>
            <img src={img.image} alt="" className={styles.customImg} />
          </div>
        );
      });
    } else {
      imagenes = (
        <div className={styles.customImg_container}>
          <img src={image} alt="" className={styles.customImg} />
        </div>
      );
    }
  } else {
    imagenes = (
      <div className={styles.customImg_container}>
        <img src={image} alt="" className={styles.customImg} />
      </div>
    );
  }

  //Controla el cambio de imagenes con las flechas del teclado
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? imagenes.length - 1 : prevIndex - 1
        );
      } else if (event.keyCode === 39) {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === imagenes.length - 1 ? 0 : prevIndex + 1
        );
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [imagenes]);

  return (
    <>
      {isPortfolio && (
        <div className={styles.portfolioContainer}>
          <PortfolioItem portfolio={item} myPerson={myPerson}/>
        </div>
      )} 
      {isProject && (
        <div className={styles.projectContainer}>
          <ProjectDetails project={item} handler={handler} myPerson={myPerson}/>
        </div>
      )}
      {isPostulation && (
        <div className={styles.portfolioContainer}>
          <PostulationItem postulation={item} coverImage={image} myPerson={myPerson}/>
          </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    perfilPropio: state.general.profileVisualizer,
  };
};

export default connect(mapStateToProps)(DetailsInfo);
