import React, { useEffect, useCallback, useState } from 'react';
import { useMap, Marker, Popup } from 'react-leaflet';
import L from "leaflet";

const SmallWorkerMap = ({
    coordinates
}) => {
    const map = useMap();
    const customIcon = new L.Icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/128/7193/7193392.png',
        iconSize: [25, 25],
    });

    useEffect(() => {
        map.flyTo(coordinates, 12, { animate: true });
    }, [coordinates]);


    return (
        <Marker
            position={[coordinates[0], coordinates[1]]}
            icon={customIcon}
            center={[coordinates[0], coordinates[1]]}
        >
        </Marker>
    );
}

export default SmallWorkerMap;