import React from "react";
import styles from "./ProjectsItem.module.scss";
import BuenosAiresIcono from "../../../assets/images/BuenosAiresIcono.jpg";
import CabaIcono from "../../../assets/images/cabaIcon.jpg";
import CatamarcaIcono from "../../../assets/images/catamarcaIcono.jpg";
import ChacoIcono from "../../../assets/images/chacoIcono.jpg";
import ChubutIcono from "../../../assets/images/chubutIcono.jpg";
import CordobaIcono from "../../../assets/images/cordobaIcono.jpg";
import CorrientesIcono from "../../../assets/images/corrientesIcono.jpg";
import EntreRiosIcono from "../../../assets/images/entreRiosIcono.jpg";
import FormosaIcono from "../../../assets/images/formosaIcono.jpg";
import JujuyIcono from "../../../assets/images/jujuyIcono.jpg";
import LaPampaIcono from "../../../assets/images/laPampaIcono.jpg";
import LaRiojaIcono from "../../../assets/images/laRiojaIcono.jpg";
import MendozaIcono from "../../../assets/images/mendozaIcono.jpg";
import MisionesIcono from "../../../assets/images/misionesIcono.jpg";
import NeuquenIcono from "../../../assets/images/neuquenIcono.jpg";
import RioNegroIcono from "../../../assets/images/rioNegroIcono.jpg";
import SaltaIcono from "../../../assets/images/saltaIcono.jpg";
import SanJuanIcono from "../../../assets/images/sanJuanIcono.jpg";
import SanLuisIcono from "../../../assets/images/sanLuisIcono.jpg";
import SantaCruzIcono from "../../../assets/images/santaCruzIcono.jpg";
import SantaFeIcono from "../../../assets/images/santaFeIcono.jpg";
import SantiagoDelEsteroIcono from "../../../assets/images/santiagoDelEsteroIcono.jpg";
import TierraDelFuegoIcono from "../../../assets/images/tierraDelFuegoIcono.jpg";
import TucumanIcono from "../../../assets/images/tucumanIcono.jpg";
import ArgentinaIcono from "../../../assets/images/argentinaIcon.jpg";
import usePremiumCheck from "../../../hooks/usePremiumCheck";

//REact-reveal
const ProjectsItem = ({ item, handleOnClick, isAdjudicated, isQuotation }) => {
  const premiumCheck = usePremiumCheck();
  //Array de iconos
  const iconJPG = [
    { id: 1, name: "Ciudad Autónoma de Buenos Aires", icon: CabaIcono },
    { id: 2, name: "Buenos Aires", icon: BuenosAiresIcono },
    { id: 3, name: "Catamarca", icon: CatamarcaIcono },
    { id: 4, name: "Chaco", icon: ChacoIcono },
    { id: 5, name: "Chubut", icon: ChubutIcono },
    { id: 6, name: "Córdoba", icon: CordobaIcono },
    { id: 7, name: "Corrientes", icon: CorrientesIcono },
    { id: 8, name: "Entre Ríos", icon: EntreRiosIcono },
    { id: 9, name: "Formosa", icon: FormosaIcono },
    { id: 10, name: "Jujuy", icon: JujuyIcono },
    { id: 11, name: "La Pampa", icon: LaPampaIcono },
    { id: 12, name: "La Rioja", icon: LaRiojaIcono },
    { id: 13, name: "Mendoza", icon: MendozaIcono },
    { id: 14, name: "Misiones", icon: MisionesIcono },
    { id: 15, name: "Neuquén", icon: NeuquenIcono },
    { id: 16, name: "Río Negro", icon: RioNegroIcono },
    { id: 17, name: "Salta", icon: SaltaIcono },
    { id: 18, name: "San Juan", icon: SanJuanIcono },
    { id: 19, name: "San Luis", icon: SanLuisIcono },
    { id: 20, name: "Santa Cruz", icon: SantaCruzIcono },
    { id: 21, name: "Santa Fe", icon: SantaFeIcono },
    { id: 22, name: "Santiago del Estero", icon: SantiagoDelEsteroIcono },
    { id: 23, name: "Tierra del Fuego,Antártida e Isla del Atlántico Sur", icon: TierraDelFuegoIcono },
    { id: 24, name: "Tucumán", icon: TucumanIcono },
    { id: 25, name: "Argentina", icon: ArgentinaIcono }
  ]

  let {
    name,
    zone,
    startDate,
    fuente,
    description,
    empresa,
    monto,
    link,
    currency,
    organismo,
    jurisdiccion,
    obra_type,
    adjudicatedDate,
    province,
    comitente,
  } = item;

  //Formatea el monto
  const current = function (number) {
    return new Intl.NumberFormat().format(number);
  };
  const date = isAdjudicated ? adjudicatedDate : startDate;
  const lugar = isAdjudicated ? province : zone;
  const MAX_CHARACTERS = (field) => {
    return field === "name" ? 80 : 30;
  };
  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate(); 
    return `${day}/${month}/${year}`;
  };


  // Función para recortar el contenido si es necesario
  const truncateText = (text, field) => {
    const maxLength = MAX_CHARACTERS(field);

    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }

    return text;
  };


  return (
    <div>
      <div className={styles.container}>
        <ul className={styles.containerItemList}>
          <li className={styles.containerItemList_item}>
            <div className={styles.containerItemList_localization}>
              <div className={styles.itemContainer_content}>
                <p>{jurisdiccion?.name ? jurisdiccion.name : "No informado"}</p>
              </div>
              <div className={styles.containerItemList_iconContainer}>
                {iconJPG.map((icon) => {
                  return (
                    <div key={icon.id}>
                      {icon.name === jurisdiccion?.name ? (
                        <img src={icon.icon} alt="icono" className={styles.containerItemList_iconContainer_img} />
                      ) : null}
                    </div>
                  );
                })
                }
                {jurisdiccion === null && (
                  <img src={ArgentinaIcono} alt="icono" className={styles.containerItemList_iconContainer_img} />
                )}
              </div>
            </div>
          </li>
          <li className={styles.containerItemList_item}>
            <p className={styles.container_item_header_title_txt}>{truncateText(name, "name")}</p>
          </li>
          <li className={styles.containerItemList_item}>
            <p>{obra_type?.name ? obra_type.name : "No informado"}</p>
          </li>
          <li className={styles.containerItemList_item}>
            <p>{organismo?.name ? organismo.name : "No informado"}</p>
          </li>
          <li className={styles.containerItemList_item}>
            {isQuotation
              ? truncateText(comitente, "quotation")
              : isAdjudicated
                ? truncateText(empresa, "quotation")
                : truncateText(comitente, "quotation")}
          </li>
          <li className={styles.containerItemList_item}>
            <p>{formatDate(date)}</p>
          </li>
          <li className={styles.containerItemList_item}>
            {lugar}
          </li>
          <li className={styles.containerItemList_item}>
            {currency}
          </li>
          <li className={styles.containerItemList_item}>
            {current(monto)}
          </li>
          <li className={styles.containerItemList_item}>
          <div className={styles.containerItemList_itemContainerButton} key={item.id}
              link={link}
              onClick={premiumCheck(handleOnClick)}>
              <div className={styles.containerItemList_itemContainerButton_text}>
                <p>Ver más</p>            
              </div>
            </div>
          </li>
        </ul>
      </div>     
    </div >
  );
};
export default ProjectsItem;
